import React, { useState } from "react";
import styled from "styled-components";
import { useForm } from "react-hook-form";
import snakeCaseKeys from "snakecase-keys";
import { useHistory } from "react-router-dom";

import YoutubeChannelAuthorization from "../components/molecules/YoutubeChannelAuthorization";
import EntryConfirmation from "../components/molecules/EntryConfirmation";
import EntryComplete from "../components/molecules/EntryComplete";
import ProgressBar from "../components/molecules/ProgressBar";
import SubmitButton from "../components/atoms/SubmitButton";
import Form from "../components/molecules/Form";
import FormItemList, {
  Props as FormItemListProps,
} from "../components/molecules/FormItemList";

import { Labels, formRules, labels } from "../utils/forms/entry";
import {
  Labels as ScoutEntryLabels,
  labels as scoutEntrylabels,
} from "../utils/forms/scoutentry";
import * as googleApi from "../utils/google";
import { entries } from "../utils/ajax";
import { toastError } from "../utils/appearError";
import { useYoutubeEntryFormItems } from "../hooks/YoutubeEntryFormItems";

// multi formの方からimportする方が自然
export type EntryInfo = ScoutEntry | NormalEntry;

interface NormalEntry {
  values: Values<Labels>;
  labels: Labels;
}

interface ScoutEntry {
  values: Values<ScoutEntryLabels>;
  labels: typeof scoutEntrylabels;
}

export interface ScoutMemberEntry {
  // values: Values<memberLabels>;
  values: any;
  // labels: memberLabels;
  labels: any;
  // fixme 型
}
interface Values<T> {
  entry: Partial<T>;
  platform?: Youtube;
}
interface Youtube {
  id: string;
  name: string;
  subscriberCount: number;
  videoCount: number;
  viewCount: number;
}
interface Google {
  name: string;
  id: string;
  email: string;
}
interface EntryInfoState {
  progress: number;
  google: Google;
  entry: Labels;
  platform: Youtube;
}

const defaultEntryInfoState = {
  progress: 1,
  google: {
    id: "",
    name: "",
    email: "",
  },
  entry: {
    name: "",
    firstName: "",
    lastName: "",
    firstNameKana: "",
    lastNameKana: "",
    email: "",
    entryType: "",
    address: "",
    gender: "",
    postcode: "",
    birthdate: "",
    selfAppeal: "",
  },
  platform: {
    id: "",
    name: "",
    subscriberCount: 0,
    videoCount: 0,
    viewCount: 0,
  },
};

const YoutubeEntry: React.FC = (): JSX.Element => {
  const history = useHistory();
  const [entryInfoState, setEntryInfoState] = useState<EntryInfoState>(
    defaultEntryInfoState
  );

  const formItems = useYoutubeEntryFormItems(
    entryInfoState.google,
    entryInfoState.entry
  );
  const { register, errors, handleSubmit } = useForm();

  const multiFormProps: FormItemListProps<Labels> = {
    rules: formRules,
    formProps: formItems,
    errors,
    register,
  };

  const query = window.location.search;
  const params = new URLSearchParams(query);
  const entryRoute = params.get("entry_route");

  const googleApiAccessToken = googleApi.fetchAccessTokenFromUrl();

  if (googleApiAccessToken === "") history.push("/");

  if (!entryInfoState.google.email && googleApiAccessToken.length > 0) {
    googleApi.profile(googleApiAccessToken).then((res) => {
      if (res) setEntryInfoState({ ...entryInfoState, google: res });
    });
  }

  const updateEntry = (arg: EntryInfoState): void => {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    setEntryInfoState(arg);
  };

  const authorizeYoutubeChannelSuccess = (arg: Youtube) => {
    setEntryInfoState({ ...entryInfoState, platform: arg, progress: 2 });
  };

  const onCompleteSubmitFunc = (data: Labels): void => {
    updateEntry({
      ...entryInfoState,
      entry: { ...entryInfoState.entry, ...data },
      progress: 3,
    });
  };

  const onCompleteForm = (e: React.FormEvent<HTMLFormElement>): Promise<void> =>
    handleSubmit((data: Labels) => onCompleteSubmitFunc(data))(e);

  const onSubmit = async (
    e: React.FormEvent<HTMLFormElement>
  ): Promise<void> => {
    const entry = {
      ...entryInfoState.entry,
      name: entryInfoState.platform.name,
      entryType: "youtube",
      googleId: entryInfoState.google.id,
      googleEmail: entryInfoState.google.email,
      entryRoute: entryRoute || "network",
    };

    e.preventDefault();

    try {
      await entries.create(
        snakeCaseKeys({
          entry,
          platform: entryInfoState.platform,
        })
      );
      updateEntry({ ...entryInfoState, progress: 4 });
    } catch (err) {
      if (err.response && err.response.status === 409) {
        toastError(err.response.data.message);
      } else {
        toastError("エラーが発生しました");
      }
    }
  };

  const steps = ["Google認証", "情報入力", "情報確認", "完了"];

  return (
    <Content>
      <ProgressBarWrapper progress={entryInfoState.progress}>
        <ProgressBar steps={steps} progress={entryInfoState.progress} />
      </ProgressBarWrapper>
      {entryInfoState.progress === 1 && (
        <YoutubeChannelAuthorization
          successFunc={authorizeYoutubeChannelSuccess}
          text={
            "審査を希望するYouTubeチャンネルを以下のボタンから選んでください"
          }
          alert={"シークレットウインドウでのご利用はできません"}
        />
      )}
      {entryInfoState.progress === 2 && (
        <StyledFormWrapper>
          <Form onSubmit={onCompleteForm} align="center">
            <FormItemList<Labels> {...multiFormProps}></FormItemList>
            <SubmitButton
              name="commit"
              value="次へ"
              backgroundColor="#0074AE"
              dataDisableValue="次へ"
            />
          </Form>
        </StyledFormWrapper>
      )}
      {entryInfoState.progress === 3 && !!entryInfoState.entry.email && (
        <EntryConfirmation
          backPreviousProgress={(): void =>
            updateEntry({ ...entryInfoState, progress: 2 })
          }
          onSubmit={onSubmit}
          data={{
            values: {
              entry: {
                ...entryInfoState.entry,
                name: entryInfoState.platform.name,
              },
              platform: entryInfoState.platform,
            },
            labels,
          }}
        />
      )}
      {entryInfoState.progress === 4 && (
        <EntryComplete
          title={
            <div>
              エントリー
              <br />
              ありがとうございました
            </div>
          }
          notice={
            <div>
              10営業日以内に
              <br />
              審査結果をメールでお知らせいたします
            </div>
          }
        />
      )}
    </Content>
  );
};

export default YoutubeEntry;

const Content = styled.main`
  position: relative;
  display: inline-block;
  min-height: calc(100vh - 11.2rem);
  padding: 6.4rem 1.6rem;

  @media (max-width: 896px) {
    margin: 0;
    min-height: calc(100vh - 12.4rem);
    padding: 3.2rem 1.6rem;
  }
`;

const ProgressBarWrapper = styled.div<{ progress: number }>`
  display: flex;
  justify-content: center;
  margin: 0 auto;
  align-items: center;
  padding-inline-start: 0;
  ${({ progress }) =>
    progress === 1 &&
    `
    margin-bottom: 27.7rem;
    @media (max-width: 896px) {
      margin-bottom: 19.8rem;
    }
  `}
`;

const StyledFormWrapper = styled.div`
  width: 100%;
  margin: 0 auto;
  max-width: 60rem;
  min-width: 28rem;
  align-items: center;
`;
