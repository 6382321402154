import React from "react";
import { ReactComponent as GoogleIcon } from "../../assets/images/google-icon.svg";
import Button from "../atoms/Button";

interface Props {
  onClick?: (e: React.FormEvent<HTMLInputElement>) => void;
  disabled?: boolean;
}

const GoogleButton: React.FC<Props> = (props: Props) => {
  return (
    <Button as="button" onClick={props.onClick} disabled={props.disabled}>
      <GoogleIcon />
      <span>Googleでログイン</span>
    </Button>
  );
};
export default GoogleButton;
