import { State } from "./types";

export const initialState: State = {
  isLoading: false,
  creatorType: "member",
  age: 0,
  address: "",
  availableServices: [],
  birthdate: "",
  email: "",
  emergencyPhoneNumber: "",
  gender: "",
  imageUrl: "",
  name: "",
  firstName: "",
  firstNameKana: "",
  lastName: "",
  lastNameKana: "",
  parentFirstName: "",
  parentLastName: "",
  parentRelationship: "other",
  phoneNumber: "",
  postcode: "",
  parentPhoneNumber: "",
  hasChannelJoiningMcn: false,
  isLiveStreamingCreator: false,
  isVoomCreator: false,
  isVoomTermsViewed: false,
  isRevenueViewed: false,
  isMcnJoinCreator: false,
  isNetworkCreator: false,
};
