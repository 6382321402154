import { BasicObject } from "./types";

export const stringToCamel = (s: string): string => {
  return s.replace(/([-_][a-z])/gi, ($1) => {
    return $1.toUpperCase().replace("-", "").replace("_", "");
  });
};

const toCamel = (obj: Record<string, any>): any => {
  if (typeof obj === "string") return obj;
  const b: BasicObject = {};

  const camelCaseKeyObject = Object.entries(obj).reduce((acc, [key, value]) => {
    const accValue = checkValueTypeAndConvert(value); // eslint-disable-line @typescript-eslint/no-use-before-define
    acc[`${stringToCamel(key)}`] = accValue;

    return acc;
  }, b);

  return camelCaseKeyObject;
};

const arrToCamel = (arr: any[]): any => {
  return arr.map((a) => toCamel(a));
};

export const convertKeyToCamel = <T>(o: Record<string, any> | T): T => {
  if (o instanceof Array) return arrToCamel(o);
  return toCamel(o);
};

const checkValueTypeAndConvert = (obj: any): any => {
  if (obj instanceof Array) {
    return obj.map((v) => convertKeyToCamel(v));
  } else if (typeof obj === "object" && obj !== null) {
    return convertKeyToCamel(obj);
  } else {
    return obj;
  }
};
