import React from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { pathType } from "../../utils/paths";

import { types, auth } from "../../store";
import { useCreasUserRegistrationCheck } from "../../hooks/CreasUserRegistrationCheck";

import UserProfile from "../molecules/UserProfile";
import GlobalNav from "../molecules/GlobalNav";
interface Props {
  mobileMenuChangeFunc: MobileMenuChangeFunc;
  mobileMenuOpen: boolean;
}

interface MobileMenuChangeFunc {
  (state: boolean): void;
}

const Sidebar: React.FC<Props> = (props: Props) => {
  const authState: auth.types.State = useSelector<
    types.RootState,
    auth.types.State
  >((state) => state.auth);
  const { pathname } = useLocation();
  const creasUserRegistration = useCreasUserRegistrationCheck(
    pathType(pathname)
  );

  if (
    !authState.isLogin ||
    !authState.isAgreedTerms ||
    !creasUserRegistration.isProfileFilled
  )
    return <div data-testid="dummuySideBar" />;

  return (
    <StyledSidebar open={props.mobileMenuOpen} data-testid="SideBarWrapper">
      <UserProfile />
      <GlobalNav mobileMenuChangeFunc={props.mobileMenuChangeFunc} />
    </StyledSidebar>
  );
};

const StyledSidebar = styled.aside<{ open: boolean }>`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  width: 28rem;
  height: 100%;
  padding-top: 6.4rem;
  position: absolute;
  top: 0;
  box-shadow: 0 0.2rem 0.8rem 0 rgba(0, 0, 0, 0.05);
  box-sizing: border-box;
  border-right: 0.1rem solid #f2f2f2;
  background-color: #fff;
  z-index: 5;
  transition: filter 0.2s ease-in-out, left 0.2s linear 0.2s;
  left: ${({ open }): string | number => (open ? "28rem" : 0)};

  @media (max-width: 896px) {
    position: absolute;
    top: -2rem;
    left: -100%;
    width: 100vw;
    padding-top: 5.6rem;
    transition: top 0.4s ease-in-out, filter 0.4s ease-in-out,
      left 0s linear 0.4s;
    filter: opacity(0);

    ${(props): string =>
      props.open
        ? `
        z-index: 5;
        top: 0;
        left: 0;
        transition: top 0.4s ease-in-out, filter 0.4s ease-in-out, left 0s linear;
        filter: opacity(1);
        `
        : ``}
  }
`;

export default Sidebar;
