import React, { useState } from "react";
import styled from "styled-components";
import { useForm } from "react-hook-form";
import SubmitButton from "../components/atoms/SubmitButton";
import ProgressBar from "../components/molecules/ProgressBar";
import FormItemList, {
  Props as FormItemListProps,
} from "../components/molecules/FormItemList";
import Form from "../components/molecules/Form";
import EntryConfirmation from "../components/molecules/EntryConfirmation";
import EntryComplete from "../components/molecules/EntryComplete";
import GoogleProfileAuthorization from "../components/molecules/GoogleProfileAuthorization";

import {
  Labels,
  formRules,
  labels,
  memberLabels,
  MemberLabels,
} from "../utils/forms/scoutentry";
import { entriesScoutApi } from "../utils/ajax";
import { toastError, toastSuccess } from "../utils/appearError";
import { useParams } from "react-router";
import { useScoutEntryForm } from "../hooks/ScoutEntryFormItems";
import { useScoutEntryMemberForm } from "../hooks/ScoutEntryMemberFormItems";
import { useScoutEntryPaymentForm } from "../hooks/ScoutEntryPaymentFormItems";
import { useScoutEntryMinorForm } from "../hooks/MinorsFormItems";
import Button from "../components/atoms/Button";
interface ParamsProps {
  uuid: string;
}
interface Google {
  name: string;
  id: string;
  email: string;
}
interface EntryInfoState {
  progress: number;
  google: Google;
  entry: Labels;
  members: any[];
  // fixme 型全般直す
  // members: Array<MemberLabels> | undefined;
  currentMemberIndex: number;
}

const getAgeFromBirthday = (birthday: string | undefined): number | boolean => {
  if (birthday === undefined || !birthday.match(/\d{4}\/\d{2}\/\d{1,2}/))
    return false;
  const ymd: Array<string> = birthday.split("/");
  const today = new Date();
  const thisYearBirthDay = new Date(
    today.getFullYear(),
    Number(ymd[1]) - 1,
    Number(ymd[2])
  );
  const age = today.getFullYear() - Number(ymd[0]);
  return today < thisYearBirthDay ? age - 1 : age;
};

const scrollTop = () => {
  document.body.scrollTop = 0; // For Safari
  document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
};

const memberDefault: MemberLabels = {
  name: "",
  firstName: "",
  lastName: "",
  firstNameKana: "",
  lastNameKana: "",
  googleEmail: "",
  address: "",
  gender: "",
  postcode: "",
  birthdate: "",
  parentFirstName: "",
  parentLastName: "",
  parentRelationship: "other",
  parentPhoneNumber: "",
};

const entryDefault: Labels = {
  name: "",
  firstName: "",
  lastName: "",
  firstNameKana: "",
  lastNameKana: "",
  googleEmail: "",
  address: "",
  gender: "",
  postcode: "",
  birthdate: "",
  parentFirstName: "",
  parentLastName: "",
  parentRelationship: "other",
  parentPhoneNumber: "",
  bankName: "",
  bankCode: "",
  branchName: "",
  branchCode: "",
  accountNo: "",
  accountName: "",
  accountType: "normal",
};

const EditScoutEntryinfo: React.FC = (): JSX.Element => {
  const { uuid } = useParams<ParamsProps>();
  const { register, errors, handleSubmit, watch, reset } = useForm<Labels>();
  const steps = ["Google認証", "情報入力", "情報確認", "完了"];
  const [entryInfoState, setEntryInfoState] = useState<EntryInfoState>({
    progress: 1,
    google: {
      id: "",
      name: "",
      email: "",
    },
    entry: entryDefault,
    members: [],
    currentMemberIndex: -1,
  });

  const currentStep =
    entryInfoState.progress > 2
      ? entryInfoState.progress - 1
      : entryInfoState.progress;

  const formItems = useScoutEntryForm(
    entryInfoState.google,
    entryInfoState.entry
  );
  const formMemberItems = useScoutEntryMemberForm(entryInfoState.entry);
  const formPaymentItems = useScoutEntryPaymentForm(entryInfoState.entry);
  const minorFormItems = useScoutEntryMinorForm(entryInfoState.entry);

  const watchEntryBirthdate = watch("birthdate");
  const isMinor = getAgeFromBirthday(watchEntryBirthdate) < 20;

  const parentFormProps: FormItemListProps<Labels> = {
    rules: formRules,
    formProps: formItems,
    errors,
    register,
  };

  const memberFormProps: FormItemListProps<Labels> = {
    rules: formRules,
    formProps: formMemberItems,
    errors,
    register,
  };

  const paymentFormProps: FormItemListProps<Labels> = {
    rules: formRules,
    formProps: formPaymentItems,
    errors,
    register,
  };

  const minorFormProps: FormItemListProps<Labels> = {
    rules: formRules,
    formProps: minorFormItems,
    errors,
    register,
  };

  const authorizeGoogleInfoSuccess = (arg: Google) => {
    setEntryInfoState({ ...entryInfoState, progress: 2, google: arg });
  };

  const resetForm = (form: MemberLabels | Labels) => {
    reset(form);
    scrollTop();
  };

  const backForm = () => {
    setEntryInfoState({
      ...entryInfoState,
      currentMemberIndex: entryInfoState.currentMemberIndex - 1,
    });
    resetForm(entryInfoState.members[entryInfoState.currentMemberIndex - 1]);
  };

  const getNewState = (data: Labels) => {
    if (entryInfoState.currentMemberIndex === -1) {
      return {
        ...entryInfoState,
        entry: data,
        currentMemberIndex: 0,
        progress: 3,
      };
    } else {
      const totalMembers = entryInfoState.members;

      if (entryInfoState.members.length > entryInfoState.currentMemberIndex)
        entryInfoState.members[entryInfoState.currentMemberIndex] = data;
      else totalMembers.push(data);

      return {
        ...entryInfoState,
        members: totalMembers,
        currentMemberIndex: entryInfoState.currentMemberIndex + 1,
      };
    }
  };

  const addMember = async (endFlag?: boolean): Promise<any> => {
    const callback = async (data: Labels) => {
      const state = getNewState(data);
      setEntryInfoState(state);
      if (endFlag) setEntryInfoState({ ...state, progress: 4 });

      const arg =
        entryInfoState.members.length - 1 > entryInfoState.currentMemberIndex
          ? entryInfoState.members[entryInfoState.currentMemberIndex + 1]
          : memberDefault;

      resetForm(arg);
    };

    await handleSubmit(callback)();
  };

  const postEntry = async (
    e: React.FormEvent<HTMLFormElement>
  ): Promise<void> => {
    if (!entryInfoState.google.email || !entryInfoState.entry) return;

    const entry = {
      groupEntry: {
        ...entryInfoState.entry,
        googleId: entryInfoState.google.id,
        googleEmail: entryInfoState.google.email,
        childEntriesAttributes: entryInfoState.members,
      },
    };

    e.preventDefault();

    try {
      await entriesScoutApi.putEntry(uuid, entry);
      toastSuccess("エントリー情報を更新しました。");
      setEntryInfoState({ ...entryInfoState, progress: 5 });
      scrollTop();
    } catch (err) {
      if (err.code === 404) {
        toastError("URLが正しくないか、送信済みです。");
      } else if (err.code === 422) {
        toastError(
          `下記理由のため更新に失敗しました。営業担当にご連絡下さい。` +
            `${err.body.messages}`
        );
      } else {
        toastError("通信エラーにより変更できませんでした");
        throw err;
      }
    }
  };

  return (
    <Content>
      <ProgressBarWrapper progress={currentStep}>
        <ProgressBar steps={steps} progress={currentStep} />
      </ProgressBarWrapper>
      {entryInfoState.progress === 1 && (
        <GoogleProfileAuthorization
          successFunc={authorizeGoogleInfoSuccess}
          text={"mypageで使用するアカウントでログインして下さい"}
        />
      )}
      {(entryInfoState.progress === 2 || entryInfoState.progress === 3) && (
        <StyledFormWrapper>
          <Form
            onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
              e.preventDefault();
              addMember(true);
            }}
            align="center"
          >
            <FormDetailHeader>
              {entryInfoState.currentMemberIndex === -1
                ? "グループクリエイターの"
                : `メンバー${entryInfoState.currentMemberIndex + 1}人目の`}
              個人情報
            </FormDetailHeader>
            {entryInfoState.currentMemberIndex === -1 ? (
              <FormItemList<Labels> {...parentFormProps}></FormItemList>
            ) : (
              <FormItemList<Labels> {...memberFormProps}></FormItemList>
            )}
            {isMinor && (
              <FormItemList<Labels> {...minorFormProps}></FormItemList>
            )}
            {/* fixme JSX 式には 1 つの親要素が必要ですとなるので下記条件文を2つ書いている、、下記は汚いのでよくない */}
            {entryInfoState.currentMemberIndex === -1 && (
              <FormDetailHeader>口座情報</FormDetailHeader>
            )}
            {entryInfoState.currentMemberIndex === -1 && (
              <FormItemList<Labels> {...paymentFormProps}></FormItemList>
            )}
            {entryInfoState.members.length > 0 && (
              <StyledSubmitButtonWrapper>
                <SubmitButton
                  name="commit"
                  value="入力を終了"
                  backgroundColor="#0074AE"
                  dataDisableValue="入力を終了"
                />
              </StyledSubmitButtonWrapper>
            )}
            <StyledButtons>
              {entryInfoState.currentMemberIndex > 0 && (
                <Button
                  as="button"
                  margin="0 1rem 0 0"
                  onClick={(e) => {
                    e.preventDefault();
                    backForm();
                  }}
                  name="commit"
                  type="entry"
                >
                  {"メンバー" +
                    entryInfoState.currentMemberIndex +
                    "人目へ戻る"}
                </Button>
              )}
              <Button
                as="button"
                margin="auto"
                onClick={(e) => {
                  e.preventDefault();
                  addMember();
                }}
                name="commit"
                type="entry"
              >
                {"メンバー" +
                  (entryInfoState.currentMemberIndex + 2) +
                  "人目を入力"}
              </Button>
            </StyledButtons>
          </Form>
        </StyledFormWrapper>
      )}
      {entryInfoState.progress === 4 && (
        <EntryConfirmation
          backPreviousProgress={(): void => {
            setEntryInfoState({
              ...entryInfoState,
              progress: 2,
              currentMemberIndex: -1,
            });
            reset(entryInfoState.entry);
            scrollTop();
          }}
          onSubmit={postEntry}
          memberData={{
            values: {
              members: entryInfoState.members,
            },
            labels: memberLabels,
          }}
          data={{ values: { entry: entryInfoState.entry }, labels }}
        />
      )}
      {entryInfoState.progress === 5 && (
        <EntryComplete
          title={
            <div>
              入力完了
              <br />
              ありがとうございました
            </div>
          }
          notice={<div>営業より後日ご連絡いたします</div>}
        />
      )}
    </Content>
  );
};

export default EditScoutEntryinfo;

const FormDetailHeader = styled.h2`
  width: 100%;
  margin-bottom: 2.4rem;
  text-align: left;
`;

const Content = styled.main`
  position: relative;
  display: inline-block;
  min-height: calc(100vh - 11.2rem);
  padding: 6.4rem 1.6rem;

  @media (max-width: 896px) {
    margin: 0;
    min-height: calc(100vh - 12.4rem);
    padding: 3.2rem 1.6rem;
  }
`;

const ProgressBarWrapper = styled.div<{ progress: number }>`
  display: flex;
  justify-content: center;
  margin: 0 auto;
  margin-bottom: 2.4rem;
  align-items: center;
  padding-inline-start: 0;

  ${({ progress }) =>
    progress === 1 &&
    `
    padding-top: 28.6rem;
    margin-bottom: 4.8rem;

    @media (max-width: 896px) {
      padding-top: 20.7rem;
    }
  `}
`;

const StyledFormWrapper = styled.div`
  width: 100%;
  margin: 0 auto;
  max-width: 60rem;
  min-width: 28rem;
  align-items: center;
`;

const StyledButtons = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
`;

const StyledSubmitButtonWrapper = styled.div`
  justify-content: center;
  display: flex;
  width: 100%;
  margin-bottom: 2rem;
`;
