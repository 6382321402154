import React from "react";
import styled from "styled-components";

interface Props {
  steps: Array<string | number>;
  progress: number;
}

const ProgressBar: React.FC<Props> = (props: Props): JSX.Element => {
  return (
    <ProgressBarWrapper>
      {props.steps.map((step, idx) => {
        return (
          <StepItem
            idx={idx}
            currentProgress={props.progress}
            key={idx}
            data-testid={`progressBarStepItem${idx}`}
          >
            <Circle
              idx={idx}
              currentProgress={props.progress}
              data-testid={`progressBarCircle${idx}`}
            >
              {props.progress > idx + 1 ? "" : idx + 1}
            </Circle>
            <ProgressText
              idx={idx}
              currentProgress={props.progress}
              data-testid={`progressBarProgressText${idx}`}
            >
              {step}
            </ProgressText>
          </StepItem>
        );
      })}
    </ProgressBarWrapper>
  );
};

export default ProgressBar;

const ProgressBarWrapper = styled.ul`
  margin: 0 auto;
  padding-inline-start: 0;
`;

const StepItem = styled.li<{ currentProgress: number; idx: number }>`
  display: inline-block;
  align-items: center;
  width: 8rem;
  vertical-align: top;

  @media (max-width: 896px) {
    margin: 0 1.8rem;
    width: 4.6rem;
  }

  @media (max-width: 340px) {
    margin: 0 1rem;
  }

  &:not(:last-child) {
    > div::before {
      position: absolute;
      margin-left: 1rem;
      display: block;
      background-color: ${({ currentProgress, idx }): string =>
        currentProgress > idx + 1 ? "#0074ae" : "#b0c6da"};
      border-radius: 0.8rem;
      top: 50%;
      left: 100%;
      height: 0.3rem;
      width: 1.6rem;
      content: "";

      @media (max-width: 340px) {
        width: 1rem;
        margin-left: 0.7rem;
      }
    }
  }
`;

const Circle = styled.div<{ currentProgress: number; idx: number }>`
  position: relative;
  text-align: center;
  width: 4.4rem;
  height: 4.4rem;
  border-radius: 50%;
  line-height: 4.4rem;
  margin: 0 auto;
  margin-bottom: 0.4rem;

  ${({ currentProgress, idx }): string =>
    currentProgress > idx + 1
      ? `
    color: #0074AE;
    background-color: #fff;
    border: 2px solid #0074AE;
    box-sizing: border-box;

    &::after {
      display: block;
      content: "\f00c";
      font-family: "Font Awesome 5 Free"; /* stylelint-disable-line font-family-no-missing-generic-family-keyword */

      /* freeのfontawsomeを使用するときはweightを900に設定する */
      font-weight: 900;
      font-size: 1.5rem;
      margin: auto;
      color: #0074AE;
    }
  `
      : ""}

  ${({ currentProgress, idx }): string =>
    currentProgress === idx + 1
      ? `
          color: #fff;
          background-color: #0074ae;
        `
      : ""}

  ${({ currentProgress, idx }): string =>
    currentProgress < idx + 1
      ? `
    color: #B0C6DA;
    background-color: #fff;
    border: 2px solid #B0C6DA;
    box-sizing: border-box;
  `
      : ""}
`;

const ProgressText = styled.p<{ currentProgress: number; idx: number }>`
  margin-bottom: 0;
  font-style: normal;
  font-weight: normal;
  font-size: 1.2rem;
  line-height: 1.7rem;
  text-align: center;

  ${({ currentProgress, idx }): string =>
    currentProgress <= idx + 1 ? "font-weight: bold;" : ""}

  color: ${({ currentProgress, idx }): string =>
    currentProgress >= idx + 1 ? "#0074AE;" : "#B0C6DA;"}
  @media (max-width: 896px) {
    font-size: 1rem;
  }
`;
