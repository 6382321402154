import React from "react";
import styled from "styled-components";

interface Props {
  required: boolean;
  value: string | number;
  onChange: Function;
  label: string;
  margin?: string;
}

const CheckBox: React.FC<Props> = (props: Props): JSX.Element => {
  return (
    <StyledLabel margin={props.margin} data-testid="wrapper">
      <input
        required={props.required}
        type="checkbox"
        value={props.value}
        name="checked"
        onChange={(e): void => props.onChange(e)}
      />
      {props.label}
    </StyledLabel>
  );
};

const StyledLabel = styled.label<Pick<Props, "margin">>`
  margin: ${({ margin }): string | number => (margin ? margin : 0)};

  @media (max-width: 896px) {
    font-size: 1.2rem;
  }

  > input[type="checkbox"] {
    margin-right: 0.8rem;
    vertical-align: baseline;

    @media (max-width: 896px) {
      margin-right: 0.4rem;
      vertical-align: middle;
    }
  }
`;

export default CheckBox;
