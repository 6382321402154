import { RegisterOptions } from "react-hook-form";

type LabelKeys = keyof Labels;

type LabelKeyRegisterOptions = {
  [key in LabelKeys]: RegisterOptions;
};

export interface MemberLabels {
  name: string;
  firstName: string;
  lastName: string;
  firstNameKana: string;
  lastNameKana: string;
  googleEmail: string;
  address: string;
  gender: string;
  postcode: string;
  birthdate: string;
  parentFirstName: string;
  parentLastName: string;
  parentRelationship: "mother" | "father" | "other";
  parentPhoneNumber: string;
}

const memberLabels: { [key in keyof MemberLabels]: string } = {
  name: "クリエイター名",
  firstName: "お名前 (名)",
  lastName: "お名前 (姓)",
  firstNameKana: "フリガナ (名)",
  lastNameKana: "フリガナ (姓)",
  googleEmail: "ログイン用(Google認証)メールアドレス",
  address: "住所",
  gender: "性別",
  postcode: "郵便番号",
  birthdate: "生年月日",
  parentFirstName: "保護者名(名)",
  parentLastName: "保護者名(姓)",
  parentRelationship: "保護者の続柄",
  parentPhoneNumber: "保護者電話番号",
};

export interface Labels {
  name: string;
  firstName: string;
  lastName: string;
  firstNameKana: string;
  lastNameKana: string;
  googleEmail: string;
  address: string;
  gender: string;
  postcode: string;
  birthdate: string;
  parentFirstName: string;
  parentLastName: string;
  parentRelationship: "mother" | "father" | "other";
  parentPhoneNumber: string;
  bankName: string;
  bankCode: string;
  branchName: string;
  branchCode: string;
  accountNo: string;
  accountName: string;
  accountType: "normal" | "checking" | "other";
}

const labels: { [key in keyof Labels]: string } = {
  name: "クリエイター名",
  firstName: "お名前 (名)",
  lastName: "お名前 (姓)",
  firstNameKana: "フリガナ (名)",
  lastNameKana: "フリガナ (姓)",
  googleEmail: "ログイン用(Google認証)メールアドレス",
  address: "住所",
  gender: "性別",
  postcode: "郵便番号",
  birthdate: "生年月日",
  parentFirstName: "保護者名(名)",
  parentLastName: "保護者名(姓)",
  parentRelationship: "保護者の続柄",
  parentPhoneNumber: "保護者電話番号",
  bankName: "銀行名",
  bankCode: "銀行コード",
  branchName: "支店名",
  branchCode: "支店コード",
  accountNo: "口座番号",
  accountName: "口座名義",
  accountType: "口座種別",
};

// 追加したい物をkey_valueで追加する。
const validations: LabelKeyRegisterOptions = {
  name: {
    maxLength: {
      value: 191,
      message: "長すぎます",
    },
  },
  firstName: {
    maxLength: {
      value: 191,
      message: "長すぎます",
    },
  },
  lastName: {
    maxLength: {
      value: 191,
      message: "長すぎます",
    },
  },
  firstNameKana: {
    maxLength: {
      value: 191,
      message: "長すぎます",
    },
    pattern: {
      value: /^[\u30a0-\u30ff　 ー－・]*$/,
      message: "正しい形式で入力してください",
    },
  },
  lastNameKana: {
    maxLength: {
      value: 191,
      message: "長すぎます",
    },
    pattern: {
      value: /^[\u30a0-\u30ff　 ー－・]*$/,
      message: "正しい形式で入力してください",
    },
  },
  googleEmail: {
    pattern: {
      // gmailにする
      value: /^[\w+\-.]+@[a-z\d\-.]+\.[a-z]+$/,
      message: "正しい形式で入力してください",
    },
  },
  address: {
    maxLength: {
      value: 191,
      message: "入力してください",
    },
  },
  postcode: {
    maxLength: {
      value: 7,
      message: "不正な入力値です",
    },
    minLength: {
      value: 7,
      message: "不正な入力値です",
    },
    pattern: /^[1-9]*/,
  },
  gender: {
    required: "選択して下さい",
    pattern: {
      value: /男性|女性|その他/,
      message: "選択肢から選択して下さい",
    },
  },
  birthdate: {
    required: "2020/01/01のように入力して下さい",
    pattern: /\d{4}\/\d{2}\/\d{2}/,
    validate: (value: string) =>
      new Date(value).toString() !== "Invalid Date" ||
      "2020/01/01のように入力して下さい",
  },
  parentFirstName: {
    maxLength: 191,
  },
  parentLastName: {
    maxLength: 191,
  },
  parentRelationship: {
    pattern: {
      value: /mother|father|other/,
      message: "選択して下さい",
    },
  },
  parentPhoneNumber: {
    pattern: {
      value: /^\+?[0-9]+$/,
      message: "半角数字のみで入力してください",
    },
  },
  accountType: {
    pattern: {
      value: /normal|checking|other/,
      message: "正しいものを選択して下さい",
    },
  },
  accountName: {
    maxLength: {
      value: 40,
      message: "長すぎます",
    },
    validate: {
      hankakuKomojiCheck: (value: string) =>
        !value.match(/[ｧｨｩｪｫｯｬｭｮ]+/) || "半角小文字は使用できません",
      halfWidthSpaceCheck: (value: string) =>
        value.trim().length > 0 || "半角スペースのみで登録できません",
      pattern: (value: string) =>
        value.match(
          /^[0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZｱｲｳｴｵｶｷｸｹｺｻｼｽｾｿﾀﾁﾂﾃﾄﾅﾆﾇﾈﾉﾊﾋﾌﾍﾎﾏﾐﾑﾒﾓﾔﾕﾖﾗﾘﾙﾚﾛﾜｦﾝｰﾞﾟ.｢｣()\-/\s]+$/
        ) || "半角ｶﾅ・半角数値・半角英字・一部記号のみ入力可能です",
    },
  },
  bankName: {
    maxLength: {
      value: 191,
      message: "長すぎます",
    },
  },
  bankCode: {
    minLength: {
      value: 4,
      message: "４桁で入力してください",
    },
    maxLength: {
      value: 4,
      message: "４桁で入力してください",
    },
    pattern: {
      value: /^[0-9]+$/,
      message: "正しい形式で入力してください",
    },
  },
  branchName: {
    maxLength: {
      value: 191,
      message: "長すぎます",
    },
  },
  branchCode: {
    minLength: {
      value: 3,
      message: "3桁で入力してください",
    },
    maxLength: {
      value: 3,
      message: "3桁で入力してください",
    },
    pattern: {
      value: /^[0-9]+$/,
      message: "正しい形式で入力してください",
    },
  },
  accountNo: {
    minLength: {
      value: 7,
      message: "7桁で入力してください",
    },
    maxLength: {
      value: 7,
      message: "7桁で入力してください",
    },
    pattern: {
      value: /^[0-9]+$/,
      message: "正しい形式で入力してください",
    },
  },
};

const formRules = (name: keyof Labels) => {
  const validation = validations[name];

  return { required: "入力してください", ...validation };
};

// この型を制限する
const getLabel = (name: keyof Labels): string => {
  return labels[name];
};

export { formRules, getLabel, labels, memberLabels };
