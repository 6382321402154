import React from "react";
import styled from "styled-components";

const EntryComplete: React.FC = () => {
  return (
    <EntryMaintenanceWrapper data-testid="entryComplete">
      <Text>現在メンテナンス中です</Text>
      <Notification>
        ご迷惑をおかけしますが時間を置いて再度アクセスしてください
      </Notification>
    </EntryMaintenanceWrapper>
  );
};

const EntryMaintenanceWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 78rem;
  min-height: 64rem;
  margin: auto;
`;

const Text = styled.p`
  font-style: normal;
  font-weight: bold;
  font-size: 2.4rem;
  line-height: 1.9rem;
  text-align: center;
  margin-bottom: 1.2rem;
  color: #111;
`;

const Notification = styled.p`
  margin-top: 1.2rem;
  text-align: center;
  font-style: normal;
  font-weight: normal;
  font-size: 1.7rem;
  line-height: 1.7rem;
  color: #777;
`;

export default EntryComplete;
