import React, { useState } from "react";
import { Provider } from "react-redux";
import { Redirect } from "react-router-dom";
import "./assets/scss/application.scss";
import * as Sentry from "@sentry/browser";
import styled from "styled-components";

import store from "./store";
import { paths, pathType } from "./utils/paths";

import PrivateRoute from "./containers/PrivateRoute";
import PublicRoute from "./containers/PublicRoute";

import Error404 from "./containers/Error404";

import Header from "./components/organisms/Header";
import Sidebar from "./components/organisms/Sidebar";
import Footer from "./components/organisms/Footer";

import Maintenance from "./containers/Maintenance";

import "react-toastify/dist/ReactToastify.css";
import { BrowserRouter as Router, Switch } from "react-router-dom";

if (process.env.NODE_ENV === "production") {
  Sentry.init({ dsn: process.env.REACT_APP_SENTRY_URL });
}

const App: React.FC = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const isMaintenance = process.env.REACT_APP_MAINTENANCE_MODE;

  return (
    <Provider store={store}>
      <Wrapper mobileMenuOpen={mobileMenuOpen}>
        <Router>
          <Header
            mobileMenuChangeFunc={(isOpen: boolean): void =>
              setMobileMenuOpen(isOpen)
            }
            mobileMenuOpen={mobileMenuOpen}
          />
          <Sidebar
            mobileMenuChangeFunc={(isOpen: boolean): void =>
              setMobileMenuOpen(isOpen)
            }
            mobileMenuOpen={mobileMenuOpen}
          />
          {isMaintenance && <Maintenance />}
          {!isMaintenance && (
            <Switch>
              <Redirect from="/signin" to="/sign_in" />
              {(Object.keys(paths) as Array<keyof typeof paths>).map(
                (key: keyof typeof paths, index: number): JSX.Element => {
                  if (pathType(key) === "public")
                    return (
                      <PublicRoute
                        exact
                        path={key}
                        component={paths[key].component}
                        key={index}
                      />
                    );
                  else
                    return (
                      <PrivateRoute
                        exact
                        path={key}
                        component={paths[key].component}
                        key={index}
                      />
                    );
                }
              )}
              <PublicRoute component={Error404} />
            </Switch>
          )}
          <Footer mobileMenuOpen={mobileMenuOpen} />
        </Router>
      </Wrapper>
    </Provider>
  );
};

const Wrapper = styled.div<{ mobileMenuOpen: boolean }>`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;

  @media (max-width: 896px) {
    min-height: initial;
  }

  ${(props): string =>
    props.mobileMenuOpen
      ? `
    height: 100vh;
    overflow: hidden;
  `
      : ``}
`;

export default App;
