import axios from "axios";
import { GoogleApi, PeopleMe, Profile } from "./types";
import { toastError } from "../appearError";

export const profile = async (token: string): Promise<Profile | void> => {
  try {
    const res = await axios.get<PeopleMe>(
      "https://people.googleapis.com/v1/people/me?personFields=names,emailAddresses",
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return {
      name: res.data.names[0].displayName,
      email: res.data.emailAddresses[0].value,
      id: res.data.names[0].metadata.source.id,
    };
  } catch (e) {
    toastError(
      e?.response?.data?.error?.message ||
        "エラーが発生しました。再度エントリーをし直してください"
    );
  }
};

export function fetchAccessTokenFromUrl(): string {
  const hash = window.location.hash;

  const resHash: GoogleApi = {
    accessToken: "",
  };

  // 型の関係でaccess tokenのみを入れている。
  hash.split("&").forEach((hk): void => {
    const temp = hk.split("=");
    if (temp[0] === "access_token") resHash["accessToken"] = temp[1];
  });

  const googleApiAccessToken = resHash.accessToken;

  return googleApiAccessToken;
}
