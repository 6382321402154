import { useState, useEffect } from "react";
import * as googleApi from "../utils/google";
import axios from "axios";

interface CreasUserRegistrationCheck {
  isLoading: boolean;
  isValidToken: boolean;
  token: string;
}

// もともとcreasだけだったが、ライブ配信の人も個人情報の登録が必須になった
export function useGetOauthToken(
  provider?: string
): CreasUserRegistrationCheck {
  const [status, setStatus] = useState<CreasUserRegistrationCheck>({
    isLoading: true,
    isValidToken: false,
    token: "",
  });

  useEffect(() => {
    const callback = async () => {
      if (provider === "google") {
        const googleApiAccessToken = googleApi.fetchAccessTokenFromUrl();
        const googleInfo = await googleApi.profile(googleApiAccessToken);

        if (!googleInfo)
          return setStatus({
            token: "",
            isLoading: false,
            isValidToken: false,
          });

        setStatus({
          token: googleApiAccessToken,
          isLoading: false,
          isValidToken: !!googleApiAccessToken,
        });
      } else if (provider === "line") {
        const query = window.location.search;
        const params = new URLSearchParams(query);
        const code = params.get("code") || "";
        const state = params.get("state") || "";
        const friendid = params.get("friendid") || "";
        let redirectUri = `${process.env.REACT_APP_HOST_URL}/oauth/line/callback`;
        if (friendid) redirectUri += `?friendid=${friendid}`;

        const tokenPayload = new URLSearchParams();
        tokenPayload.append("code", code);
        tokenPayload.append("state", state);
        tokenPayload.append("grant_type", "authorization_code");
        // TO DO: 下記は全て環境変数に置き換える
        tokenPayload.append(
          "client_id",
          `${process.env.REACT_APP_OAUTH_LINE_CLIENT_ID}` || ""
        );
        tokenPayload.append(
          "client_secret",
          `${process.env.REACT_APP_OAUTH_LINE_CLIENT_SECRET}` || ""
        );
        tokenPayload.append("redirect_uri", redirectUri);

        const headers = {
          "Content-Type": "application/x-www-form-urlencoded",
        };
        try {
          // アクセストークンの取得に使用される認可コードは1回のみ利用可能(再取得はできない)
          const res = await axios.post(
            "https://api.line.me/oauth2/v2.1/token",
            tokenPayload,
            { headers }
          );
          setStatus({
            isLoading: false,
            isValidToken: !!res.data.id_token,
            token: res.data.id_token,
          });
        } catch (err) {
          setStatus({ isLoading: false, isValidToken: false, token: "" });
        }
      } else setStatus({ isLoading: false, isValidToken: false, token: "" });
    };
    callback();
  }, [provider]);
  return status;
}
