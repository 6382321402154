import React, { useState, useEffect } from "react";
import Content from "../components/templates/Content";
import { createAxiosHook } from "../utils/createAxiosHook";
import SelectBox from "../components/atoms/SelectBox";
import LineChart from "../components/molecules/LineChart";
import List from "../components/molecules/List";
import DownloadNav from "../components/molecules/DownloadNav";
import Alert from "../components/molecules/Alert";
import { types as AjaxTypes } from "../utils/ajax";
import { revenuesApi } from "../utils/ajax";
import { creator, types } from "../store";
import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";

const useRevenues = createAxiosHook<AjaxTypes.Revenues.Basic[]>(
  "get",
  `/mypage/api/v1/creator/revenues`
);
interface SelectOptions {
  value: number;
  text: string;
}

interface MonthlyRevenue {
  month: string;
  amount: string;
}

interface RevenuesInfoState {
  year: string;
  downloadButtonEnable: boolean;
}

const CURRENT_YEAR = new Date().getFullYear();

const selectProperties = (): SelectOptions[] => {
  const result: SelectOptions[] = [];
  // 画面表示年〜2017年の本年まで表示
  for (let i = CURRENT_YEAR; i >= 2017; i--) {
    result.push({ value: i, text: i + "年" });
  }

  return result;
};

const Revenues: React.FC = (): JSX.Element => {
  const creatorState: creator.types.State = useSelector<
    types.RootState,
    creator.types.State
  >((state) => state.creator);

  const isAvailableRevenue = creatorState.isRevenueViewed;

  const [revenuesInfoState, setRevenuesInfoState] = useState<RevenuesInfoState>(
    {
      year: String(CURRENT_YEAR),
      downloadButtonEnable: false,
    }
  );
  const { data, loading, error } = useRevenues([], {
    year: revenuesInfoState.year,
  });

  useEffect(() => {
    const func = async () => {
      const revenuesExistenceRes =
        await revenuesApi.ableToDownloadPaymentRecords(
          Number(revenuesInfoState.year)
        );
      setRevenuesInfoState((prevState) => ({
        ...prevState,
        downloadButtonEnable: revenuesExistenceRes.flag,
      }));
    };

    if (
      revenuesInfoState.year !== String(CURRENT_YEAR) &&
      revenuesInfoState.year > "2020"
    )
      func();
  }, [revenuesInfoState.year]);

  if (loading) return <div>Loading....</div>;
  if (error) return <div>取得に失敗しました</div>;
  if (!isAvailableRevenue) return <Redirect to="/404" />;

  const showMonth = (str: string): number => {
    return Number(str.replace(/^\d{4}-/, ""));
  };

  const monthlyValues = (report: AjaxTypes.Revenues.Basic[]): string[] => {
    return report.map((x) => x.month);
  };

  const paymentValues = (report: AjaxTypes.Revenues.Basic[]): number[] => {
    return report.map((x) => x.amount);
  };

  const paymentReportSortAscByMonth = data
    .slice()
    .sort((a: AjaxTypes.Revenues.Basic, b: AjaxTypes.Revenues.Basic) => {
      return showMonth(a.month) - showMonth(b.month);
    });

  const monthToString = (month: string): string => {
    const date = new Date(month);
    return `${date.getFullYear()}年${date.getMonth() + 1}月`;
  };

  const amountToString = (amount: string | number): string => {
    return typeof amount === "number"
      ? `${amount.toLocaleString()}円`
      : "未確定";
  };

  const revenueDetailPath = (month: string): string => {
    const date = new Date(month);
    return `/creator/revenues/${date.getFullYear()}/${date.getMonth() + 1}`;
  };

  const downloadUrl = `${process.env.REACT_APP_CODA_API_SERVER_URL}/mypage/api/v1/creator/revenues/download/payment_records_pdf/${revenuesInfoState.year}`;

  const listItems = data.map((payment: MonthlyRevenue) => ({
    title: monthToString(payment.month),
    detail: amountToString(payment.amount),
    to: revenueDetailPath(payment.month),
  }));

  return (
    <Content title="収益">
      <SelectBox
        options={selectProperties()}
        defaultValue={CURRENT_YEAR}
        onChange={(e: React.FormEvent<HTMLSelectElement>): void =>
          setRevenuesInfoState({
            ...revenuesInfoState,
            year: e.currentTarget.value,
          })
        }
        position="absolute"
        top="4.8rem"
        right="4.8rem"
      />
      {data.length === 0 && (
        <Alert title="この年度にはデータがありません" type="danger" />
      )}
      <LineChart
        labels={monthlyValues(paymentReportSortAscByMonth)}
        values={paymentValues(paymentReportSortAscByMonth)}
        title="月別支払額"
      />
      {revenuesInfoState.year !== String(CURRENT_YEAR) &&
        revenuesInfoState.year > "2020" && (
          <DownloadNav
            title="支払い調書ダウンロード"
            text={`${revenuesInfoState.year}年度支払い調書のダウンロードはこちらです。`}
            disabled={!revenuesInfoState.downloadButtonEnable}
            url={downloadUrl}
          />
        )}
      <List items={listItems} />
    </Content>
  );
};

export default Revenues;
