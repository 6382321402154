import React from "react";
import Content from "../components/templates/Content";
import { creator, types } from "../store";
import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";

const TermsFileUrl = `${process.env.REACT_APP_MYPAGE_IMAGE_URL}terms/voom/terms_of_use.pdf#toolbar=0&navpanes=0'"`;

const VoomTerms: React.FC = (): JSX.Element => {
  const creatorState: creator.types.State = useSelector<
    types.RootState,
    creator.types.State
  >((state) => state.creator);

  if (creatorState.isLoading) return <div>Loading....</div>;
  if (!creatorState.isVoomTermsViewed) return <Redirect to="/404" />;

  return (
    <Content title="LINE VOOM支援施策規約">
      <iframe
        title="LINE VOOM支援施策規約"
        src={TermsFileUrl}
        width="100%"
        height="800px"
      ></iframe>
    </Content>
  );
};

export default VoomTerms;
