import { Reducer } from "redux";
import { State, ActionTypes, Action } from "./types";
import { initialState } from "./initialStates";

export const reducer: Reducer<State> = (
  state = initialState,
  action: Action
) => {
  switch (action.type) {
    case ActionTypes.FETCH_REQUEST: {
      return { ...state, isLoading: true };
    }
    case ActionTypes.FETCH_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        ...action.payload,
      };
    }
    case ActionTypes.FETCH_ERROR: {
      return { ...state, isLoading: false };
    }
    default:
      return state;
  }
};
