import React from "react";
import styled from "styled-components";

interface Props {
  title: string;
  detail?: string | number;
  as?: any;
  to?: string | LinkTo;
  onClick?: (e: React.FormEvent<HTMLInputElement>) => void;
}

interface LinkTo {
  pathname: string;
  state: EditState;
}

interface EditState {
  label: string;
  value: string;
  type: string;
}

const defaultProps = {
  as: "li",
};

const ListItem: React.FC<Props> = (props: Props) => {
  return (
    <StyledListItem
      as={props.as}
      to={props.to}
      onClick={props.onClick}
      data-testid="listItem"
    >
      <StyledTitle>{props.title}</StyledTitle>
      <StyledDetail
        dangerouslySetInnerHTML={{ __html: `${props.detail}` }}
      ></StyledDetail>
    </StyledListItem>
  );
};

ListItem.defaultProps = defaultProps;

const StyledListItem = styled.li`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 6rem;
  border-bottom: 0.1rem solid #eee;
  color: #000;
  text-decoration: none;
  transition: all 0.2s ease-in-out;

  &[href] {
    padding: 0 4rem 0 0;

    &::after {
      content: "";
      display: block;
      position: absolute;
      top: 50%;
      right: 0.4rem;
      width: 1.2rem;
      height: 1.2rem;
      transform: translateY(-50%) rotate(45deg);
      padding: 0;
      border-top: 0.2rem solid #676767;
      border-right: 0.2rem solid #676767;
      pointer-events: none;
      transition: all 0.2s ease-in-out;
    }

    &:hover {
      text-decoration: none;

      &::after {
        border-color: #3e94be;
      }
    }
  }

  @media (max-width: 896px) {
    height: 4.6rem;
  }
`;

const StyledTitle = styled.div`
  font-size: 1.8rem;

  @media (max-width: 896px) {
    font-size: 1.4rem;
  }
`;

const StyledDetail = styled.div`
  font-size: 1.8rem;
  font-weight: bold;
  text-align: right;

  @media (max-width: 896px) {
    font-size: 1.4rem;
  }
`;

export default ListItem;
