import React from "react";
import styled from "styled-components";

interface Props {
  children: React.ReactNode;
  as?: any;
  margin?: string;
}

const defaultProps = {
  as: "h1",
  margin: "0 0 2.4rem",
};

const Title: React.FC<Props> = (props: Props): JSX.Element => {
  return (
    <StyledTitle as={props.as} margin={props.margin}>
      {props.children}
    </StyledTitle>
  );
};

Title.defaultProps = defaultProps;

const StyledTitle = styled.h1<Pick<Props, "as" | "margin">>`
  margin: ${({ margin }): string | number => (margin ? margin : 0)};
  line-height: 1;
  font-weight: bold;
  font-size: ${({ as }): string => (as === "h1" ? "2.4rem" : "1.8rem")};

  @media (max-width: 896px) {
    font-size: ${({ as }): string => (as === "h1" ? "1.8rem" : "1.6rem")};
  }
`;

export default Title;
