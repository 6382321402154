import React from "react";
import { useTerms } from "../hooks/Terms";
import TermsCommonForm from "./terms/TermsCommonForm";

const UuumNetworkTerm: React.FC = () => {
  const networkTermsPram = { category: "network" };
  const { terms } = useTerms(networkTermsPram);

  const networkObj = {
    title: "NETWORK利用規約",
    content: terms.content,
  };
  return <TermsCommonForm obj={networkObj} />;
};

export default UuumNetworkTerm;
