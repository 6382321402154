import React from "react";
import styled from "styled-components";

interface Props {
  type: "text" | "password";
  value?: string | number;
  name?: string;
  placeholder?: string;
  onChange?: Function;
  margin?: string;
}

// rulesについて、最初に渡されたものから順番に評価して、失敗した段階でエラーオブジェクトのtypeを更新する。
// FormInputに全て移行する。
const Input: React.FC<Props> = (props: Props) => {
  return (
    <StyledWrapper margin={props.margin} data-testid="inputWrapper">
      <StyledInput
        type={props.type}
        value={props.value}
        name={props.name}
        placeholder={props.placeholder}
        onChange={(e: React.FormEvent<HTMLInputElement>): void =>
          props.onChange ? props.onChange(e) : ""
        }
      />
    </StyledWrapper>
  );
};

const StyledWrapper = styled.div<Pick<Props, "margin">>`
  margin: ${({ margin }): string | number => (margin ? margin : "auto")};
  width: 100%;
`;

const StyledInput = styled.input`
  width: 100%;
  padding: 0 1.6rem;
  line-height: 4rem;
  font-size: 1.6rem;
  border: 0.1rem solid #eee;
  border-radius: 0.6rem;

  &:hover {
    filter: opacity(0.8);
  }
`;

export default Input;
