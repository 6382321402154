import React from "react";
import styled from "styled-components";
import { createAxiosHook } from "../../utils/createAxiosHook";
import { initialResponses } from "../../utils/ajax";

const createMonthlyRevenuesHookBase = (
  year: number,
  month: number
): Function => {
  return createAxiosHook(
    "get",
    `/mypage/api/v1/creator/revenues/${year}/${month}`
  );
};

const MonthlyPaymentsChartSection: React.FC = (): JSX.Element => {
  const d = new Date();
  const year = d.getFullYear();
  const month = d.getMonth() + 1;

  const useMonthlyRevenues = createMonthlyRevenuesHookBase(year, month);
  const { data: RevenueDetail } = useMonthlyRevenues(
    initialResponses.revenues.detail,
    {
      year,
      month,
    }
  );

  return (
    <Section>
      <Ammount>
        {RevenueDetail.paymentAmount?.toLocaleString()}
        <small>円</small>
      </Ammount>
      <Text>
        {year}年{month}月<small>({RevenueDetail.status})</small>
      </Text>
    </Section>
  );
};

const Section = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  text-align: right;

  @media (max-width: 896px) {
    margin-bottom: 0;
  }
`;

const Ammount = styled.p`
  margin-bottom: 1.2rem;
  line-height: 1;
  font-size: 4rem;
  font-weight: bold;

  @media (max-width: 896px) {
    float: none;
    text-align: right;
  }

  > small {
    margin-left: 0.8rem;
    font-size: 1.8rem;
  }
`;

const Text = styled.p`
  margin: 0;
  font-size: 1.4rem;
  line-height: 1;

  > small {
    margin-left: 0.4rem;
    font-size: 1.4rem;
  }
`;

export default MonthlyPaymentsChartSection;
