import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

interface Props {
  children: React.ReactNode;
  title: string;
  link?: string;
  padding?: string;
  linkText?: string;
  isExternalLink?: boolean;
}

const SmallBox: React.FC<Props> = (props: Props) => {
  return (
    <StyledSmallBox>
      <StyledInner>
        <StyledTitle>{props.title}</StyledTitle>
        <StyledDetail>{props.children}</StyledDetail>
      </StyledInner>
      {props.link && (
        <StyledFooter>
          {props.isExternalLink ? (
            <a href={props.link}>{props.linkText || "もっと見る"}</a>
          ) : (
            <Link to={props.link}>{props.linkText || "もっと見る"}</Link>
          )}
        </StyledFooter>
      )}
    </StyledSmallBox>
  );
};

const StyledSmallBox = styled.div`
  width: 100%;
  margin: 0 0 2.4rem;
  box-sizing: border-box;
  border: 0.1rem solid #e0e0e0;
  border-radius: 0.4rem;
  background: #fff;
  -webkit-column-break-inside: avoid;
  page-break-inside: avoid;
  break-inside: avoid;

  @media (max-width: 896px) {
    margin: 0 0 1.6rem;
  }
`;

const StyledInner = styled.div`
  padding: 2.4rem;
`;

const StyledTitle = styled.h2`
  margin: 0 0 1.8rem;
  font-size: 1.8rem;
  font-weight: bold;
  line-height: 2.4rem;
  white-space: pre-wrap;
`;

const StyledDetail = styled.div`
  margin: 0;
  font-size: 1.6rem;
  line-height: 2.4rem;

  > p {
    margin: 0;
  }
`;

const StyledFooter = styled.div`
  padding: 1.2rem 2.4rem;
  line-height: 1;
  text-align: right;
  border-top: 0.1rem solid #e0e0e0;
`;

export default SmallBox;
