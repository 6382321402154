import React, { useState } from "react";
import styled from "styled-components";
import { BasicForm } from "../../utils/forms/types";

export interface SelectValue extends BasicForm {
  options: Array<Option>;
}

interface Option {
  value: string | number;
  text: string | number;
}

// rulesについて、最初に渡されたものから順番に評価して、失敗した段階でエラーオブジェクトのtypeを更新する。
const Select: React.FC<SelectValue> = (props: SelectValue) => {
  const [isSelected, setIsSelected] = useState(
    !!props.defaultValue && props.defaultValue !== "選択して下さい"
  );

  return (
    <StyledWrapper margin={props.margin}>
      <StyledSelect
        ref={props.register(props.rules)}
        defaultValue={props.defaultValue}
        name={props.name}
        isSelected={isSelected}
        onChange={(e): void => {
          setIsSelected(e?.target.value !== undefined);
        }}
        id={props.name}
      >
        <option
          key={"isNotSelected"}
          value={undefined}
          hidden
          data-testid="select-option"
        >
          選択して下さい
        </option>
        {props.options.map((item, index) => (
          <option key={index} value={item.value} data-testid="select-option">
            {item.text}
          </option>
        ))}
      </StyledSelect>
      {props.errors[props.name]?.message && (
        <ErrorMessage data-testid="errorMessage">
          {props.errors[props.name]?.message}
        </ErrorMessage>
      )}
    </StyledWrapper>
  );
};

const StyledWrapper = styled.div<{ margin?: string }>`
  margin: ${({ margin }): string => (margin ? margin : "auto")};
  width: 100%;
  position: relative;

  &::after {
    content: "";
    display: block;
    position: absolute;
    top: 1.8rem;
    right: 1.9rem;
    width: 0.8rem;
    height: 0.8rem;
    transform: rotate(135deg);
    padding: 0;
    border-top: 0.2rem solid rgb(103, 103, 103);
    border-right: 0.2rem solid rgb(103, 103, 103);
    pointer-events: none;
  }
`;

const StyledSelect = styled.select<{ isSelected: boolean }>`
  width: 100%;
  height: 4.4rem;
  line-height: 4rem;
  font-size: 1.6rem;
  padding-left: 1.6rem;
  padding-right: 1.9rem;
  appearance: none;
  color: ${({ isSelected }): string => (isSelected ? "" : "#767676")};
  background: #fff;
  border: 1px solid #ddd;
  box-sizing: border-box;
  border-radius: 6px;

  &:hover {
    filter: opacity(0.8);
  }
`;

const ErrorMessage = styled.p`
  color: red;
  font-size: 1.2rem;
`;

export default Select;
