import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Content from "../../components/templates/Content";

type Props = {
  obj: {
    title: string;
    content: string;
  };
};

const TermsCommonForm: React.FC<Props> = ({ obj }) => {
  return (
    <Content title={obj.title}>
      <StyledBody dangerouslySetInnerHTML={{ __html: obj.content }} />
    </Content>
  );
};

TermsCommonForm.propTypes = {
  obj: PropTypes.shape({
    title: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
  }).isRequired,
};

const StyledBody = styled.div`
  font-size: 1.4rem;
  line-height: 1.5;

  h2 {
    margin-bottom: 1rem;
    line-height: 1;
    font-size: 1.6rem;
    font-weight: bold;
  }

  ul {
    list-style: none;
    padding: 0;
    margin-bottom: 1.6rem;
  }

  ol {
    list-style: decimal;
    padding-left: 3.2rem;
    margin-bottom: 1.6rem;
  }

  > section {
    margin: 2.4rem 0;
  }
`;

export default TermsCommonForm;
