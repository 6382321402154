import React from "react";
import styled from "styled-components";

interface Props {
  label: string;
  name: string;
}

// rulesについて、最初に渡されたものから順番に評価して、失敗した段階でエラーオブジェクトのtypeを更新する。
const Label: React.FC<Props> = (props: Props) => {
  return <StyledLabel htmlFor={props.name}>{props.label}</StyledLabel>;
};

const StyledLabel = styled.label`
  font-weight: bold;
  font-size: 1.4rem;
  margin-bottom: 1.2rem;
`;

export default Label;
