import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { useForm } from "react-hook-form";
import snakeCaseKeys from "snakecase-keys";

import Content from "../components/templates/Content";
import Alert from "../components/molecules/Alert";
import FormItemList, {
  Props as FormItemListProps,
  FormItemProps,
} from "../components/molecules/FormItemList";
import Form from "../components/molecules/Form";
import SubmitButton from "../components/atoms/SubmitButton";

import { createAxiosHook } from "../utils/createAxiosHook";
import {
  initialResponses,
  payments as paymentAjax,
  businesssInvoice as businessInvoiceAjax,
} from "../utils/ajax";
import { toastSuccess, toastError } from "../utils/appearError";
import { Labels, formRules } from "../utils/forms/payments";

const usePayment = createAxiosHook("get", "/mypage/api/v1/creator/payee");
const useBusinessInvoice = createAxiosHook(
  "get",
  "/mypage/api/v1/creator/business_invoice"
);

interface Payment {
  title: string;
  detail: string | number;
  to?: string | LinkTo;
}

interface LinkTo {
  pathname: string;
  state: EditState;
}

interface EditState {
  label: string;
  value: string;
  paymentType: string;
  inputType: string;
}

const StyleDescription = styled.div`
  padding-top: 1em;
  color: gray;
  font-size: 0.8rem;
`;

const formProdProps: FormItemProps<Labels> = [
  {
    formItemProps: {
      type: "text",
      placeholder: "全角",
      name: "bankName",
    },
    field: "input",
    label: "銀行名",
  },
  {
    formItemProps: {
      type: "text",
      placeholder: "半角数字 4桁",
      name: "bankCode",
      maxlength: 4,
      minlength: 4,
    },
    field: "input",
    label: "銀行コード",
  },
  {
    formItemProps: {
      type: "text",
      placeholder: "全角",
      name: "branchName",
    },
    field: "input",
    label: "支店名",
  },
  {
    formItemProps: {
      type: "text",
      placeholder: "半角数字 3桁",
      name: "branchCode",
      maxlength: 3,
      minlength: 3,
    },
    field: "input",
    label: "支店コード",
  },
  {
    formItemProps: {
      name: "accountType",
      options: [
        { value: "normal", text: "普通" },
        { value: "checking", text: "当座" },
        { value: "other", text: "その他" },
      ],
    },
    field: "select",
    label: "口座種別",
  },
  {
    formItemProps: {
      type: "text",
      placeholder: "半角カナ 英数字 ※小文字不可",
      name: "accountName",
      description: (
        <StyleDescription>
          法人口座に切り替えをご希望の場合は、書類の審査がございますので情報変更前に
          <a href="mailto:creas-contact@uuum.jp">creas-contact@uuum.jp</a>
          まで必ずご連絡ください。審査を経ず法人口座にご変更された場合、法人取引には切り替わりませんのでご注意ください。
        </StyleDescription>
      ),
    },
    field: "input",
    label: "口座名義",
  },
  {
    formItemProps: {
      type: "text",
      placeholder: "半角数字 7桁",
      name: "accountNo",
      maxlength: 7,
      minlength: 7,
    },
    field: "input",
    label: "口座番号",
  },
];

const formDevProps: FormItemProps<Labels> = [
  {
    formItemProps: {
      type: "text",
      placeholder: "全角",
      name: "bankName",
    },
    field: "input",
    label: "銀行名",
  },
  {
    formItemProps: {
      type: "text",
      placeholder: "半角数字 4桁",
      name: "bankCode",
      maxlength: 4,
      minlength: 4,
    },
    field: "input",
    label: "銀行コード",
  },
  {
    formItemProps: {
      type: "text",
      placeholder: "全角",
      name: "branchName",
    },
    field: "input",
    label: "支店名",
  },
  {
    formItemProps: {
      type: "text",
      placeholder: "半角数字 3桁",
      name: "branchCode",
      maxlength: 3,
      minlength: 3,
    },
    field: "input",
    label: "支店コード",
  },
  {
    formItemProps: {
      name: "accountType",
      options: [
        { value: "normal", text: "普通" },
        { value: "checking", text: "当座" },
        { value: "other", text: "その他" },
      ],
    },
    field: "select",
    label: "口座種別",
  },
  {
    formItemProps: {
      type: "text",
      placeholder: "半角カナ 英数字 ※小文字不可",
      name: "accountName",
      description: (
        <StyleDescription>
          法人口座に切り替えをご希望の場合は、書類の審査がございますので情報変更前に
          <a href="mailto:creas-contact@uuum.jp">creas-contact@uuum.jp</a>
          まで必ずご連絡ください。審査を経ず法人口座にご変更された場合、法人取引には切り替わりませんのでご注意ください。
        </StyleDescription>
      ),
    },
    field: "input",
    label: "口座名義",
  },
  {
    formItemProps: {
      type: "text",
      placeholder: "半角数字 7桁",
      name: "accountNo",
      maxlength: 7,
      minlength: 7,
    },
    field: "input",
    label: "口座番号",
  },
  {
    formItemProps: {
      type: "text",
      placeholder: "登録番号 13桁あ",
      name: "businessInvoiceNumber",
      maxlength: 13,
      minlength: 13,
      description: (
        <StyleDescription>
          インボイス制度 登録番号が変更となる場合は、
          <a href="mailto:creas-contact@uuum.jp">creas-contact@uuum.jp</a>
          までご連絡ください。ご連絡いただけない場合、支払証明書上のインボイス制度
          登録番号は変更されませんのでご注意ください。
        </StyleDescription>
      ),
    },
    field: "input",
    label: "インボイス制度 登録番号(数値のみ13桁をご入力ください)",
  },
];

const formProps =
  process.env.REACT_APP_CODA_API_SERVER_URL !== "https://api.mypage.uuum.jp"
    ? formDevProps
    : formProdProps;

const Payment: React.FC = () => {
  const { data: payment, loading } = usePayment(
    initialResponses.payments.basic
  );
  const { data: businessInvoice, loading: loadingBusinessIncvoice } =
    useBusinessInvoice(initialResponses.businessInvoice.basic);
  const { register, errors, handleSubmit, reset } = useForm<Labels>();
  const [wasNotReset, setWasNotReset] = useState(true);

  const onSubmitFunc = async (data: Labels): Promise<void> => {
    try {
      await paymentAjax.update(snakeCaseKeys(data));
      await businessInvoiceAjax.update(snakeCaseKeys(data));
      toastSuccess("支払情報を変更しました");
    } catch (e) {
      toastError("通信エラーにより変更できませんでした");
    }
  };

  useEffect(() => {
    if (
      payment.paymentMethod === "zengin" &&
      wasNotReset &&
      loading === false &&
      loadingBusinessIncvoice === false
    ) {
      reset({
        bankName: payment.zenginBank.bankName,
        bankCode: payment.zenginBank.bankCode,
        branchName: payment.zenginBank.branchName,
        branchCode: payment.zenginBank.branchCode,
        accountType: payment.zenginBank.accountType,
        accountName: payment.zenginBank.accountName,
        accountNo: payment.zenginBank.accountNo,
        businessInvoiceNumber: businessInvoice.businessInvoiceNumber,
      });
      setWasNotReset(false);
    }
    const businessInvoiceNumber = formProps.find((item) => {
      return item.formItemProps.name === "businessInvoiceNumber";
    });
    if (businessInvoiceNumber?.formItemProps !== undefined) {
      businessInvoiceNumber.formItemProps.disabled =
        businessInvoice?.businessInvoiceStatus === "in_review" ||
        businessInvoice?.businessInvoiceStatus === "approved";
    }
    return;
  }, [
    payment,
    businessInvoice,
    reset,
    wasNotReset,
    loading,
    loadingBusinessIncvoice,
  ]);

  const onSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
    handleSubmit((data: Labels) => onSubmitFunc(data))(e);
  };

  const multiFormProps: FormItemListProps<Labels> = {
    rules: formRules,
    formProps,
    errors,
    register,
  };

  if (loading || loadingBusinessIncvoice) return <div />;

  return (
    <Content title="支払情報">
      {payment.paymentMethod === "zengin" && (
        <Form onSubmit={onSubmit} align="center">
          <FormItemList<Labels> {...multiFormProps}></FormItemList>
          <SubmitButton
            name="commit"
            value="更新"
            backgroundColor="#0074AE"
            dataDisableValue="更新"
          />
        </Form>
      )}
      {payment.paymentMethod === "other" && (
        <Alert
          title="特殊な支払い方法が指定されているため、口座情報は入力されていません"
          type="danger"
        />
      )}
      {payment.paymentMethod === "" && (
        <Alert title="支払情報が正しく登録されていません" type="danger" />
      )}
    </Content>
  );
};

export default Payment;
