import { useState, useEffect } from "react";
import { FormItemProps } from "../components/molecules/FormItemList";
import { Labels, getLabel } from "../utils/forms/scoutentry";

export function useScoutEntryForm(
  google: {
    name: string;
    id: string;
    email: string;
  },
  entry: Labels
): FormItemProps<Labels> {
  const [status, setStatus] = useState<FormItemProps<Labels>>([]);

  useEffect(() => {
    const formItem: FormItemProps<Labels> = [
      {
        formItemProps: {
          type: "text",
          placeholder: "クリエイター",
          name: "name",
          defaultValue: entry.name,
        },
        field: "input",
        label: getLabel("name"),
      },
      {
        formItemProps: {
          type: "text",
          placeholder: "山田",
          name: "lastName",
          defaultValue: entry.lastName,
        },
        field: "input",
        label: getLabel("lastName"),
      },
      {
        formItemProps: {
          type: "text",
          placeholder: "太郎",
          name: "firstName",
          defaultValue: entry.firstName,
        },
        field: "input",
        label: getLabel("firstName"),
      },
      {
        formItemProps: {
          type: "text",
          placeholder: "ヤマダ",
          name: "lastNameKana",
          defaultValue: entry.lastNameKana,
        },
        field: "input",
        label: getLabel("lastNameKana"),
      },
      {
        formItemProps: {
          type: "text",
          placeholder: "タロウ",
          name: "firstNameKana",
          defaultValue: entry.firstNameKana,
        },
        field: "input",
        label: getLabel("firstNameKana"),
      },
      {
        formItemProps: {
          type: "text",
          name: "birthdate",
          placeholder: "2000/01/01(「/」を含めて入力して下さい)",
          defaultValue: entry.birthdate,
        },
        field: "input",
        // field: "date",
        // fixme 本来はdateにしたいが未成年判定のためのwatchがdateに反応しないため暫定対応。。
        label: getLabel("birthdate"),
      },
      {
        formItemProps: {
          name: "gender",
          options: [
            { value: "男性", text: "男性" },
            { value: "女性", text: "女性" },
            { value: "その他", text: "その他" },
          ],
          defaultValue: entry.gender,
        },
        field: "select",
        label: getLabel("gender"),
      },
      {
        formItemProps: {
          type: "text",
          placeholder: "1076228",
          name: "postcode",
          defaultValue: entry.postcode,
        },
        field: "input",
        label: getLabel("postcode"),
      },
      {
        formItemProps: {
          type: "text",
          placeholder: "東京都港区赤坂9-7-1 ミッドタウン・タワー F28",
          name: "address",
          defaultValue: entry.address,
        },
        field: "input",
        label: getLabel("address"),
      },
    ];
    setStatus(formItem);
  }, [entry, google]);

  return status;
}
