import React from "react";
import styled from "styled-components";
import InputMask from "react-input-mask";
import { BasicForm } from "../../utils/forms/types";

// rulesについて、最初に渡されたものから順番に評価して、失敗した段階でエラーオブジェクトのtypeを更新する。
const Date: React.FC<BasicForm> = (props: BasicForm) => {
  return (
    <StyledWrapper margin={props.margin}>
      <InputMask
        inputMode="numeric"
        mask="9999/99/99"
        placeholder="2000/01/01"
        inputRef={props.register(props.rules)}
        name={props.name}
        maskChar=""
        defaultValue={props.defaultValue}
        aria-label="datetime-input"
        id={props.name}
      />
      {props.errors[props.name]?.message && (
        <ErrorMessage data-testid="errorMessage">
          {props.errors[props.name]?.message}
        </ErrorMessage>
      )}
    </StyledWrapper>
  );
};

const StyledWrapper = styled.div<Pick<BasicForm, "margin">>`
  margin: ${({ margin }): string | undefined => (margin ? margin : "auto")};
  width: 100%;

  input {
    padding-left: 1.6rem;
    width: 100%;
    height: 4.4rem;
    border: 1px solid #ddd;
    box-sizing: border-box;
    border-radius: 6px;
  }
`;

const ErrorMessage = styled.p`
  color: red;
  font-size: 1.2rem;
`;

export default Date;
