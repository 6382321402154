import { useState, useEffect } from "react";
import { FormItemProps } from "../components/molecules/FormItemList";
import { Labels, getLabel } from "../utils/forms/scoutentry";

export function useScoutEntryPaymentForm(entry: Labels): FormItemProps<Labels> {
  const [status, setStatus] = useState<FormItemProps<Labels>>([]);

  useEffect(() => {
    const formItem: FormItemProps<Labels> = [
      {
        formItemProps: {
          type: "text",
          placeholder: "全角",
          name: "bankName",
          defaultValue: entry.bankName,
        },
        field: "input",
        label: getLabel("bankName"),
      },
      {
        formItemProps: {
          type: "text",
          placeholder: "半角数字 4桁",
          name: "bankCode",
          maxlength: 4,
          minlength: 4,
          defaultValue: entry.bankCode,
        },
        field: "input",
        label: getLabel("bankCode"),
      },
      {
        formItemProps: {
          type: "text",
          placeholder: "全角",
          name: "branchName",
          defaultValue: entry.branchName,
        },
        field: "input",
        label: getLabel("branchName"),
      },
      {
        formItemProps: {
          type: "text",
          placeholder: "半角数字 3桁",
          name: "branchCode",
          maxlength: 3,
          minlength: 3,
          defaultValue: entry.branchCode,
        },
        field: "input",
        label: getLabel("branchCode"),
      },
      {
        formItemProps: {
          name: "accountType",
          options: [
            { value: "normal", text: "普通" },
            { value: "checking", text: "当座" },
            { value: "other", text: "その他" },
          ],
          defaultValue: entry.accountType,
        },
        field: "select",
        label: "口座種別",
      },
      {
        formItemProps: {
          type: "text",
          placeholder: "半角カナ 英数字 ※小文字不可",
          name: "accountName",
          defaultValue: entry.accountName,
        },
        field: "input",
        label: getLabel("accountName"),
      },
      {
        formItemProps: {
          type: "text",
          placeholder: "半角数字 7桁",
          name: "accountNo",
          maxlength: 7,
          minlength: 7,
          defaultValue: entry.accountNo,
        },
        field: "input",
        label: getLabel("accountNo"),
      },
    ];
    setStatus(formItem);
  }, [entry]);

  return status;
}
