import React from "react";
import styled from "styled-components";

import FormInput from "../atoms/FormInput";
import Date from "../atoms/Date";
import Select from "../atoms/Select";
import Textarea from "../atoms/Textarea";
import Label from "../atoms/Label";

import {
  RegisterOptions,
  FieldError,
  DeepMap,
  UseFormMethods,
  FieldValues,
} from "react-hook-form";
import { LabelTypes } from "../../utils/forms";

import {
  InputType,
  SelectType,
  TextareaType,
  DateType,
} from "../../utils/forms/types";

// ここら辺の型定義、別の場所でやりたい...
export type FormItemProp<T> =
  | InputType<T>
  | SelectType<T>
  | DateType<T>
  | TextareaType<T>;

export type FormItemProps<T> = Array<FormItemProp<T>>;

export interface Props<T> {
  formProps: FormItemProps<T>;
  rules(name: keyof T): RegisterOptions;
  errors: DeepMap<Record<string, any>, FieldError>;
  register: UseFormMethods<FieldValues>["register"];
  remarks?: { [key in keyof T]?: JSX.Element };
}

const FormItemList: <T extends LabelTypes>(props: Props<T>) => JSX.Element = <
  T extends LabelTypes
>(
  props: Props<T>
) => {
  return (
    <StyledFormGroup>
      {props.formProps.map((attr, idx) => {
        return (
          <StyledFormItem key={idx}>
            {attr.label && (
              <Label label={attr.label} name={attr.formItemProps.name} />
            )}
            {(attr.field === "input" && (
              <FormInput
                errors={props.errors}
                type={attr.formItemProps.type}
                rules={props.rules(attr.formItemProps.name)}
                defaultValue={attr.formItemProps.defaultValue}
                register={props.register}
                name={attr.formItemProps.name}
                placeholder={attr.formItemProps.placeholder}
                maxLength={attr.formItemProps.maxlength}
                minLength={attr.formItemProps.minlength}
                margin="0"
                disabled={attr.formItemProps.disabled}
                description={attr.formItemProps.description}
              />
            )) ||
              (attr.field === "select" && (
                <Select
                  errors={props.errors}
                  options={attr.formItemProps.options}
                  rules={props.rules(attr.formItemProps.name)}
                  defaultValue={
                    attr.formItemProps.defaultValue || "選択して下さい"
                  }
                  register={props.register}
                  name={attr.formItemProps.name}
                />
              )) ||
              (attr.field === "date" && (
                <Date
                  errors={props.errors}
                  margin="0"
                  rules={props.rules(attr.formItemProps.name)}
                  defaultValue={attr.formItemProps.defaultValue}
                  register={props.register}
                  name={attr.formItemProps.name}
                />
              )) ||
              (attr.field === "textarea" && (
                <Textarea
                  errors={props.errors}
                  rules={props.rules(attr.formItemProps.name)}
                  defaultValue={attr.formItemProps.defaultValue}
                  register={props.register}
                  name={attr.formItemProps.name}
                  placeholder={attr.formItemProps.placeholder}
                />
              ))}
            {props.remarks && props.remarks[attr.formItemProps.name]}
          </StyledFormItem>
        );
      })}
    </StyledFormGroup>
  );
};

const StyledFormGroup = styled.ul`
  list-style-type: none;
  padding-inline-start: 0;
  margin-top: 0;
  margin-bottom: 3.2rem;
  max-width: 60rem;
  width: 100%;
`;

const StyledFormItem = styled.li`
  &:not(:last-child) {
    margin-bottom: 2.4rem;
  }
`;

export default FormItemList;
