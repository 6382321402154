import React from "react";
import styled from "styled-components";
import ListItem from "../atoms/ListItem";

interface Props {
  items: ListItem[];
  additionalMarginBottom?: number;
}

export interface ListItem {
  icon?: string;
  text: string | number;
  to?: string;
}

const List: React.FC<Props> = (props: Props) => {
  if (props.items === []) return null;

  return (
    <StyledList mb={(props.additionalMarginBottom || 0) - 2.4}>
      {props.items.map((item, index) => (
        <StyledListItem key={index}>
          {item.icon && (
            <IconWrapper>
              <Icon src={item.icon} />
            </IconWrapper>
          )}
          {(item.to && (
            <StyledText onClick={() => window.open(item.to, "_blank")} href="#">
              {item.text}
              {item.icon && (
                <StyledExternalLink className="fas fa-external-link-alt"></StyledExternalLink>
              )}
            </StyledText>
          )) || <StyledText as="p"> {item.text}</StyledText>}
        </StyledListItem>
      ))}
    </StyledList>
  );
};

const StyledList = styled.ul<{ mb: number }>`
  padding: 0;
  list-style: none;
  margin-bottom: ${({ mb }): string => mb + "rem"};
`;

const StyledListItem = styled.li`
  padding: 1.6rem 0;
  padding-left: 2.4rem;
  margin: 0 -2.4rem;
  color: #000;
  text-decoration: none;
  display: flex;

  &:first-child {
    padding-top: 0.1rem;
  }

  &:not(:last-child) {
    border-bottom: 0.1rem solid #eee;
  }
`;

const IconWrapper = styled.div`
  width: 4.8rem;
  height: 4.8rem;
  border-radius: 50%;
  border: 1px solid #e0e0e0;
  position: relative;
  margin-right: 1.6rem;
`;

const Icon = styled.img`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 50%;
  height: 50%;
  margin: auto;
`;

const StyledText = styled.a`
  margin: auto;
  margin-left: 0;
`;

const StyledExternalLink = styled.i`
  margin-left: 0.6rem;
`;

export default List;
