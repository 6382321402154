import React, { useState } from "react";
import styled from "styled-components";

// import { EntryInfo } from "../../containers/YoutubeEntry";
import SubmitButton from "../atoms/SubmitButton";
import { ReactComponent as YoutubeLogo } from "../../assets/images/youtube.svg";

interface Props {
  backPreviousProgress: () => void;
  // data: EntryInfo;
  data: any;
  memberData?: {
    values: {
      members: Array<any>;
    };
    labels: any;
  };
  onSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
}

interface Item {
  subHeader: string;
}

// 削除予定NewEntryConfirmationに置き換えたい
const EntryConfirmation: React.FC<Props> = (props: Props) => {
  const onClickTermsLink = () =>
    window.open("https://www.uuum.co.jp/privacy_policy");
  const [isSubmitting, setIsSubmitting] = useState(false);

  return (
    <StyledConfirmationForm
      onSubmit={(e) => {
        e.preventDefault();
        setIsSubmitting(true);
        props.onSubmit(e);
        window.setTimeout(() => setIsSubmitting(false), 3000);
      }}
    >
      <StyledConfirmationGroup>
        {props.data.values.platform && (
          <div>
            <YoutubeStyledConfirmationHeader>
              チャンネル情報
            </YoutubeStyledConfirmationHeader>
            <ChannelInfo>
              <LogoWrapper>
                <YoutubeLogo />
              </LogoWrapper>
              <ChannelText subHeader={props.data.values.platform.id}>
                {props.data.values.platform.name}
              </ChannelText>
            </ChannelInfo>
          </div>
        )}
        {
          <div>
            <StyledConfirmationHeader>個人情報</StyledConfirmationHeader>
            <StyledConfirmationList>
              {(
                Object.keys(props.data.values.entry) as Array<
                  keyof typeof props.data.values.entry
                >
              ).map((key: keyof typeof props.data.values.entry) => {
                return (
                  <StyledConfirmationListItem key={Number(key)}>
                    <StyledConfirmationListItemText
                      subHeader={props.data.labels[key]}
                    >
                      {props.data.values.entry[key]}
                    </StyledConfirmationListItemText>
                  </StyledConfirmationListItem>
                );
              })}
            </StyledConfirmationList>
          </div>
        }
        {props.memberData &&
          props.memberData?.values?.members.length !== 0 &&
          props.memberData.values.members.map((member, key) => {
            return (
              <div key={Number(key)}>
                <StyledConfirmationHeader>
                  メンバー{key + 1}人目の個人情報
                </StyledConfirmationHeader>
                <StyledConfirmationList>
                  {(Object.entries(member) as string[][]).map((item, key2) => {
                    return (
                      <StyledConfirmationListItem key={key2}>
                        <StyledConfirmationListItemText
                          subHeader={props.memberData?.labels[item[0]]}
                        >
                          {item[1] || ""}
                        </StyledConfirmationListItemText>
                      </StyledConfirmationListItem>
                    );
                  })}
                </StyledConfirmationList>
              </div>
            );
          })}
      </StyledConfirmationGroup>
      <StyledCheckBox>
        <input type="checkbox" value="1" id="confirmation" required />
        <TermsLink onClick={onClickTermsLink}>プライバシーポリシー</TermsLink>
        <label htmlFor="confirmation">に同意する</label>
      </StyledCheckBox>

      <EditEntry onClick={props.backPreviousProgress}>情報を編集</EditEntry>

      <SubmitButton
        name="commit"
        value="送信"
        backgroundColor="#0074AE"
        dataDisableValue="送信"
        disabled={isSubmitting}
      />
    </StyledConfirmationForm>
  );
};

const StyledConfirmationForm = styled.form`
  display: flex;
  width: 100%;
  max-width: 60rem;
  min-width: 28.8rem;
  align-items: center;
  flex-direction: column;
  margin: 0 auto;

  @media (max-width: 896px) {
    margin-top: 4.8rem;
  }
`;

const StyledConfirmationGroup = styled.div`
  list-style-type: none;
  max-width: 60rem;
  width: 100%;
  margin-bottom: 3.2rem;
`;

const StyledCheckBox = styled.div`
  text-align: center;
  margin-bottom: 3.2rem;
`;

const StyledConfirmationHeader = styled.h3`
  max-width: 20.2rem;
  width: 100%;
  height: 1.9rem;
  font-style: normal;
  font-weight: bold;
  font-size: 1.6rem;
  line-height: 1.9rem;
  margin-bottom: 0.4rem;
`;

const YoutubeStyledConfirmationHeader = styled(StyledConfirmationHeader)`
  margin-bottom: 1.6rem;
`;

const StyledConfirmationList = styled.ul`
  list-style-type: none;
  padding-inline-start: 0;
`;

const StyledConfirmationListItem = styled.li`
  position: relative;
  padding-top: 3.6rem;
  border-bottom: 1px solid #ddd;
`;

const StyledConfirmationListItemText = styled.p<Pick<Item, "subHeader">>`
  font-style: normal;
  font-weight: normal;
  font-size: 1.6rem;
  line-height: 1.9rem;
  margin-bottom: 1.1rem;
  color: #111;

  &::before {
    display: block;
    position: absolute;
    content: "${({ subHeader }): string => (subHeader ? subHeader : "")}";
    top: 1.2rem;
    font-style: normal;
    font-weight: normal;
    font-size: 1.4rem;
    line-height: 1.6rem;
    color: #676767;
  }
`;

const EditEntry = styled.span`
  text-align: center;
  font-style: normal;
  font-weight: bold;
  font-size: 1.6rem;
  line-height: 2.3rem;
  color: #0074ae;
  margin-bottom: 2.4rem;

  &:hover {
    filter: opacity(0.8);
    text-decoration: none;
  }
`;

const ChannelInfo = styled.div`
  display: flex;
  position: relative;
  margin-bottom: 3.2rem;
`;

const LogoWrapper = styled.div`
  width: 4.8rem;
  height: 4.8rem;
  border-radius: 50%;
  line-height: 4.8rem;
  margin-right: 1.2rem;
`;

const ChannelText = styled.p<Pick<Item, "subHeader">>`
  padding-top: 0.8rem;
  font-style: normal;
  font-weight: bold;
  font-size: 1.6rem;
  line-height: 1.9rem;
  color: #111;

  &::after {
    display: block;
    position: absolute;
    content: "${({ subHeader }): string => (subHeader ? subHeader : "")}";
    padding-top: 0.2rem;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: #676767;
  }
`;

const TermsLink = styled.span`
  color: #0074ae;

  &:hover {
    filter: opacity(0.8);
    text-decoration: none;
  }
`;

export default EntryConfirmation;
