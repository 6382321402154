import { StringKeyRegisterOptionsObject } from "./index";
import { stringToCamel } from "../toCamel";

export interface Labels {
  accountType: "normal" | "checking" | "other";
  accountName: string;
  bankName: string;
  bankCode: string;
  branchName: string;
  branchCode: string;
  accountNo: string;
  businessInvoiceNumber: string;
}

// 追加したい物をkey_valueで追加する。
const validations: StringKeyRegisterOptionsObject = {
  accountType: {
    pattern: {
      value: /normal|checking|other/,
      message: "正しいものを選択して下さい",
    },
  },
  accountName: {
    maxLength: {
      value: 40,
      message: "長すぎます",
    },
    validate: {
      hankakuKomojiCheck: (value: string) =>
        !value.match(/[ｧｨｩｪｫｯｬｭｮ]+/) || "半角小文字は使用できません",
      halfWidthSpaceCheck: (value: string) =>
        value.trim().length > 0 || "半角スペースのみで登録できません",
      pattern: (value: string) =>
        value.match(
          /^[0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZｱｲｳｴｵｶｷｸｹｺｻｼｽｾｿﾀﾁﾂﾃﾄﾅﾆﾇﾈﾉﾊﾋﾌﾍﾎﾏﾐﾑﾒﾓﾔﾕﾖﾗﾘﾙﾚﾛﾜｦﾝｰﾞﾟ.｢｣()\-/\s]+$/
        ) || "半角ｶﾅ・半角数値・半角英字・一部記号のみ入力可能です",
    },
  },

  bankName: {
    maxLength: {
      value: 191,
      message: "長すぎます",
    },
  },
  bankCode: {
    minLength: {
      value: 4,
      message: "４桁で入力してください",
    },
    maxLength: {
      value: 4,
      message: "４桁で入力してください",
    },
    pattern: {
      value: /^[0-9]+$/,
      message: "正しい形式で入力してください",
    },
  },
  branchName: {
    maxLength: {
      value: 191,
      message: "長すぎます",
    },
  },
  branchCode: {
    minLength: {
      value: 3,
      message: "3桁で入力してください",
    },
    maxLength: {
      value: 3,
      message: "3桁で入力してください",
    },
    pattern: {
      value: /^[0-9]+$/,
      message: "正しい形式で入力してください",
    },
  },
  accountNo: {
    minLength: {
      value: 7,
      message: "7桁で入力してください",
    },
    maxLength: {
      value: 7,
      message: "7桁で入力してください",
    },
    pattern: {
      value: /^[0-9]+$/,
      message: "正しい形式で入力してください",
    },
  },
  businessInvoiceNumber: {
    required: false,
    minLength: {
      value: 13,
      message: "13桁で入力してください",
    },
    maxLength: {
      value: 13,
      message: "13桁で入力してください",
    },
    pattern: {
      value: /^[0-9]+$/,
      message: "正しい形式で入力してください",
    },
  },
};

const formRules = (attribute: string) => {
  const validation = validations[stringToCamel(attribute)] || {};

  return { required: "入力してください", ...validation };
};

export { formRules };
