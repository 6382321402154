import React from "react";
import styled from "styled-components";
import {
  RegisterOptions,
  UseFormMethods,
  FieldError,
  DeepMap,
} from "react-hook-form";

interface BasicForm {
  name: string;
  errors: DeepMap<Record<string, any>, FieldError>;
  register: UseFormMethods["register"];
  rules: RegisterOptions;
  margin?: string;
  defaultValue?: number | string;
}

export interface TextareaValue extends BasicForm {
  placeholder: string;
}

// rulesについて、最初に渡されたものから順番に評価して、失敗した段階でエラーオブジェクトのtypeを更新する。
const Textarea: React.FC<TextareaValue> = (props: TextareaValue) => {
  return (
    <StyledWrapper margin={props.margin}>
      <StyledTextarea
        name={props.name}
        placeholder={props.placeholder}
        ref={props.register(props.rules)}
        defaultValue={props.defaultValue}
        id={props.name}
      />
      {props.errors[props.name]?.message && (
        <ErrorMessage data-testid="errorMessage">
          {props.errors[props.name]?.message}
        </ErrorMessage>
      )}
    </StyledWrapper>
  );
};

const StyledWrapper = styled.div<Pick<TextareaValue, "margin">>`
  margin: ${({ margin }): string | number => (margin ? margin : "auto")};
  width: 100%;
`;

const StyledTextarea = styled.textarea`
  width: 100%;
  padding: 0 1.6rem;
  line-height: 4rem;
  font-size: 1.6rem;
  border: 0.1rem solid #eee;
  border-radius: 0.6rem;

  &:hover {
    filter: opacity(0.8);
  }
`;

const ErrorMessage = styled.p`
  color: red;
  font-size: 1.2rem;
`;

export default Textarea;
