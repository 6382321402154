import React from "react";
import styled from "styled-components";

interface Props {
  children: React.ReactNode;
  width?: string;
  title?: string;
}

const Contents: React.FC<Props> = (props: Props) => {
  return (
    <StyledContent width={props.width || "100%"}>
      {props.title && <Title>{props.title}</Title>}
      {props.children}
    </StyledContent>
  );
};

const StyledContent = styled.main<{ width: string }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  width: 100%;
  max-width: ${({ width }): string => width};
  height: calc(100vh - 11.2rem);
  margin: auto;

  @media (max-width: 896px) {
    height: calc(100vh - 6.8rem);
    padding: 1.6rem;
  }
`;

const Title = styled.h1`
  margin-bottom: 2.4rem;
  line-height: 1;
  font-size: 2.4rem;
  font-weight: bold;

  @media (max-width: 896px) {
    font-size: 1.8rem;
  }
`;

export default Contents;
