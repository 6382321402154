import React from "react";
import { useTerms } from "../hooks/Terms";
import TermsCommonForm from "./terms/TermsCommonForm";

const Terms: React.FC = () => {
  const mypageTermsPram = { category: "mypage" };
  const { terms } = useTerms(mypageTermsPram);

  const mypageObj = {
    title: "利用規約",
    content: terms.content,
  };

  return <TermsCommonForm obj={mypageObj} />;
};

export default Terms;
