import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

interface Props {
  title: string;
  to: string;
}

const Breadcrumb: React.FC<Props> = (props: Props) => {
  return (
    <StyledBreadcrumb>
      <StyledBreadcrumbItem to={props.to}>{props.title}</StyledBreadcrumbItem>
    </StyledBreadcrumb>
  );
};

const StyledBreadcrumb = styled.div`
  display: flex;
  padding: 0 4.8rem;
  border-bottom: 1px solid #e0e0e0;

  @media (max-width: 896px) {
    padding: 0 1.6rem;
  }
`;

const StyledBreadcrumbItem = styled(Link)`
  position: relative;
  display: flex;
  align-items: center;
  min-height: 6.4rem;
  margin: 0;
  padding: 0 0 0 2rem;
  font-size: 1.6rem;
  font-weight: bold;
  line-height: 1;
  color: #3e94be;
  text-decoration: none;

  @media (max-width: 896px) {
    min-height: 5.6rem;
  }

  &::before {
    content: "";
    display: block;
    position: absolute;
    top: 50%;
    left: 0.4rem;
    width: 1.2rem;
    height: 1.2rem;
    padding: 0;
    border-top: 0.2rem solid #3e94be;
    border-right: 0.2rem solid #3e94be;
    pointer-events: none;
    transition: transform 0.2s ease-in-out;
    transform: translateY(-50%) rotate(225deg);
  }
`;

export default Breadcrumb;
