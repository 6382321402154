import React from "react";
import { Route } from "react-router-dom";

function PublicRoute({ component: Component, ...rest }: any): JSX.Element {
  return (
    <Route
      {...rest}
      render={(props): JSX.Element => <Component {...rest} {...props} />}
    />
  );
}

export default PublicRoute;
