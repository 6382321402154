import React from "react";
import styled from "styled-components";

interface Props {
  children: React.ReactNode;
  width?: string;
  height?: string;
}

const defaultProps = {
  width: "100%",
  height: "100%",
};

const Icon: React.FC<Props> = (props: Props) => {
  return (
    <StyledIcon width={props.width} height={props.height} data-testid="icon">
      {props.children}
    </StyledIcon>
  );
};

Icon.defaultProps = defaultProps;

const StyledIcon = styled.div<Pick<Props, "width" | "height">>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${({ width }): string => width || "100%"};
  height: ${({ height }): string => height || "100%"};
  margin: auto;
  border-radius: 50%;
  background: #f3f3f3;
  overflow: hidden;

  > img {
    width: 100%;
  }

  > svg {
    font-size: ${({ width }): string | undefined => width};
    color: #fff;
  }
`;

export default Icon;
