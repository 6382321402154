import React from "react";
import styled from "styled-components";

interface Props {
  onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  mobileMenuOpen: boolean;
}

const DrawerButton: React.FC<Props> = (props: Props) => {
  return (
    <StyledDrawerButton
      onClick={props.onClick}
      mobileMenuOpen={props.mobileMenuOpen}
      data-testid="drawer-button"
    />
  );
};

const StyledDrawerButton = styled.button<{ mobileMenuOpen: boolean }>`
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 6.4rem;
  height: 6.4rem;
  padding: 2rem;
  transition: all 0.2s ease-in-out;
  border: none;
  background-color: transparent;

  &:focus {
    outline: none;
  }

  &::before,
  &::after {
    content: "";
    display: block;
    width: 100%;
    height: 0.2rem;
    transition: all 0.2s ease-in-out;
    background-color: rgb(151, 151, 151);
    transform: translateY(0) translateX(0) rotate(0);
  }

  &::after {
    margin-top: 0.8rem;
  }

  @media (max-width: 896px) {
    display: block;
    width: 5.6rem;
    height: 5.6rem;
    padding: 1.8rem;
  }

  ${(props): string =>
    props.mobileMenuOpen
      ? `
      &::before {
        transform: translateY(0.5rem) rotate(45deg);
      }

      &::after {
        transform: translateY(-0.5rem) rotate(-45deg);
      }
      `
      : ``}
`;

export default DrawerButton;
