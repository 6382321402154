import { RegisterOptions } from "react-hook-form";
import { FormItemProp } from "../../components/molecules/FormItemList";

type LabelKeys = keyof Labels;

type LabelKeyRegisterOptions = {
  [key in LabelKeys]: RegisterOptions;
};

interface SelectAttributes {
  field: "select";
  options: Array<{ value: string; text: string }>;
}

interface InputAttributes {
  field: "input";
  type: "text" | "password";
}

interface TextareaAttributes {
  field: "textarea";
}

interface DateAttributes {
  field: "date";
}

type FormAttributes = {
  [key in LabelKeys]:
    | InputAttributes
    | SelectAttributes
    | DateAttributes
    | TextareaAttributes;
};

export const formAttributes: FormAttributes = {
  name: {
    field: "input",
    type: "text",
  },
  firstName: {
    field: "input",
    type: "text",
  },
  lastName: {
    field: "input",
    type: "text",
  },
  firstNameKana: {
    field: "input",
    type: "text",
  },
  lastNameKana: {
    field: "input",
    type: "text",
  },
  email: {
    field: "input",
    type: "text",
  },
  address: {
    field: "input",
    type: "text",
  },
  postcode: {
    field: "input",
    type: "text",
  },
  birthdate: {
    field: "date",
  },
  parentFirstName: {
    field: "input",
    type: "text",
  },
  parentLastName: {
    field: "input",
    type: "text",
  },
  parentRelationship: {
    field: "select",
    options: [
      { value: "mother", text: "母" },
      { value: "father", text: "父" },
      { value: "other", text: "その他" },
    ],
  },
  gender: {
    field: "select",
    options: [
      { value: "男性", text: "男性" },
      { value: "女性", text: "女性" },
      { value: "その他", text: "その他" },
    ],
  },
  emergencyPhoneNumber: {
    field: "input",
    type: "text",
  },
  phoneNumber: {
    field: "input",
    type: "text",
  },
  parentPhoneNumber: {
    field: "input",
    type: "text",
  },
};

export interface Labels {
  name: string;
  firstName: string;
  lastName: string;
  firstNameKana: string;
  lastNameKana: string;
  email: string;
  address: string;
  postcode: string;
  birthdate: string;
  parentFirstName: string;
  parentLastName: string;
  parentRelationship: "mother" | "father" | "other";
  gender: string;
  phoneNumber: string;
  emergencyPhoneNumber: string;
  parentPhoneNumber: string;
}

const labels: { [key in keyof Labels]: string } = {
  name: "クリエイター名",
  firstName: "お名前 (名)",
  lastName: "お名前 (姓)",
  firstNameKana: "フリガナ (名)",
  lastNameKana: "フリガナ (姓)",
  email: "連絡用メールアドレス",
  address: "住所",
  postcode: "郵便番号",
  birthdate: "生年月日",
  parentFirstName: "保護者(名)",
  parentLastName: "保護者(姓)",
  parentRelationship: "保護者の続柄",
  gender: "性別",
  phoneNumber: "電話番号",
  emergencyPhoneNumber: "緊急連絡先",
  parentPhoneNumber: "保護者連絡先",
};

// 追加したい物をkey_valueで追加する。
const validations: LabelKeyRegisterOptions = {
  name: {
    maxLength: {
      value: 191,
      message: "長すぎます",
    },
  },
  firstName: {
    maxLength: {
      value: 191,
      message: "長すぎます",
    },
  },
  lastName: {
    maxLength: {
      value: 191,
      message: "長すぎます",
    },
  },
  firstNameKana: {
    maxLength: {
      value: 191,
      message: "長すぎます",
    },
    pattern: {
      value: /^[\u30a0-\u30ff　 ー－・]*$/,
      message: "正しい形式で入力してください",
    },
  },
  lastNameKana: {
    maxLength: {
      value: 191,
      message: "長すぎます",
    },
    pattern: {
      value: /^[\u30a0-\u30ff　 ー－・]*$/,
      message: "正しい形式で入力してください",
    },
  },
  email: {
    pattern: {
      value: /^[\w+\-.]+@[a-z\d\-.]+\.[a-z]+$/,
      message: "正しい形式で入力してください",
    },
  },
  address: {
    maxLength: {
      value: 191,
      message: "入力してください",
    },
  },
  postcode: {
    pattern: {
      value: /^[A-Z0-9\s-]+$/,
      message: "正しい形式で入力してください",
    },
  },
  birthdate: {
    pattern: /\d{4}\/\d{2}\/\d{1,2}/,
    validate: (value: string) =>
      new Date(value).toString() !== "Invalid Date" ||
      "正確な日付を入力して下さい",
  },
  parentFirstName: {
    maxLength: 191,
  },
  parentLastName: {
    maxLength: 191,
  },
  parentRelationship: {
    pattern: {
      value: /mother|father|other/,
      message: "選択して下さい",
    },
  },
  gender: {
    pattern: {
      value: /男|女|その他/,
      message: "選択して下さい",
    },
  },
  phoneNumber: {
    pattern: {
      value: /^\+?[0-9]+$/,
      message: "半角数字のみで入力してください",
    },
  },
  parentPhoneNumber: {
    pattern: {
      value: /^\+?[0-9]+$/,
      message: "半角数字のみで入力してください",
    },
  },
  emergencyPhoneNumber: {
    pattern: {
      value: /^\+?[0-9]+$/,
      message: "半角数字のみで入力してください",
    },
  },
};

const formRules = (name: keyof Labels) => {
  const validation = validations[name];

  return { required: "入力してください", ...validation };
};

// この型を制限する
const getLabel = (name: keyof Labels): string => {
  return labels[name];
};

export const genFormItemProp = (
  label: LabelKeys,
  defaultValue: string | number
): FormItemProp<Labels> => {
  const formAtt = formAttributes[label];
  const base = {
    placeholder: labels[label],
    name: label,
    defaultValue,
  };

  if (formAtt.field === "input") {
    const type = formAtt.type;
    const obj: FormItemProp<Labels> = {
      formItemProps: {
        ...base,
        type,
      },
      field: "input",
    };

    return obj;
  } else if (formAtt.field === "select") {
    const options = formAtt.options;
    const obj: FormItemProp<Labels> = {
      formItemProps: {
        ...base,
        options,
      },
      field: "select",
    };
    return obj;
  }

  return {
    formItemProps: base,
    field: formAtt.field,
  };
};

export { formRules, getLabel, labels };
