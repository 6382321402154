import { toast } from "react-toastify";

toast.configure({
  autoClose: 2000,
  draggable: false,
  position: "top-right",
});

const errorId = 0;
const successId = 1;

export const toastSuccess = (message: string) => {
  if (toast.isActive(successId)) return;

  toast.success(message, {
    toastId: successId,
  });
};

export const toastError = (message: string) => {
  if (toast.isActive(errorId)) return;

  toast.error(message, {
    toastId: errorId,
  });
};
