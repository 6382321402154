import React from "react";
import styled from "styled-components";

interface Props {
  title: string;
  detail?: string | number;
  as?: any;
  onClick?: (e: React.FormEvent<HTMLInputElement>) => void;
  isActive: boolean;
  titleTestId?: string;
  detailTestId?: string;
}

const defaultProps = {
  as: "div",
  isActive: false,
};

const ToggleItem: React.FC<Props> = (props: Props) => {
  return (
    <StyledToggleItem
      as={props.as}
      onClick={props.onClick}
      isActive={props.isActive}
      data-testid="toggleItem"
    >
      <StyledTitle data-testid={props.titleTestId}>{props.title}</StyledTitle>
      <StyledDetail data-testid={props.detailTestId}>
        {props.detail}
      </StyledDetail>
    </StyledToggleItem>
  );
};

ToggleItem.defaultProps = defaultProps;

const StyledToggleItem = styled.div<Pick<Props, "isActive">>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 6rem;
  padding: 0 0 0 4rem;
  color: #000;
  border-bottom: 0.1rem solid #eee;
  text-decoration: none;
  transition: all 0.2s ease-in-out;

  &::before {
    content: "";
    display: block;
    position: absolute;
    top: 50%;
    left: 0.4rem;
    width: 1.2rem;
    height: 1.2rem;
    padding: 0;
    border-top: 0.2rem solid #676767;
    border-right: 0.2rem solid #676767;
    pointer-events: none;
    transition: transform 0.2s ease-in-out;

    ${(props): string =>
      props.isActive
        ? `transform: translateY(-50%) rotate(135deg);`
        : `transform: translateY(-50%) rotate(45deg)`}
  }

  &:hover {
    color: #3e94be;

    &::before {
      border-color: #3e94be;
    }
  }

  @media (max-width: 896px) {
    height: 4.6rem;
  }
`;

const StyledTitle = styled.div`
  font-size: 1.8rem;
  font-weight: bold;

  @media (max-width: 896px) {
    font-size: 1.4rem;
  }
`;

const StyledDetail = styled.div`
  font-size: 1.8rem;
  font-weight: bold;
  text-align: right;

  @media (max-width: 896px) {
    font-size: 1.4rem;
  }
`;

export default ToggleItem;
