import React from "react";
import styled from "styled-components";

interface Props {
  children: React.ReactNode;
}

const Modal: React.FC<Props> = (props: Props) => {
  return <StyledModal>{props.children}</StyledModal>;
};

const StyledModal = styled.div`
  position: fixed;
  z-index: 100;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default Modal;
