import React from "react";
import styled from "styled-components";
import sanitizeHTML from "sanitize-html";

interface Props {
  title: string;
  message?: string;
  type?: string;
}

const Alert: React.FC<Props> = (props: Props) => {
  const __html = sanitizeHTML(props.title, {
    allowedAttributes: { a: ["href", "target", "noopener"] },
  });
  return (
    <StyledAlert type={props.type}>
      <StyledTitle dangerouslySetInnerHTML={{ __html }}></StyledTitle>
      {props.message && <StyledMessage>{props.message}</StyledMessage>}
    </StyledAlert>
  );
};

const StyledAlert = styled.div<{ type?: string }>`
  display: block;
  margin-bottom: 3.2rem;
  padding: 1.6rem;
  border-radius: 0.4rem;

  @media (max-width: 896px) {
    margin: 1.6rem 1.6rem 2.4rem;
  }

  ${(props): string =>
    props.type === "danger"
      ? `
        background-color: #f27f7f;
        color: #fff;
        `
      : `
        background-color: #f8f8f8;
        `}
  ${(props): string =>
    props.type === "info"
      ? `
        background-color: #ffffe0;
        color: #000042;
        `
      : ``}
  ${(props): string =>
    props.type === "lmnd"
      ? `
        background-color: #ffffe0;
        color: #000042;

        width: 57rem;
        display: flex;
        transform: translateX(-20%);
        white-space: pre-line;

        @media (max-width: 896px) {
          width: 100%;
          transform: translateX(-5%);
        }
        `
      : ``}
`;

const StyledTitle = styled.h4`
  margin: 0;
  font-size: 1.6rem;
  font-weight: bold;
  line-height: 1;

  > i {
    margin-right: 0.8rem;
  }
`;

const StyledMessage = styled.p`
  margin: 0.8rem 0 0;
  font-size: 1.6rem;
  line-height: 1.6rem;
`;

export default Alert;
