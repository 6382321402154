import React from "react";
import styled from "styled-components";

import Content from "../components/templates/Content";
import InfoBox from "../components/molecules/InfoBox";
import Toggle from "../components/molecules/Toggle";
import List from "../components/molecules/List";
import DownloadNav from "../components/molecules/DownloadNav";
import Title from "../components/atoms/Title";

import { Redirect, useParams } from "react-router-dom";
import { initialResponses } from "../utils/ajax";
import { createAxiosHook } from "../utils/createAxiosHook";
import { creator, types } from "../store";
import { useSelector } from "react-redux";

const createMonthlyRevenuesHookBase = (
  year?: string,
  month?: string
): Function => {
  return createAxiosHook(
    "get",
    `/mypage/api/v1/creator/revenues/${year}/${month}`
  );
};

interface ListItem {
  title: string;
  detail: string;
}

interface Revenue {
  details: string;
  rewardAmount: string;
  paymentMonth: string;
}

const MonthlyRevenues: React.FC = (): JSX.Element => {
  const creatorState: creator.types.State = useSelector<
    types.RootState,
    creator.types.State
  >((state) => state.creator);

  const isAvailableRevenue = creatorState.isRevenueViewed;

  const { year, month } = useParams<{ year: string; month: string }>();

  const useMonthlyRevenues = createMonthlyRevenuesHookBase(year, month);
  const { data: RevenueDetail } = useMonthlyRevenues(
    initialResponses.revenues.detail,
    {
      year,
      month,
    }
  );

  if (!isAvailableRevenue) return <Redirect to="/404" />;

  const carryOveredProperties: ListItem[] = [
    {
      title: "累計残高",
      detail: `${RevenueDetail.carryOveredRewardAmount.toLocaleString()}円`,
    },
  ];

  const prevPath = (): string => {
    return Number(month) === 1
      ? `/creator/revenues/${Number(year) - 1}/12`
      : `/creator/revenues/${Number(year)}/${Number(month) - 1}`;
  };

  const nextPath = (): string => {
    // 当月の場合はnullを返す
    const d = new Date();
    if (Number(year) === d.getFullYear() && Number(month) >= d.getMonth() + 1)
      return "";

    return Number(month) === 12
      ? `/creator/revenues/${Number(year) + 1}/1`
      : `/creator/revenues/${Number(year)}/${Number(month) + 1}`;
  };

  const target = `${year}年${month}月(${RevenueDetail.status})`;

  const isPaymentTarget = !(
    RevenueDetail.isGroupPayment && RevenueDetail.isGroupPaymentParent
  );

  const downloadText = (): string => {
    return isPaymentTarget
      ? "※支払証明書のダウンロードは、支払額確定後、翌月よりダウンロード可能になります。"
      : "分割支払い対象のため、グループでの支払証明書のダウンロードはできません。";
  };

  const downloadButtonEnable = !!(
    year &&
    month &&
    isPaymentTarget &&
    RevenueDetail.status === "確定" &&
    RevenueDetail.paymentAmount > 0
  );

  const downloadUrl = (): string => {
    return `${process.env.REACT_APP_CODA_API_SERVER_URL}/mypage/api/v1/creator/revenues/${year}/${month}/download.csv`;
  };

  const thisMonthRewardAmountDetailsArray = RevenueDetail.thisMonthRevenues.map(
    (revenue: Revenue) => ({
      title: `${year}年${month}月 ${revenue.details}`,
      detail: `${revenue.rewardAmount.toLocaleString()}円`,
    })
  );

  const lastMonthCarryOveredRevenuesArray =
    RevenueDetail.lastMonthCarryOveredRevenues.map((revenue: Revenue) => ({
      title: `${revenue.paymentMonth.match(
        /\d{4}/
      )}年${revenue.paymentMonth.match(/\d{2}$/)}月の繰越分 ${revenue.details}`,
      detail: `${revenue.rewardAmount.toLocaleString()}円`,
    }));

  const checkOthersArray = (): ListItem[] => {
    const arr = [];
    if (RevenueDetail.withholdingTaxAmount > 0) {
      arr.push({
        title: "源泉徴収額",
        detail: `${(
          0 - RevenueDetail.withholdingTaxAmount
        ).toLocaleString()}円`,
      });
    }
    if (RevenueDetail.commissionAmount > 0) {
      arr.push({
        title: "手数料",
        detail: `${(0 - RevenueDetail.commissionAmount).toLocaleString()}円`,
      });
    }
    return arr;
  };

  return (
    <Content title={`${year}年${month}月 支払明細`}>
      <InfoBox text={target} prevPath={prevPath()} nextPath={nextPath()}>
        <div data-testid="paymentAmount">
          {RevenueDetail.paymentAmount.toLocaleString() || 0}
        </div>
        <small>円</small>
      </InfoBox>

      <StyledSection>
        <Toggle
          titleTestId="revenueAmountTitle"
          detailTestId="revenueAmountDetail"
          title="報酬金額"
          detail={`${
            RevenueDetail.thisMonthRewardAmount.toLocaleString() || 0
          }円`}
          items={thisMonthRewardAmountDetailsArray || []}
        />
        {/* グループクリエイターの親の場合、paymentAmountが0になるが繰り越しの条件などは表示したい。
        なので実際に支払われた報酬ではなく、そのグループ全体の報酬が反映されるrewardAmountを使って判定する */}
        {RevenueDetail.rewardAmount > 0 &&
          RevenueDetail.lastMonthCarryOveredRewardAmount > 0 && (
            <Toggle
              title="繰越分"
              titleTestId="lastMonthCarryOveredRewardAmountTitle"
              detailTestId="lastMonthCarryOveredRewardAmountDetail"
              detail={`${
                RevenueDetail.lastMonthCarryOveredRewardAmount.toLocaleString() ||
                0
              }円`}
              items={lastMonthCarryOveredRevenuesArray || []}
            />
          )}
        {RevenueDetail.rewardAmount > 0 && (
          <Toggle
            title="その他"
            titleTestId="otherAmountTitle"
            detailTestId="otherAmountDetail"
            detail={`${(
              0 -
              RevenueDetail.withholdingTaxAmount -
              RevenueDetail.commissionAmount
            ).toLocaleString()}円`}
            items={checkOthersArray() || []}
          />
        )}
      </StyledSection>

      {RevenueDetail.carryOveredRewardAmount > 0 && (
        <StyledSection>
          <Title as="h2">繰越金額合計</Title>
          <List items={carryOveredProperties} />
        </StyledSection>
      )}

      <StyledSection>
        <DownloadNav
          title="支払証明書"
          text={downloadText()}
          disabled={!downloadButtonEnable}
          url={downloadUrl()}
        />
      </StyledSection>
    </Content>
  );
};

const StyledSection = styled.section`
  margin-bottom: 4.8rem;
`;

export default MonthlyRevenues;
