import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import ListItem from "../atoms/ListItem";

interface Props {
  items: ListItem[];
  width?: string;
  margin?: string;
}

interface ListItem {
  title: string;
  detail?: string | number;
  to?: any;
}

const List: React.FC<Props> = (props: Props) => {
  if (props.items === []) return <div data-testid="listEmpty" />;

  return (
    <StyledList
      width={props.width || "100%"}
      margin={props.margin || "0"}
      data-testid="listStyledList"
    >
      {props.items.map((item, index) => (
        <ListItem
          key={index}
          title={item.title}
          detail={item.detail}
          as={item.to ? Link : "li"}
          to={item.to}
        />
      ))}
    </StyledList>
  );
};

const StyledList = styled.ul<{ width: string; margin: string }>`
  width: ${({ width }): string => width};
  margin: ${({ margin }): string => margin};
  padding: 0;
  list-style: none;
`;

export default List;
