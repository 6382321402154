import { Dispatch } from "redux";

import { ActionTypes, Action } from "./types";

import { creatorsApi } from "../../utils/ajax";
import { Creator } from "@uuum/coda-mypage-api-sdk";

const basicActions = {
  [ActionTypes.FETCH_REQUEST]: (): Action => ({
    type: ActionTypes.FETCH_REQUEST,
    payload: undefined,
  }),
  [ActionTypes.FETCH_SUCCESS]: (basicInfo: Creator): Action => ({
    type: ActionTypes.FETCH_SUCCESS,
    payload: basicInfo,
  }),
  [ActionTypes.FETCH_ERROR]: (): Action => ({
    type: ActionTypes.FETCH_ERROR,
    payload: undefined,
  }),
};

const sideEffectActions = {
  [ActionTypes.FETCH_INFO]:
    () =>
    async (dispatch: Dispatch): Promise<Action> => {
      await dispatch(basicActions[ActionTypes.FETCH_REQUEST]());
      try {
        const response = await creatorsApi.getCreators();
        return dispatch(basicActions[ActionTypes.FETCH_SUCCESS](response));
      } catch {
        return dispatch(basicActions[ActionTypes.FETCH_ERROR]());
      }
    },
};

export const actions = { ...basicActions, ...sideEffectActions };
