import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";

import Icon from "../atoms/Icon";
import Title from "../atoms/Title";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import { creator, types } from "../../store";

const UserProfile: React.FC = (): JSX.Element => {
  const creatorState: creator.types.State = useSelector<
    types.RootState,
    creator.types.State
  >((state) => state.creator);

  const image = (): JSX.Element => {
    return (
      (creatorState.imageUrl && creatorState.imageUrl.length > 0 && (
        <img
          alt={creatorState.name}
          src={creatorState.imageUrl}
          data-testid="userProfileImage"
        />
      )) || <AccountCircleIcon />
    );
  };

  return (
    <Section>
      <Icon width="12rem" height="12rem">
        {image()}
      </Icon>
      <Title as="h2" margin="1.8rem 0 0">
        {creatorState.name}
      </Title>
    </Section>
  );
};

const Section = styled.section`
  width: 100%;
  padding: 7.2rem 1.6rem;
  text-align: center;

  @media (max-width: 896px) {
    padding: 3.6rem 1.6rem;
  }
`;

export default UserProfile;
