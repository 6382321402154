import { Revenues } from "../types";

export const basic: Revenues.Basic = {
  amount: 0,
  month: "",
};

export const monthlyOverview: Revenues.MonthlyOverview = {
  details: "",
  isWithholdingTax: false,
  paymentMonth: "",
  rewardAmount: 0,
};

export const detail: Revenues.Detail = {
  rewardAmount: 0,
  paymentAmount: 0,
  carryOveredRewardAmount: 0,
  withholdingTaxAmount: 0,
  commissionAmount: 0,
  thisMonthRevenues: [],
  thisMonthRewardAmount: 0,
  lastMonthCarryOveredRevenues: [],
  lastMonthCarryOveredRewardAmount: 0,
  thisMonthCarryOveredReward: 0,
  isGroupPayment: false,
  isGroupPaymentParent: false,
  status: "",
  existenceFlag: false,
};
