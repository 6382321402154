import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { auth as authAjax } from "../../utils/ajax";
import { toastError } from "../../utils/appearError";
import Input from "../atoms/Input";
import SubmitButton from "../atoms/SubmitButton";

const IdPasswordSignIn: React.FC = () => {
  const history = useHistory();
  const [signInEmail, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const postLogin = async (
    e: React.FormEvent<HTMLFormElement>
  ): Promise<void> => {
    e.preventDefault();
    // parameterがこの形なのはcoda側の実装に依存している。
    authAjax
      .idPasswordSignIn({ creator: { signin_email: signInEmail, password } }) // eslint-disable-line @typescript-eslint/camelcase
      .then((): void => {
        history.push("/");
      })
      .catch((): void => {
        toastError("ログイン情報が異なります。再度入力してください");
      });
  };

  return (
    <StyledForm
      onSubmit={(e: React.FormEvent<HTMLFormElement>): Promise<void> =>
        postLogin(e)
      }
    >
      <StyledFormGroup>
        <Input
          type="text"
          value={signInEmail}
          onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
            setEmail(e.target.value)
          }
          name="email"
          placeholder="メールアドレス"
        />
      </StyledFormGroup>
      <StyledFormGroup>
        <Input
          type="password"
          value={password}
          onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
            setPassword(e.target.value)
          }
          name="password"
          placeholder="パスワード"
        />
      </StyledFormGroup>
      <SubmitButton
        name="commit"
        value="ログイン"
        dataDisableValue="ログイン"
      />
    </StyledForm>
  );
};

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
`;

const StyledFormGroup = styled.div`
  margin-bottom: 1.2rem;
  text-align: center;

  &:last-of-type {
    margin-bottom: 2.4rem;
  }
`;

export default IdPasswordSignIn;
