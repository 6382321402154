import React from "react";
import { ReactComponent as LineIcon } from "../../assets/images/line_icon.svg";
import Button from "../atoms/Button";

interface Props {
  onClick: (e: React.FormEvent<HTMLInputElement>) => void;
}

const LineButton: React.FC<Props> = (props: Props) => {
  return (
    <Button as="button" onClick={props.onClick}>
      <LineIcon />
      <span>LINEでログイン&nbsp;&nbsp;&nbsp;</span>
    </Button>
  );
};
export default LineButton;
