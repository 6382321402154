import React from "react";
import styled from "styled-components";
import HowToGetLineLiveUrl from "../../assets/images/how_to_get_line_live_account_url.png";
import Logo from "../../assets/images/how_to_get_pococha_account_url.png";

const images = {
  "how_to_get_line_live_account_url.png": HowToGetLineLiveUrl,
  "how_to_get_pococha_account_url.png": Logo,
};

type imageKeys = keyof typeof images;

interface Props {
  imageKeys: imageKeys;
}

const Image: React.FC<Props> = (props: Props): JSX.Element => {
  return (
    <ImageWrapper>
      <StyledImage src={images[props.imageKeys]} />
    </ImageWrapper>
  );
};

export default Image;

const ImageWrapper = styled.div`
  margin: auto;
`;
const StyledImage = styled.img`
  width: 100%;
  max-width: 952px;
  max-height: 600px;
`;
