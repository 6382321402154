import React, { useState } from "react";
import styled from "styled-components";
import { useForm } from "react-hook-form";
import snakeCaseKeys from "snakecase-keys";

import GoogleProfileAuthorization from "../components/molecules/GoogleProfileAuthorization";
import EntryConfirmation from "../components/molecules/NewEntryConfirmation";
import EntryComplete from "../components/molecules/EntryComplete";
import SubmitButton from "../components/atoms/SubmitButton";
import ProgressBar from "../components/molecules/ProgressBar";
import Form from "../components/molecules/Form";
import FormItemList, {
  Props as FormItemListProps,
} from "../components/molecules/FormItemList";

import {
  Labels,
  formRules,
  labels,
  sortFormAttributes,
} from "../utils/forms/entry";
import { entries } from "../utils/ajax";
import { toastError } from "../utils/appearError";
import { useEntryFormItems } from "../hooks/EntryFormItems";

interface Google {
  name: string;
  id: string;
  email: string;
}

interface EntryInfoState {
  progress: number;
  google: Google;
  entry: Labels;
}

const EditEntryInfo: React.FC = (): JSX.Element => {
  const [entryInfoState, setEntryInfoState] = useState<EntryInfoState>({
    progress: 1,
    google: {
      id: "",
      name: "",
      email: "",
    },
    entry: {
      name: "",
      firstName: "",
      lastName: "",
      firstNameKana: "",
      lastNameKana: "",
      email: "",
      entryType: "",
      address: "",
      gender: "",
      postcode: "",
      birthdate: "",
      selfAppeal: "",
    },
  });
  const formItems = useEntryFormItems(
    entryInfoState.google,
    entryInfoState.entry
  );

  const { register, errors, handleSubmit } = useForm<Labels>();

  const multiFormProps: FormItemListProps<Labels> = {
    rules: formRules,
    formProps: formItems,
    errors,
    register,
  };

  const updateEntry = (arg: EntryInfoState): void => {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    setEntryInfoState(arg);
  };

  const authorizeGoogleInfoSuccess = (arg: Google) => {
    setEntryInfoState({ ...entryInfoState, progress: 2, google: arg });
  };

  const onSubmitFunc = (data: Labels): void => {
    const entry = {
      ...data,
      entryType: "other",
    };

    updateEntry({ ...entryInfoState, entry, progress: 3 });
  };

  const onCompleteForm = (e: React.FormEvent<HTMLFormElement>): Promise<void> =>
    handleSubmit((data: Labels) => onSubmitFunc(data))(e);

  const onSubmit = async (
    e: React.FormEvent<HTMLFormElement>
  ): Promise<void> => {
    if (!entryInfoState.google.email || !entryInfoState.entry) return;

    const entry = {
      ...entryInfoState.entry,
      googleId: entryInfoState.google.id,
      googleEmail: entryInfoState.google.email,
      entryRoute: "network",
    };

    e.preventDefault();

    try {
      await entries.create(
        snakeCaseKeys({
          entry,
        })
      );
      updateEntry({ ...entryInfoState, progress: 4 });
    } catch (err) {
      if (err.response.status === 409) toastError(err.response.data.message);
      else toastError("エラーが発生しました");
    }
  };

  const steps = ["Google認証", "情報入力", "情報確認", "完了"];

  return (
    <Content>
      <ProgressBarWrapper progress={entryInfoState.progress}>
        <ProgressBar steps={steps} progress={entryInfoState.progress} />
      </ProgressBarWrapper>
      {entryInfoState.progress === 1 && (
        <GoogleProfileAuthorization
          successFunc={authorizeGoogleInfoSuccess}
          text={"Googleアカウントと連携してエントリーをはじめよう"}
        />
      )}
      {entryInfoState.progress === 2 && (
        <StyledFormWrapper>
          <Form onSubmit={onCompleteForm} align="center">
            <FormItemList<Labels> {...multiFormProps}></FormItemList>
            <SubmitButton
              name="commit"
              value="次へ"
              backgroundColor="#0074AE"
              dataDisableValue="次へ"
            />
          </Form>
        </StyledFormWrapper>
      )}
      {entryInfoState.progress === 3 && (
        <EntryConfirmation<Labels>
          backPreviousProgress={(): void =>
            updateEntry({ ...entryInfoState, progress: 2 })
          }
          onSubmit={onSubmit}
          formValues={entryInfoState.entry}
          labels={labels}
          sortFormAttributes={sortFormAttributes}
        />
      )}
      {entryInfoState.progress === 4 && (
        <EntryComplete
          title={
            <div>
              エントリー
              <br />
              ありがとうございました
            </div>
          }
          notice={
            <div>
              10営業日以内に
              <br />
              営業より後日ご連絡いたします
            </div>
          }
        />
      )}
    </Content>
  );
};

export default EditEntryInfo;

const Content = styled.main`
  position: relative;
  display: inline-block;
  min-height: calc(100vh - 11.2rem);
  padding: 6.4rem 1.6rem;

  @media (max-width: 896px) {
    margin: 0;
    min-height: calc(100vh - 12.4rem);
    padding: 3.2rem 1.6rem;
  }
`;

const ProgressBarWrapper = styled.div<{ progress: number }>`
  display: flex;
  justify-content: center;
  margin: 0 auto;
  margin-bottom: 2.4rem;
  align-items: center;
  padding-inline-start: 0;

  ${({ progress }) =>
    progress === 1 &&
    `
    padding-top: 28.6rem;
    margin-bottom: 4.8rem;

    @media (max-width: 896px) {
      padding-top: 20.7rem;
    }
  `}
`;

const StyledFormWrapper = styled.div`
  width: 100%;
  margin: 0 auto;
  max-width: 60rem;
  min-width: 28rem;
  align-items: center;
`;
