export enum ActionTypes {
  UPDATE_AUTH_STATUS = "@@auth/UPDATE_AUTH_STATUS",
  FETCH_AUTH_STATUS = "@@auth/FETCH_AUTH_STATUS",
  FETCH_REQUEST = "@@auth/FETCH_REQUEST",
  FETCH_SUCCESS = "@@auth/FETCH_SUCCESS",
  FETCH_ERROR = "@@auth/FETCH_ERROR",
}

export interface State {
  readonly isLogin: boolean;
  readonly isAgreedTerms: boolean;
  readonly isLoading: boolean;
}

export interface Action {
  type: string;
  payload?: AuthPayload;
}

export interface AuthPayload {
  isAgreedTerms: boolean;
  isLogin: boolean;
}
