import { MUUU, CREAS, LMND } from "../../constants";
import {
  Creator,
  CreatorAvailableServicesEnum,
} from "@uuum/coda-mypage-api-sdk";

export enum ActionTypes {
  FETCH_INFO = "@@creator/FETCH_INFO",
  FETCH_REQUEST = "@@creator/FETCH_REQUEST",
  FETCH_SUCCESS = "@@creator/FETCH_SUCCESS",
  FETCH_ERROR = "@@creator/FETCH_ERROR",
}

export type AvailableService = typeof MUUU | typeof CREAS | typeof LMND;

export type State = Creator & { isLoading: boolean };

export interface Action {
  type: string;
  payload?: Creator;
}

export type CreatorAvailableServices = CreatorAvailableServicesEnum;
