import React from "react";
import styled from "styled-components";
import Content from "../components/templates/ContentFullScreen";

const Error404: React.FC = (): JSX.Element => {
  return (
    <Content>
      <StyledTitle>
        <span>404</span>
        NOT FOUND
      </StyledTitle>
      <StyledMessage>
        <i className="fas fa-exclamation-triangle" />
        ページが見つかりません
      </StyledMessage>
    </Content>
  );
};

const StyledTitle = styled.h2`
  margin-bottom: 2.4rem;
  font-size: 4.8rem;
  font-weight: bold;
  line-height: 1;
  text-align: center;

  @media (max-width: 896px) {
    font-size: 2.4rem;
  }

  > span {
    margin-right: 1.6rem;

    @media (max-width: 896px) {
      display: block;
      margin: 0 0 0.8rem;
      font-size: 3.2rem;
    }
  }
`;

const StyledMessage = styled.p`
  font-size: 1.6rem;
  font-weight: bold;
  line-height: 1;
  text-align: center;

  > i {
    margin: 0 0.8rem 0 0;
  }
`;

export default Error404;
