import React from "react";
import styled from "styled-components";

import Button from "../atoms/Button";
interface Props {
  readonly title?: string;
  readonly text: string;
  readonly url: string;
  readonly disabled?: boolean;
}

const DownloadNav: React.FC<Props> = (props: Props): JSX.Element => {
  const downloadAction = (): void => {
    window.open(props.url, "_blank");
  };

  return (
    <section>
      <Title>{props.title}</Title>
      <Row>
        <LeftColumn>{props.text}</LeftColumn>
        <RightColumn>
          <Button
            as="button"
            onClick={(): void => downloadAction()}
            disabled={props.disabled}
            type="info"
          >
            ダウンロード
          </Button>
        </RightColumn>
      </Row>
    </section>
  );
};

const Title = styled.h2`
  margin-bottom: 3.2rem;
  font-size: 2.4rem;
  font-weight: bold;

  @media (max-width: 896px) {
    font-size: 1.8rem;
  }
`;

const Row = styled.div`
  display: flex;

  @media (max-width: 896px) {
    flex-direction: column;
  }
`;

const LeftColumn = styled.div`
  flex: 1;
  padding-right: 10rem;
  color: #676767;
  padding-left: 1em;
  text-indent: -1em;

  @media (max-width: 896px) {
    padding: 0 0 1.6rem;
  }

  > p {
    margin: 0;
    font-size: 1.6rem;
    color: #676767;

    @media (max-width: 896px) {
      font-size: 1.2rem;
    }
  }
`;

const RightColumn = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 34rem;
`;

export default DownloadNav;
