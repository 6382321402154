import React, { useEffect } from "react";
import styled from "styled-components";
import { Redirect, useHistory, useLocation } from "react-router-dom";

import Content from "../components/templates/ContentFullScreen";
import IdPasswordSignInSection from "../components/molecules/IdPasswordSignIn";
import Button from "../components/atoms/Button";
import { ReactComponent as Logo } from "../assets/images/logo.svg";
import GoogleButton from "../components/molecules/GoogleButton";
import LineButton from "../components/molecules/LineButton";
import Alert from "../components/molecules/Alert";

import { useAuth } from "../hooks/Auth";
import { toastError } from "../utils/appearError";
import { auth as authAjax } from "../utils/ajax";

function useQuery(): URLSearchParams {
  return new URLSearchParams(useLocation().search);
}

const SignIn: React.FC = () => {
  const history = useHistory();
  const query = useQuery();
  const samlRequest = query.get("saml_request") || "";
  const relayState = query.get("relay_state") || "";
  const provider = query.get("provider") || "";
  const isError = !!query.get("is_error");
  const lmndRequest = query.get("show_lmnd") || "";
  const { data: authState, isLoading: authIsLoading } = useAuth();

  const encodedSamlRequest = encodeURIComponent(samlRequest).replace(
    /%20/g,
    "%2B"
  );
  const encodedRelayState = encodeURIComponent(relayState).replace(
    /%20/g,
    "%2B"
  );
  const googleLoginUrl = `${process.env.REACT_APP_CODA_API_SERVER_URL}/mypage/auth/google_oauth2?callback_action=login&amp;saml_request=${encodedSamlRequest}&relay_state=${encodedRelayState}&client_id=`;
  const lineLoginUrl = `${process.env.REACT_APP_CODA_API_SERVER_URL}/mypage/auth/line?callback_action=login&amp;saml_request=${encodedSamlRequest}&relay_state=${encodedRelayState}`;
  const onClickGoogle = () => (window.location.href = googleLoginUrl);
  const onClickLine = () => (window.location.href = lineLoginUrl);

  const debugLogin = async (
    e: React.FormEvent<HTMLInputElement>
  ): Promise<void> => {
    e.preventDefault();
    await authAjax.debugLogin();
    history.push("/");
  };

  useEffect(() => {
    if (isError) toastError("正しいアカウントでログインしてください");
    return (): undefined => undefined;
  }, [isError]);

  if (authIsLoading) return <div data-testid="dummyComponent" />;

  // これ必要....???? saml requestかつerrorが出てなかったら雑に投げるだけでいいの...??
  if (samlRequest !== "" && !isError) {
    if (provider === "line") {
      window.location.href = lineLoginUrl;
    } else {
      window.location.href = googleLoginUrl;
    }

    return <div />;
  }

  if (lmndRequest) {
    sessionStorage.setItem("ShowLmndModal", "true");
  }

  return (
    (authState.isLogin && <Redirect to={"/"} />) || (
      <Content width="34rem">
        {lmndRequest && (
          <Alert
            type="lmnd"
            title={`LINE連携が完了致しました。
            改めて「LINEでログイン」からログインをいただくことでマイページ連携が完了致します。`}
          ></Alert>
        )}
        <Header>
          <Logo />
        </Header>

        {process.env.NODE_ENV !== "production" && <IdPasswordSignInSection />}
        {process.env.NODE_ENV !== "production" && <OrText />}

        <SignInList>
          <SignInListItem>
            <GoogleButton onClick={onClickGoogle} />
          </SignInListItem>
          <SignInListItem>
            <LineButton onClick={onClickLine} />
          </SignInListItem>
          {process.env.NODE_ENV === "development" && (
            <SignInListItem>
              <Button
                onClick={(e): Promise<void> => debugLogin(e)}
                type="success"
              >
                Force login for dev
              </Button>
            </SignInListItem>
          )}
        </SignInList>
      </Content>
    )
  );
};

const Header = styled.h1`
  text-align: center;
  width: 15rem;
  margin: 0 auto 4.4rem;
  font-size: 0;
`;

const SignInList = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
`;

const SignInListItem = styled.li`
  margin-bottom: 2rem;
  text-align: center;

  &:last-child {
    margin-bottom: 0;
  }
`;

const OrText = styled.div`
  position: relative;
  margin: 2.4rem 0;
  font-size: 1.2rem;
  text-align: center;

  &::before {
    content: "";
    z-index: -1;
    position: absolute;
    top: 50%;
    display: block;
    width: 100%;
    height: 0.1rem;
    background-color: #eee;
  }

  &::after {
    content: "または";
    padding: 0 1.2rem;
    background-color: #fff;
  }
`;

export default SignIn;
