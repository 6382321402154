import React from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useHistory } from "react-router-dom";

import { creator, types, auth } from "../../store";
import { auth as authAjax } from "../../utils/ajax";

import GlobalNavItem from "../atoms/GlobalNavItem";

interface Props {
  mobileMenuChangeFunc: MobileMenuChangeFunc;
}

interface MobileMenuChangeFunc {
  (state: boolean): void;
}

const GlobalNav: React.FC<Props> = (props: Props) => {
  const { pathname } = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const creatorState: creator.types.State = useSelector<
    types.RootState,
    creator.types.State
  >((state) => state.creator);
  const isVoomCreator = creatorState.isVoomCreator;

  const isAvailableRevenue = creatorState.isRevenueViewed;

  const isNetworkCreator = creatorState.isNetworkCreator;

  const signOut = (e: React.FormEvent<HTMLInputElement>): void => {
    e.preventDefault();

    authAjax.signOut().then(() => {
      dispatch(
        auth.actions[auth.types.ActionTypes.UPDATE_AUTH_STATUS]({
          isLogin: false,
          isAgreedTerms: false,
        })
      );
      history.push("/sign_in");
    });
  };

  function checkIsActive(menuPath: string): boolean {
    return pathname === menuPath;
  }

  return (
    <StyledGlobalNav>
      <GlobalNavItem
        as={Link}
        to={"/"}
        active={checkIsActive("/")}
        onClick={(): void => props.mobileMenuChangeFunc(false)}
      >
        <i className="fas fa-home" />
        ホーム
      </GlobalNavItem>
      {isAvailableRevenue && (
        <GlobalNavItem
          as={Link}
          to={"/revenues"}
          active={checkIsActive("/revenues")}
          onClick={(): void => props.mobileMenuChangeFunc(false)}
        >
          <i className="fas fa-search-dollar" />
          収益
        </GlobalNavItem>
      )}
      <GlobalNavItem
        as={Link}
        to="/profile"
        active={checkIsActive("/profile")}
        onClick={(): void => props.mobileMenuChangeFunc(false)}
      >
        <i className="fas fa-user" />
        個人情報
      </GlobalNavItem>
      <GlobalNavItem
        as={Link}
        to="/payment"
        active={checkIsActive("/payment")}
        onClick={(): void => props.mobileMenuChangeFunc(false)}
      >
        <i className="fas fa-university" />
        支払情報
      </GlobalNavItem>
      {/* ページ完成まで先が遠いため、しばらく本番環境では表示をOFF */}
      {process.env.NODE_ENV !== "production" && (
        <GlobalNavItem
          as={Link}
          to="/sns_linkages"
          active={checkIsActive("/sns_linkages")}
          onClick={(): void => props.mobileMenuChangeFunc(false)}
        >
          <i className="fas fa-link" />
          SNS連携
        </GlobalNavItem>
      )}
      {isVoomCreator && (
        <GlobalNavItem
          as={Link}
          to="/voom_faq"
          active={checkIsActive("/voom_faq")}
          onClick={(): void => props.mobileMenuChangeFunc(false)}
        >
          <i className="fas fa-question-circle" />
          LINE VOOM よくある質問
        </GlobalNavItem>
      )}
      {isNetworkCreator && (
        <GlobalNavItem
          as={Link}
          to="/appear_in_ad_survey"
          active={checkIsActive("/appear_in_ad_survey")}
          onClick={(): void => props.mobileMenuChangeFunc(false)}
        >
          <i className="fas fa-clipboard-check" />
          広告出演希望
        </GlobalNavItem>
      )}
      <GlobalNavItem as="a" href="!#" onClick={(e): void => signOut(e)}>
        <i className="fas fa-sign-out-alt" />
        ログアウト
      </GlobalNavItem>
    </StyledGlobalNav>
  );
};

const StyledGlobalNav = styled.nav`
  display: flex;
  flex-direction: column;
`;

export default GlobalNav;
