import React from "react";
import styled from "styled-components";
import Content from "../components/templates/Content";

const Question: React.FC = (): JSX.Element => {
  return (
    <Content title="UUUM マイページの使い方">
      <StyledBody>
        <section>
          <h2>「UUUM マイページ」とは</h2>
          <p>
            月別の支払明細やご登録いただいている口座情報を確認したり、支払証明書のダウンロードができるシステムです
          </p>
        </section>
        <section>
          <h2>マイページでできること</h2>
          <ul>
            <li>月別の支払額の確認</li>
            <li>支払証明書のダウンロード</li>
            <li>支払先口座情報の確認・編集</li>
            <li>個人情報の確認・編集</li>
          </ul>
        </section>
        <section>
          <h2>月別の支払額の確認</h2>
          <section>
            <h3>当月分の支払額の確認</h3>
            <ol>
              <li>TOPページ、もしくはサイドバーの「収益」を開く</li>
              <li>
                上部に当月分の支払金額が表示されます
                <p>
                  ※●月の支払額の横の（）内に、支払額の確定段階（確定/仮確定/未確定）が表示されます
                </p>
              </li>
            </ol>
          </section>
          <section>
            <h3>月別の支払額、明細の確認</h3>
            <ol>
              <li>TOPページ、もしくはサイドバーの「収益」を開く</li>
              <li>月別支払額のグラフで1年ごとの支払額の推移が確認できます</li>
              <li>
                グラフの下に月別の支払額が一覧で表示されます
                <p>
                  ※未確定の段階では一覧に金額は表示されず「未確定」と表示されます
                </p>
              </li>
              <li>
                一覧から閲覧したい月のリンクを開くと、明細ページに遷移して支払額の明細が確認できます
                <p>
                  ※支払額の上部にある日付横の（）内に、支払額の確定段階（確定/仮確定/未確定）が表示されます
                </p>
              </li>
            </ol>
          </section>
        </section>
        <section>
          <h2>支払証明書のダウンロード</h2>
          <ol>
            <li>TOPページ、もしくはサイドバーの「収益」を開く</li>
            <li>月別支払額の一覧からダウンロードしたい月の明細ページを開く</li>
            <li>下部にある「支払証明書」のダウンロードボタンを押す</li>
            <li>
              支払証明書が表示されますので、ご自身の端末からダウンロードや印刷を行ってください
              <p>
                ※支払証明書のダウンロードは、支払額確定後、翌月よりダウンロード可能になります
              </p>
            </li>
          </ol>
        </section>
        <section>
          <h2>支払先口座情報の確認・編集</h2>
          <section>
            <h3>確認</h3>
            <ol>
              <li>サイドバーの「支払情報」を開く</li>
              <li>支払先口座情報が表示されます</li>
            </ol>
            <h3>編集</h3>
            <ol>
              <li>サイドバーの「支払情報」を開く</li>
              <li>支払先口座情報を編集し、「更新」ボタンを押す</li>
              <li>支払先口座情報が更新されます</li>
            </ol>
          </section>
        </section>
        <section>
          <h2>法人口座への変更</h2>
          <section>
            <p>
              個人口座から法人口座に切り替えられる場合、月末5営業日前までに下記の書類の画像をメールにてご送付ください。
            </p>
            <h3>書類の画像</h3>
            <ol>
              <li>3ヶ月以内に取得した会社の登記簿謄本の画像</li>
              <li>法人代表者様の身分証の画像</li>
            </ol>
            <StyleBox>
              <section>
                <p>
                  UUUMマイページのご登録者と法人代表者の名義が違う場合は、チャンネル代表者様の身分証も必要です。
                  <br />
                  これらの必要書類をメールにてご提出いただいた後、こちらで審査させていただきます。
                  <br />
                  <br />
                  審査が完了しましたら翌月初旬にご連絡いたしますので、
                  <br />
                  その月の20日までにご自身でUUUMマイページより口座情報の変更を行い、
                  完了したら必ずUUUMネットワーク事務局までお知らせください。
                  <br />
                  <br />
                  事務局へのご報告が完了しましたら、その月末のお支払いから源泉徴収対象外とさせていただきます。
                  <br />
                  詳しくはこちらのページをご覧ください。
                  <a href="https://creators.uuum.jp/page/8340">
                    https://creators.uuum.jp/page/8340
                  </a>
                </p>
              </section>
            </StyleBox>
          </section>
        </section>
        <section>
          <h2>個人情報の確認・編集</h2>
          <section>
            <h3>確認</h3>
            <ol>
              <li>サイドバーの「個人情報」を開く</li>
              <li>個人情報が表示されます</li>
            </ol>
            <h3>編集</h3>
            <ol>
              <li>サイドバーの「個人情報」を開く</li>
              <li>個人情報を編集し、「更新」ボタンを押す</li>
              <li>個人情報が更新されます</li>
            </ol>
          </section>
        </section>
        <section>
          <h2>わからないことがあったら</h2>
          <p>UUUMの担当者にお問い合わせください</p>
        </section>
      </StyledBody>
    </Content>
  );
};

const StyledBody = styled.div`
  margin: auto;
  line-height: 1.5;

  @media (max-width: 896px) {
    font-size: 1.4rem;
  }

  @media (max-width: 896px) {
    max-width: initial;
  }

  > section {
    margin-bottom: 2.4rem;

    > h2 {
      margin-bottom: 1.2rem;
      font-size: 2rem;
      font-weight: bold;

      @media (max-width: 896px) {
        font-size: 1.6rem;
      }
    }

    > ul {
      padding-left: 2.4rem;
    }

    > section {
      margin-bottom: 2rem;

      > h3 {
        font-size: 1.6rem;
        font-weight: bold;

        @media (max-width: 896px) {
          font-size: 1.4rem;
        }
      }
    }

    p,
    li {
      font-size: 1.4rem;
    }
  }
`;

const StyleBox = styled.div`
  background-color: #f8f8f8;
  padding: 15px;
`;

export default Question;
