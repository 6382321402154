import { useState, useEffect } from "react";
import { creatorsApi, payeeApi, businessInvoiceApi } from "../utils/ajax";
import { FormItemProps } from "../components/molecules/FormItemList";

import { Labels, labels } from "../utils/forms/creasUserRegistration";
import { Payee, BusinessInvoice } from "@uuum/coda-mypage-api-sdk";

const paymentItems: FormItemProps<Labels> = [
  {
    formItemProps: {
      type: "text",
      placeholder: "全角",
      name: "bankName",
    },
    field: "input",
    label: labels.bankName,
  },
  {
    formItemProps: {
      type: "text",
      placeholder: "半角数字 4桁",
      name: "bankCode",
      maxlength: 4,
      minlength: 4,
    },
    field: "input",
    label: labels.bankCode,
  },
  {
    formItemProps: {
      type: "text",
      placeholder: "全角",
      name: "branchName",
    },
    field: "input",
    label: labels.branchName,
  },
  {
    formItemProps: {
      type: "text",
      placeholder: "半角数字 3桁",
      name: "branchCode",
      maxlength: 3,
      minlength: 3,
    },
    field: "input",
    label: labels.branchCode,
  },
  {
    formItemProps: {
      name: "accountType",
      options: [
        { value: "normal", text: "普通" },
        { value: "checking", text: "当座" },
        { value: "other", text: "その他" },
      ],
    },
    field: "select",
    label: labels.accountType,
  },
  {
    formItemProps: {
      type: "text",
      placeholder: "半角数字 7桁",
      name: "accountNo",
      maxlength: 7,
      minlength: 7,
    },
    field: "input",
    label: labels.accountNo,
  },
  {
    formItemProps: {
      type: "text",
      placeholder: "半角カナ 英数字 ※小文字不可",
      name: "accountName",
    },
    field: "input",
    label: labels.accountName,
  },
];

const invoiceItems: FormItemProps<Labels> = [
  {
    formItemProps: {
      type: "text",
      placeholder: "登録番号 13桁",
      name: "businessInvoiceNumber",
      maxlength: 13,
      minlength: 13,
    },
    field: "input",
    label: labels.businessInvoiceNumber,
  },
];

let creatorItems: FormItemProps<Labels> = [
  {
    formItemProps: {
      type: "text",
      placeholder: "電話番号",
      name: "phoneNumber",
    },
    field: "input",
    label: labels.phoneNumber,
  },
  {
    formItemProps: {
      type: "text",
      placeholder: "郵便番号",
      name: "postcode",
      maxlength: 7,
    },
    field: "input",
    label: labels.postcode,
  },
  {
    formItemProps: {
      type: "text",
      placeholder: "住所",
      name: "address",
    },
    field: "input",
    label: labels.address,
  },
  {
    formItemProps: {
      type: "text",
      placeholder: "山田",
      name: "lastName",
    },
    field: "input",
    label: labels.lastName,
  },
  {
    formItemProps: {
      type: "text",
      placeholder: "太郎",
      name: "firstName",
    },
    field: "input",
    label: labels.firstName,
  },
  {
    formItemProps: {
      type: "text",
      placeholder: "ヤマダ",
      name: "lastNameKana",
    },
    field: "input",
    label: labels.lastNameKana,
  },
  {
    formItemProps: {
      type: "text",
      placeholder: "タロウ",
      name: "firstNameKana",
    },
    field: "input",
    label: labels.firstNameKana,
  },
  {
    formItemProps: {
      name: "birthdate",
    },
    field: "date",
    label: labels.birthdate,
  },
  {
    formItemProps: {
      name: "gender",
      options: [
        { value: "男性", text: "男性" },
        { value: "女性", text: "女性" },
        { value: "その他", text: "その他" },
      ],
    },
    field: "select",
    label: labels.gender,
  },
];

interface CreasUserFirstRegistrationForm {
  isLoading: boolean;
  payment: Payee["zenginBank"];
  invoice: BusinessInvoice;
  paymentMethod?: "other" | "zengin";
  paymentFormItemProps: FormItemProps<Labels>;
  creatorFormItemProps: FormItemProps<Labels>;
  invoiceFormItemProps: FormItemProps<Labels>;
}

function extractUnfilledTags(
  array: FormItemProps<Labels>,
  compareObj?: any
): FormItemProps<Labels> {
  if (!compareObj) return [];

  // 情報の入っていないやつを取得する
  return array.filter((item) => !compareObj[item.formItemProps.name]);
}

export function useCreasUserFirstRegistrationForm(
  path?: string
): CreasUserFirstRegistrationForm {
  const [status, setStatus] = useState<CreasUserFirstRegistrationForm>({
    isLoading: true,
    payment: {},
    invoice: {},
    paymentMethod: undefined,
    paymentFormItemProps: [],
    creatorFormItemProps: [],
    invoiceFormItemProps: [],
  });

  useEffect(() => {
    Promise.all([
      creatorsApi.getCreators(),
      payeeApi.getPayee(),
      businessInvoiceApi.getBusinessInvoice(),
    ])
      .then((res) => {
        const creatorRes = res[0];
        const payeeRes = res[1];
        const invoiceRes = res[2];
        const zenginRes = payeeRes.zenginBank;

        if ((creatorRes.age || creatorRes.age === 0) && creatorRes.age < 20) {
          creatorItems = creatorItems.concat([
            {
              formItemProps: {
                type: "text",
                placeholder: "保護者姓名（姓）",
                name: "parentLastName",
              },
              field: "input",
              label: labels.parentLastName,
            },
            {
              formItemProps: {
                type: "text",
                placeholder: "保護者姓名（名）",
                name: "parentFirstName",
              },
              field: "input",
              label: labels.parentFirstName,
            },
            {
              formItemProps: {
                type: "text",
                placeholder: "保護者電話番号",
                name: "parentPhoneNumber",
              },
              field: "input",
              label: labels.parentPhoneNumber,
            },
            {
              formItemProps: {
                options: [
                  { value: "mother", text: "母" },
                  { value: "father", text: "父" },
                  { value: "other", text: "その他" },
                ],
                name: "parentRelationship",
              },
              field: "select",
              label: labels.parentRelationship,
            },
          ]);
        }

        if (payeeRes.paymentMethod === "other") {
          setStatus({
            isLoading: false,
            payment: zenginRes,
            invoice: invoiceRes,
            paymentMethod: payeeRes.paymentMethod,
            paymentFormItemProps: [],
            creatorFormItemProps: extractUnfilledTags(creatorItems, creatorRes),
            invoiceFormItemProps: [],
          });
        } else {
          setStatus({
            isLoading: false,
            payment: zenginRes,
            invoice: invoiceRes,
            paymentMethod: payeeRes.paymentMethod,
            paymentFormItemProps: extractUnfilledTags(
              paymentItems,
              zenginRes || {}
            ),
            creatorFormItemProps: extractUnfilledTags(creatorItems, creatorRes),
            invoiceFormItemProps: extractUnfilledTags(
              invoiceItems,
              invoiceRes || {}
            ),
          });
        }
      })
      .catch((_) =>
        setStatus({
          isLoading: false,
          payment: {},
          invoice: {},
          paymentMethod: undefined,
          paymentFormItemProps: [],
          creatorFormItemProps: [],
          invoiceFormItemProps: [],
        })
      );
  }, [path]);
  return status;
}
