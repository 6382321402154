import React, { useState } from "react";
import Content from "../components/templates/Content";
import SubmitButton from "../components/atoms/SubmitButton";
import Form from "../components/molecules/Form";
import { initialResponses } from "../utils/ajax";
import snakeCaseKeys from "snakecase-keys";
import { createAxiosHook } from "../utils/createAxiosHook";
import { AdSurveys } from "../utils/ajax/types";
import axios from "axios";
import styled from "styled-components";
import RadioGridRow from "../components/atoms/RadioGridRow";
import { toastSuccess, toastError } from "../utils/appearError";

import { creator, types } from "../store";
import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";

const useAdSurvey = createAxiosHook(
  "get",
  "/mypage/api/v1/creator_appear_in_ad_survey"
);

const values = [
  "非常に消極的",
  "消極的",
  "どちらでもない",
  "積極的",
  "非常に積極的",
];

const ratings = [
  "very_negative",
  "negative",
  "neutral",
  "positive",
  "very_positive",
];

const AppearInAdSurvey: React.FC = () => {
  const creatorState: creator.types.State = useSelector<
    types.RootState,
    creator.types.State
  >((state) => state.creator);

  const { data: adSurveys, loading } = useAdSurvey(
    initialResponses.adSurveys.basic
  );

  // TODO: useform使うようにする時にこのコメントも消す
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [adSurveysInit, setAdSurveysInit] = useState<
    AdSurveys.AppearInAdSurveys[]
  >([]);

  const onChange = (
    adSurvey: AdSurveys.AppearInAdSurveys,
    e: React.FormEvent<HTMLInputElement>
  ) => {
    adSurvey.rating = ratings[values.indexOf(e.currentTarget.value)];
    adSurveys.map((value: AdSurveys.AppearInAdSurveys) =>
      value.id === adSurvey.id ? adSurvey : value
    );
    // 画面が更新されないため暫定対応
    setAdSurveysInit([]);
  };

  const onSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
    axios
      .patch("/mypage/api/v1/creator_appear_in_ad_survey", {
        creatorAppearInAdSurveys: snakeCaseKeys(adSurveys),
      })
      .then((response) => {
        toastSuccess("広告出演希望を更新しました");
      })
      .catch((error) => {
        toastError("通信エラーにより変更できませんでした");
      });
  };

  if (loading) return <div />;
  if (!creatorState.isNetworkCreator) return <Redirect to="/404" />;
  if (!creatorState.isMcnJoinCreator) {
    sessionStorage.setItem(
      "HomeAlert",
      "広告出演希望を入力するにはYouTubeのMCNに加入する必要があります。詳細は<a href='https://creators.uuum.jp/apply/monetize' target='_blank' rel='noopener'>こちら</a>からご確認ください。 "
    );
    return <Redirect to="/" />;
  }

  return (
    <Content title="広告出演希望">
      <Form onSubmit={onSubmit} align="center">
        <StyledTable>
          <tbody>
            {adSurveys.map((adSurvey: AdSurveys.AppearInAdSurveys) => {
              return (
                <RadioGridRow
                  key={adSurvey.adGenreName}
                  label={adSurvey.adGenreName}
                  minTitle={values[1]}
                  maxTitle={values[3]}
                  name={adSurvey.adGenreName}
                  value={values}
                  state={values[ratings.indexOf(adSurvey.rating)]}
                  onChange={(e: React.FormEvent<HTMLInputElement>): void =>
                    onChange(adSurvey, e)
                  }
                />
              );
            })}
          </tbody>
        </StyledTable>
        <SubmitButton name="commit" value="更新" dataDisableValue="更新" />
      </Form>
    </Content>
  );
};

const StyledTable = styled.table`
  width: 100%;
  max-width: 800px;
  margin: 0 0 2.4rem;
`;

export default AppearInAdSurvey;
