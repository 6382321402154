import React from "react";
import styled from "styled-components";

interface Props {
  children: React.ReactNode;
  onSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
  align?: "center" | string;
}

const Form: React.FC<Props> = (props: Props) => {
  return (
    <StyledForm onSubmit={props.onSubmit} role="form">
      <StyledFormGroup align={props.align} data-testid="formStyledFormGroup">
        {props.children}
      </StyledFormGroup>
    </StyledForm>
  );
};

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
`;

const StyledFormGroup = styled.div<{ align?: string }>`
  margin-bottom: 1.2rem;

  ${({ align }): string => {
    return align === "center"
      ? `
        display: flex;
        align-items: center;
        flex-direction: column;
      `
      : ``;
  }}

  &:last-of-type {
    margin-bottom: 2.4rem;
  }
`;

export default Form;
