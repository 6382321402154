import { RegisterOptions } from "react-hook-form";

type LabelKeys = keyof Labels;

type LabelKeyRegisterOptions = {
  [key in LabelKeys]: RegisterOptions;
};

type ParentRelationship = "mother" | "father" | "other";
type AccountType = "normal" | "checking" | "other";

export interface Labels {
  phoneNumber: string;
  postcode: string;
  address: string;
  firstName: string;
  lastName: string;
  firstNameKana: string;
  lastNameKana: string;
  birthdate: string;
  gender: string;

  parentLastName: string;
  parentFirstName: string;
  parentRelationship: ParentRelationship;
  parentPhoneNumber: string;

  accountType: AccountType;
  bankName: string;
  bankCode: string;
  branchName: string;
  branchCode: string;
  accountNo: string;
  accountName: string;
  businessInvoiceNumber: string;
}

const labels: { [key in keyof Labels]: string } = {
  phoneNumber: "電話番号",
  address: "住所",
  postcode: "郵便番号",
  firstName: "お名前 (名)",
  lastName: "お名前 (姓)",
  firstNameKana: "フリガナ (名)",
  lastNameKana: "フリガナ (姓)",
  birthdate: "生年月日",
  gender: "性別",

  parentLastName: "保護者氏名（姓）",
  parentFirstName: "保護者氏名（名）",
  parentRelationship: "保護者の続柄",
  parentPhoneNumber: "保護者電話番号",

  accountType: "口座種別",
  bankName: "銀行名",
  bankCode: "銀行コード",
  branchName: "支店名",
  branchCode: "支店コード",
  accountNo: "口座番号",
  accountName: "口座名義",

  businessInvoiceNumber: "インボイス制度 登録番号(数値13桁をご入力ください)",
};

// 追加したい物をkey_valueで追加する。
const rules: LabelKeyRegisterOptions = {
  phoneNumber: {
    pattern: {
      value: /^\+?[0-9]+$/,
      message: "半角数字のみで入力してください",
    },
  },
  address: {
    maxLength: {
      value: 191,
      message: "入力してください",
    },
  },
  postcode: {
    pattern: {
      value: /^[A-Z0-9\s-]+$/,
      message: "正しい形式で入力してください",
    },
  },
  firstName: {
    maxLength: {
      value: 191,
      message: "長すぎます",
    },
  },
  lastName: {
    maxLength: {
      value: 191,
      message: "長すぎます",
    },
  },
  firstNameKana: {
    maxLength: {
      value: 191,
      message: "長すぎます",
    },
    pattern: {
      value: /^[\u30a0-\u30ff　 ー－・]*$/,
      message: "正しい形式で入力してください",
    },
  },
  lastNameKana: {
    maxLength: {
      value: 191,
      message: "長すぎます",
    },
    pattern: {
      value: /^[\u30a0-\u30ff　 ー－・]*$/,
      message: "正しい形式で入力してください",
    },
  },
  birthdate: {
    pattern: /\d{4}\/\d{2}\/\d{1,2}/,
    validate: (value: string) =>
      new Date(value).toString() !== "Invalid Date" ||
      "正確な日付を入力して下さい",
  },
  gender: {
    pattern: {
      value: /男|女|その他/,
      message: "選択して下さい",
    },
  },

  parentLastName: {
    maxLength: {
      value: 191,
      message: "長すぎます",
    },
  },
  parentFirstName: {
    maxLength: {
      value: 191,
      message: "長すぎます",
    },
  },
  parentRelationship: {
    pattern: {
      value: /mother|father|other/,
      message: "選択して下さい",
    },
  },
  parentPhoneNumber: {
    pattern: {
      value: /^\+?[0-9]+$/,
      message: "半角数字のみで入力してください",
    },
  },
  accountType: {
    pattern: /normal|checking|other/,
  },
  bankName: {
    required: "入力してください",
    maxLength: {
      value: 191,
      message: "長すぎます",
    },
  },
  bankCode: {
    maxLength: {
      value: 4,
      message: "4桁で入力してください",
    },
    minLength: {
      value: 4,
      message: "4桁で入力してください",
    },
    pattern: {
      value: /^[0-9]+$/,
      message: "半角数字のみで入力してください",
    },
  },
  branchName: {
    required: "入力してください",
    maxLength: {
      value: 191,
      message: "長すぎます",
    },
  },
  branchCode: {
    maxLength: {
      value: 3,
      message: "3桁で入力してください",
    },
    minLength: {
      value: 3,
      message: "3桁で入力してください",
    },
    pattern: {
      value: /^[0-9]+$/,
      message: "半角数字のみで入力してください",
    },
  },
  accountNo: {
    maxLength: {
      value: 7,
      message: "7桁で入力してください",
    },
    minLength: {
      value: 7,
      message: "7桁で入力してください",
    },
    pattern: {
      value: /^[0-9]+$/,
      message: "半角数字のみで入力してください",
    },
  },
  accountName: {
    maxLength: 40,
    validate: {
      hankakuKomojiCheck: (value: string) =>
        !value.match(/[ｧｨｩｪｫｯｬｭｮ]+/) || "半角小文字は使用できません",
      halfWidthSpaceCheck: (value: string) =>
        value.trim().length > 0 || "半角スペースのみで登録できません",
      pattern: (value: string) =>
        value.match(
          /^[0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZｱｲｳｴｵｶｷｸｹｺｻｼｽｾｿﾀﾁﾂﾃﾄﾅﾆﾇﾈﾉﾊﾋﾌﾍﾎﾏﾐﾑﾒﾓﾔﾕﾖﾗﾘﾙﾚﾛﾜｦﾝｰﾞﾟ.｢｣()\-/\s]+$/
        ) || "半角ｶﾅ・半角数値・半角英字・一部記号のみ入力可能です",
    },
  },
  businessInvoiceNumber: {
    required: false,
    minLength: {
      value: 13,
      message: "13桁で入力してください",
    },
    maxLength: {
      value: 13,
      message: "13桁で入力してください",
    },
    pattern: {
      value: /^[0-9]+$/,
      message: "正しい形式で入力してください",
    },
  },
};

const formRules = (name: keyof Labels) => {
  const validation = rules[name];

  return { required: "入力してください", ...validation };
};

export { formRules, labels };
