import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { ReactComponent as Logo } from "../../assets/images/logo.svg";

interface Props {
  to?: string;
  width?: string;
  padding?: string;
  onClick?: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
}

const defaultProps = {
  to: "/",
  width: "100%",
  padding: "0",
};

const UuumLogo: React.FC<Props> = (props: Props) => {
  return (
    <StyledUuumLogo
      to={props.to || "/"}
      onClick={props.onClick}
      width={props.width}
      padding={props.padding}
      data-testid="uuumLogo"
    >
      <Logo />
    </StyledUuumLogo>
  );
};
UuumLogo.defaultProps = defaultProps;

const StyledUuumLogo = styled(Link)<{ width?: string; padding?: string }>`
  display: inline-block;
  width: ${({ width }): string => (width ? width : "")};
  padding: ${({ padding }): string => (padding ? padding : "")};
`;

export default UuumLogo;
