import React, { useState } from "react";

import GoogleLogin from "react-google-login";
import styled from "styled-components";
import axios from "axios";

import GoogleButton from "../../components/molecules/GoogleButton";

interface Props {
  successFunc: (arg: YoutubeChannel) => void;
  text?: string;
  alert?: string;
}

interface YoutubeChannel {
  id: string;
  name: string;
  subscriberCount: number;
  videoCount: number;
  viewCount: number;
}

const YoutubeChannelAuthorization: React.FC<Props> = (
  props: Props
): JSX.Element => {
  const [error, setError] = useState("");

  const responseGoogle = async (response: any) => {
    try {
      const url = `https://www.googleapis.com/youtube/v3/channels?part=id&part=snippet&part=brandingSettings&part=contentDetails&part=statistics&part=topicDetails&mine=true`;
      const res = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${response.accessToken}`,
        },
      });

      if (res.data.pageInfo.totalResults === 0) {
        setError("正しいアカウントを選択してください");
        return;
      }

      const item = res.data.items[0];

      const youtubeChannel = {
        id: item.id,
        name: item.brandingSettings.channel.title,
        subscriberCount: Number(item.statistics.subscriberCount),
        videoCount: Number(item.statistics.videoCount),
        viewCount: Number(item.statistics.viewCount),
      };

      props.successFunc(youtubeChannel);
    } catch (e) {
      if (e.response.status === 401 || e.response.status === 403) {
        setError(
          `認証に失敗しました\n正しいアカウントか確認してもう一度お試しください`
        );
        return;
      }
      setError(`認証に失敗しました`);
    }
  };

  const errorMessage = (error: string) => {
    const texts = error.split("\n").map((e, index) => {
      return (
        <React.Fragment key={index}>
          {e}
          <br />
        </React.Fragment>
      );
    });
    return <div>{texts}</div>;
  };

  return (
    <StyledContent>
      {props.text && <Header>{props.text}</Header>}

      <SignInList>
        <SignInListItem>
          <GoogleLogin
            clientId={`${process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID}`}
            buttonText="Login"
            onSuccess={responseGoogle}
            cookiePolicy={"single_host_origin"}
            scope="https://www.googleapis.com/auth/youtube.readonly"
            render={(renderProps) => (
              <GoogleButton
                onClick={renderProps.onClick}
                disabled={renderProps.disabled}
              />
            )}
          />
          {error && (
            <ErrorMessage data-testid="errorMessage">
              {errorMessage(error)}
            </ErrorMessage>
          )}
        </SignInListItem>
      </SignInList>
      {props.alert && <Alert>{props.alert}</Alert>}
    </StyledContent>
  );
};

export default YoutubeChannelAuthorization;

const StyledContent = styled.main`
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  width: 100%;
  max-width: 51.1rem;
  margin: auto;
`;

const Header = styled.p`
  text-align: center;
  margin: 0 auto;
  margin-bottom: 1.6rem;
  color: #777;
  font-size: 1.6rem;
  width: 51.1rem;

  @media (max-width: 896px) {
    width: 33.4rem;
  }
`;

const SignInList = styled.ul`
  margin: 0 auto;
  padding: 0;
  width: 33.9rem;
  list-style: none;
`;

const SignInListItem = styled.li`
  margin-bottom: 2rem;
  text-align: center;

  &:last-child {
    margin-bottom: 0;
  }
`;

const ErrorMessage = styled.p`
  margin-top: 1rem;
  color: red;
`;

const Alert = styled.p`
  text-align: center;
  margin: 0 auto;
  margin-top: 1rem;
  color: #f00;
  font-size: 1.4rem;
  width: 51.1rem;

  @media (max-width: 896px) {
    width: 33.4rem;
  }
`;
