import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

import { creator, types } from "../../store";

interface Props {
  mobileMenuOpen: boolean;
}

const Footer: React.FC<Props> = (props: Props): JSX.Element => {
  const creatorState: creator.types.State = useSelector<
    types.RootState,
    creator.types.State
  >((state) => state.creator);
  const isbrowseLiveStreamingTerm = creatorState.isLiveStreamingCreator;
  const isVoomTerm = creatorState.isVoomTermsViewed;

  return (
    <StyledFooter open={props.mobileMenuOpen} data-testid="Footer">
      <Row>
        <StyledNav>
          <Link to="/terms">利用規約</Link>
          <a
            href="https://www.uuum.co.jp/privacy_policy"
            rel="noopener noreferrer"
            target="_blank"
          >
            プライバシーポリシー
          </a>
          {isbrowseLiveStreamingTerm && (
            <Link to="/live_streaming_term/">
              配信プラットフォーム参加利用規約
            </Link>
          )}
          {isVoomTerm && <Link to="/voom_terms/">LINE VOOM支援施策規約</Link>}
        </StyledNav>
        <StyledCopyright>&copy;UUUM</StyledCopyright>
      </Row>
    </StyledFooter>
  );
};

const StyledFooter = styled.footer<{ open: boolean }>`
  display: inline-block;
  margin-top: auto;
  padding: 0 4.8rem;
  transition: all 0.4s ease-in-out;
  margin-left: ${({ open }): string => (open ? "0" : "28rem")};

  @media (max-width: 896px) {
    margin: 0;
  }
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
  height: 4.8rem;
  line-height: 1;

  @media (max-width: 896px) {
    flex-direction: column;
    height: auto;
    padding: 1.6rem;
  }
`;

const StyledNav = styled.nav`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;

  @media (max-width: 896px) {
    margin-bottom: 1.2rem;
  }

  > a {
    display: inline-block;
    position: relative;
    transition: color 0.2s linear;
    color: #767676;
    font-size: 1.2rem;
    text-decoration: none;

    &:hover {
      transition: all 0.1s linear;
      color: rgb(62, 148, 190);
    }

    &:not(:last-child) {
      padding-right: 1rem;
      margin-right: 1rem;

      &::after {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        width: 0.1rem;
        height: 1.2rem;
        background-color: #767676;
      }
    }
  }
`;

const StyledCopyright = styled.small`
  color: #767676;
  font-size: 1.2rem;
`;

export default Footer;
