import { createStore, applyMiddleware, combineReducers } from "redux";
import thunk from "redux-thunk";
import * as types from "./types";
import * as creator from "./creator";
import * as auth from "./auth";

const rootReducer = combineReducers<types.RootState>({
  creator: creator.reducer,
  auth: auth.reducer,
});

const middleware = [thunk];

const store = createStore(rootReducer, applyMiddleware(...middleware));

export default store;

export { types, creator, auth };
