import { Dispatch } from "redux";
import { ActionTypes, AuthPayload, Action } from "./types";
import { auth as authAjax } from "../../utils/ajax";

import { convertKeyToCamel } from "../../utils/toCamel";

const basicActions = {
  [ActionTypes.UPDATE_AUTH_STATUS](payload: AuthPayload): Action {
    return {
      type: ActionTypes.FETCH_SUCCESS,
      payload: convertKeyToCamel<AuthPayload>(payload),
    };
  },
  [ActionTypes.FETCH_REQUEST](): Action {
    return {
      type: ActionTypes.FETCH_REQUEST,
    };
  },
  [ActionTypes.FETCH_ERROR](): Action {
    return {
      type: ActionTypes.FETCH_ERROR,
    };
  },
};

const sideEffectActions = {
  [ActionTypes.FETCH_AUTH_STATUS]:
    () =>
    async (dispatch: Dispatch<Action>): Promise<Action> => {
      try {
        await dispatch(basicActions[ActionTypes.FETCH_REQUEST]());
        const response = await authAjax.status();
        const body = await response.data;
        const payload = convertKeyToCamel<AuthPayload>(body);

        return dispatch(basicActions[ActionTypes.UPDATE_AUTH_STATUS](payload));
      } catch {
        return dispatch(basicActions[ActionTypes.FETCH_ERROR]());
      }
    },
};

export const actions = { ...basicActions, ...sideEffectActions };
