import React, { useState } from "react";
import styled from "styled-components";
import ToggleItem from "../atoms/ToggleItem";
import List from "../molecules/List";
import ListItem from "../atoms/ListItem";

interface Props {
  title: string;
  detail: string | number;
  items: ListItem[];
  width?: string;
  margin?: string;
  titleTestId?: string;
  detailTestId?: string;
}

interface ListItem {
  title: string;
  detail?: string | number;
}

const Toggle: React.FC<Props> = (props: Props) => {
  const [flag, setFlag] = useState(false);

  return (
    <StyledToggle width={props.width || "100%"} margin={props.margin || "0"}>
      <ToggleItem
        title={props.title}
        detail={props.detail}
        onClick={(): void => setFlag(!flag)}
        isActive={flag}
        titleTestId={props.titleTestId}
        detailTestId={props.detailTestId}
      />
      {flag && <List items={props.items} />}
    </StyledToggle>
  );
};

const StyledToggle = styled.div<{ width: string; margin: string }>`
  width: ${({ width }): string => width};
  margin: ${({ margin }): string => margin};
  padding: 0;
  list-style: none;

  > ul > li {
    padding-left: 4rem;
  }
`;

export default Toggle;
