import google from "../../assets/images/google-icon.svg";
import muuu from "../../assets/images/muuu.svg";
import creas from "../../assets/images/creas.svg";
import lmnd from "../../assets/images/lmnd.svg";
import youtube from "../../assets/images/youtube.svg";
import uuumLogo from "../../assets/images/logo.svg";
import { CREAS, MUUU, LMND } from "../../constants";

export type IconName =
  | "google"
  | "youtube"
  | "uuumLogo"
  | typeof MUUU
  | typeof CREAS
  | typeof LMND;

type Icons = {
  [key in IconName]: string;
};

export const icons: Icons = {
  [CREAS]: creas,
  [MUUU]: muuu,
  [LMND]: lmnd,
  youtube,
  uuumLogo,
  google,
};

export default icons;
