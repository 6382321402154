import axios, { AxiosResponse } from "axios";
import {
  Terms,
  Login,
  Auth,
  Payments,
  AdSurveys,
  BusinessInvoice,
} from "./types";
import * as types from "./types";
import * as initialResponses from "./initialResponses";
import {
  CreatorsApi,
  CreatorAppearInAdSurveyApi,
  PayeesApi,
  BusinessInvoiceApi,
  RevenuesApi,
  EntriesScoutApi,
  LiveStreamingServicesApi,
  EntryApi,
  SnsLinkagesApi,
  server1,
  server2,
  server3,
  ServerConfiguration,
  createConfiguration,
} from "@uuum/coda-mypage-api-sdk";
import { IsomorphicFetchHttpLibrary } from "./http";

const getServer = (): ServerConfiguration<{}> => {
  switch (process.env.REACT_APP_CODA_API_SERVER_URL) {
    case "http://localhost:19800":
      return server3;
    case "https://api.staging.mypage.uuum.tokyo":
      return server2;
    default:
      return server1;
  }
};
const httpLibrary = new IsomorphicFetchHttpLibrary();
const conf = createConfiguration({
  baseServer: getServer(),
  httpApi: httpLibrary,
});
export const creatorsApi = new CreatorsApi(conf);
export const creatorAppearInAdSurveyApi = new CreatorAppearInAdSurveyApi(conf);
export const payeeApi = new PayeesApi(conf);
export const businessInvoiceApi = new BusinessInvoiceApi(conf);
export const revenuesApi = new RevenuesApi(conf);
export const entriesScoutApi = new EntriesScoutApi(conf);
export const liveStreamingServicesApi = new LiveStreamingServicesApi(conf);
export const entryApi = new EntryApi(conf);
export const snsLinkagesApi = new SnsLinkagesApi(conf);

axios.defaults.baseURL = process.env.REACT_APP_CODA_API_SERVER_URL;
axios.defaults.headers.get["Content-Type"] = "application/json";
axios.defaults.headers.post["Content-Type"] = "application/json";

const options = (params = {}) => ({
  params,
  withCredentials: true,
  headers: {
    Accept: "application/json",
  },
});

const handleRequest = {
  get: <T>(url: string, params = {}) => axios.get<T>(url, options(params)),
  post: <T>(url: string, params = {}) => axios.post<T>(url, params, options()),
  patch: <T>(url: string, params = {}) =>
    axios.patch<T>(url, params, options()),
  delete: <T>(url: string, params = {}) =>
    axios.delete<T>(url, options(params)),
};

// unknownにきちんと型をつけてあげる必要がある。
export const terms = {
  get: (
    params: Terms.ShowParameter
  ): Promise<AxiosResponse<{ id: number; content: string }>> =>
    handleRequest.get<Terms.Content>("/mypage/api/v1/terms", params),
  post: (params: Terms.AgreeParameter): Promise<AxiosResponse<unknown>> =>
    handleRequest.post("/mypage/api/v1/terms/agree_to_terms", params),
};

export const auth = {
  idPasswordSignIn: (params: Login.IdPasswordParams) =>
    handleRequest.post("/mypage/auth/sign_in", params),
  status: (): Promise<AxiosResponse<Auth.Status>> =>
    handleRequest.get("/mypage/api/v1/auth"),
  signOut: (): Promise<AxiosResponse<unknown>> =>
    handleRequest.delete("/mypage/api/v1/auth"),
  debugLogin: (): Promise<AxiosResponse<unknown>> =>
    handleRequest.post("/mypage/auth/debug_login"),
};

export const payments = {
  show: () => handleRequest.get<Payments.Basic>("/mypage/api/v1/creator/payee"),
  update: (params: {}) =>
    handleRequest.patch<Payments.Basic>("/mypage/api/v1/creator/payee", params),
};

export const businesssInvoice = {
  show: () => handleRequest.get("/mypage/api/v1/creator/business_invoice"),
  update: (params: {}) =>
    handleRequest.patch<BusinessInvoice.Basic>(
      "/mypage/api/v1/creator/business_invoice",
      params
    ),
};

export const entries = {
  create: (params: {}) => handleRequest.post("/mypage/api/v1/entries", params),
};

export const adSurveys = {
  show: (params: {}) =>
    handleRequest.get<AdSurveys.Basic>(
      "/mypage/api/v1/creator_appear_in_ad_survey",
      params
    ),
  update: (params: {}) =>
    handleRequest.patch<AdSurveys.Basic>(
      "/mypage/api/v1/creator_appear_in_ad_survey",
      params
    ),
};

export { types, initialResponses };
