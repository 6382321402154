import React, { useState } from "react";
import styled from "styled-components";
import { LabelTypes } from "../../utils/forms";
import SubmitButton from "../atoms/SubmitButton";
interface Props<T> {
  backPreviousProgress: () => void;
  onSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
  formValues: T;
  labels: { [key in keyof T]: string };
  sortFormAttributes: Array<keyof T>;
}

// このanyをLabelsのどれかに制限する
const EntryConfirmation: <T extends LabelTypes>(
  props: Props<T>
) => JSX.Element = <T extends LabelTypes>(props: Props<T>) => {
  const onClickTermsLink = () =>
    window.open("https://www.uuum.co.jp/privacy_policy");
  const onClickPlatformTermsLink = () =>
    window.open("/live_streaming_term/?uuum=mypage");
  // fixme ここを、ライブ配信用に差し替える必要がある(テストも追加)
  const [isSubmitting, setIsSubmitting] = useState(false);

  return (
    <StyledConfirmationForm
      onSubmit={(e) => {
        e.preventDefault();
        setIsSubmitting(true);
        props.onSubmit(e);
        window.setTimeout(() => setIsSubmitting(false), 3000);
      }}
    >
      <StyledConfirmationGroup>
        {
          <div>
            <StyledConfirmationHeader>個人情報</StyledConfirmationHeader>
            <StyledConfirmationList>
              {props.sortFormAttributes.map((key: keyof T, idx) => {
                return (
                  props.formValues[key] && (
                    <StyledConfirmationListItem key={idx + `${key}`}>
                      <StyledConfirmationListItemText
                        subHeader={props.labels[key]}
                      >
                        {props.formValues[key]}
                      </StyledConfirmationListItemText>
                    </StyledConfirmationListItem>
                  )
                );
              })}
            </StyledConfirmationList>
          </div>
        }
      </StyledConfirmationGroup>
      <div>
        <StyledCheckBox>
          <input type="checkbox" value="1" id="confirmation" required />
          <TermsLink onClick={onClickTermsLink}>プライバシーポリシー</TermsLink>
          <label htmlFor="confirmation">に同意する</label>
        </StyledCheckBox>
        <StyledCheckBox props="3.2rem">
          <input type="checkbox" value="2" id="confirmation_2" required />
          <TermsLink onClick={onClickPlatformTermsLink}>
            配信プラットフォーム参加利用規約
          </TermsLink>
          <label htmlFor="confirmation">に同意する</label>
        </StyledCheckBox>
      </div>
      <EditEntry onClick={props.backPreviousProgress}>情報を編集</EditEntry>
      <SubmitButton
        name="commit"
        value="送信"
        backgroundColor="#0074AE"
        dataDisableValue="送信"
        disabled={isSubmitting}
      />
    </StyledConfirmationForm>
  );
};

const StyledConfirmationForm = styled.form`
  display: flex;
  width: 100%;
  max-width: 60rem;
  min-width: 28.8rem;
  align-items: center;
  flex-direction: column;
  margin: 0 auto;

  @media (max-width: 896px) {
    margin-top: 4.8rem;
  }
`;

const StyledConfirmationGroup = styled.div`
  list-style-type: none;
  max-width: 60rem;
  width: 100%;
  margin-bottom: 3.2rem;
`;

const StyledCheckBox = styled.div<{ props?: string }>`
  margin-bottom: ${({ props }): string => (props ? props : "")};
  text-align: left;
`;

const StyledConfirmationHeader = styled.h3`
  max-width: 20.2rem;
  width: 100%;
  height: 1.9rem;
  font-style: normal;
  font-weight: bold;
  font-size: 1.6rem;
  line-height: 1.9rem;
  margin-bottom: 0.4rem;
`;

const StyledConfirmationList = styled.ul`
  list-style-type: none;
  padding-inline-start: 0;
`;

const StyledConfirmationListItem = styled.li`
  position: relative;
  padding-top: 3.6rem;
  border-bottom: 1px solid #ddd;
`;

const StyledConfirmationListItemText = styled.p<{ subHeader?: string }>`
  font-style: normal;
  font-weight: normal;
  font-size: 1.6rem;
  line-height: 1.9rem;
  margin-bottom: 1.1rem;
  color: #111;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  &::before {
    display: block;
    position: absolute;
    content: "${({ subHeader }): string => (subHeader ? subHeader : "")}";
    top: 1.2rem;
    font-style: normal;
    font-weight: normal;
    font-size: 1.4rem;
    line-height: 1.6rem;
    color: #676767;
  }
`;

const EditEntry = styled.span`
  text-align: center;
  font-style: normal;
  font-weight: bold;
  font-size: 1.6rem;
  line-height: 2.3rem;
  color: #0074ae;
  margin-bottom: 2.4rem;

  &:hover {
    filter: opacity(0.8);
    text-decoration: none;
  }
`;

const TermsLink = styled.span`
  color: #0074ae;

  &:hover {
    filter: opacity(0.8);
    text-decoration: none;
  }
`;

export default EntryConfirmation;
