import React, { useEffect } from "react";
import { useSelector } from "react-redux";

import { useDispatch } from "react-redux";
import { types, creator } from "../store";
import { Labels, labels } from "../utils/forms/profile";

import Content from "../components/templates/Content";
import List from "../components/molecules/List";
import Title from "../components/atoms/Title";
import { PARENT_RELATIONSHIP_EN_JA } from "../constants";

interface LinkState {
  attribute: keyof Labels;
}
interface Link {
  pathname: string;
  state: LinkState;
}
interface Profile {
  attribute: string;
  title: string;
  detail: string | number;
  to?: Link;
}

const Profile: React.FC = (): JSX.Element => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(creator.actions[creator.types.ActionTypes.FETCH_INFO]());
  }, [dispatch]);
  const creatorState: creator.types.State = useSelector<
    types.RootState,
    creator.types.State
  >((state) => state.creator);

  const genPropertyDetail = (attribute: keyof Labels): string | number => {
    if (attribute === "parentRelationship")
      return PARENT_RELATIONSHIP_EN_JA[creatorState[attribute]];
    else return creatorState[attribute] || "-";
  };

  const createProperty = (attribute: keyof Labels): Profile => {
    return {
      attribute: attribute,
      title: labels[attribute],
      detail: genPropertyDetail(attribute),
      to: {
        pathname: `/profile/edit/${attribute.replace(
          /([A-Z])/g,
          (s: string) => "_" + s.charAt(0).toLowerCase()
        )}`,
        state: {
          attribute,
        },
      },
    };
  };

  const profileArray: Array<keyof Labels> = [
    "lastName",
    "firstName",
    "lastNameKana",
    "firstNameKana",
    "birthdate",
    "gender",
  ];

  // TODO: リファクタリングしたい...! sakamoto_tよろしく!
  const profileProperties: Profile[] = profileArray
    .map((s: keyof Labels): Profile => createProperty(s))
    .reduce((accum: Profile[], elm: Profile): Profile[] => {
      /**
       * realName
       */
      if (elm.attribute === "firstName") {
        const property: Profile = {
          attribute: "realName",
          title: "お名前",
          detail: `${genPropertyDetail("lastName")} ${genPropertyDetail(
            "firstName"
          )}`,
          to: {
            pathname: `/profile/edit/first_name`,
            state: { attribute: elm.attribute },
          },
        };
        return [...accum, property];
      }
      if (elm.attribute === "lastName") {
        return [...accum];
      }
      /**
       * realNameKana
       */
      if (elm.attribute === "firstNameKana") {
        const property: Profile = {
          attribute: "realNameKana",
          title: "フリガナ",
          detail: `${genPropertyDetail("lastNameKana")} ${genPropertyDetail(
            "firstNameKana"
          )}`,
          to: {
            pathname: `/profile/edit/first_name_kana`,
            state: { attribute: elm.attribute },
          },
        };
        return [...accum, property];
      }
      if (elm.attribute === "lastNameKana") {
        return [...accum];
      }
      return [...accum, elm];
    }, []);

  const contactArray: Array<keyof Labels> = [
    "email",
    "address",
    "phoneNumber",
    "emergencyPhoneNumber",
  ];

  const contactProperties: Profile[] = contactArray
    .map((s: keyof Labels): Profile => createProperty(s))
    .reduce((accum: Profile[], elm: Profile): Profile[] => {
      /**
       * address
       */
      if (elm.attribute === "address") {
        const property: Profile = {
          attribute: elm.attribute,
          title: labels[elm.attribute],
          detail: `〒${genPropertyDetail("postcode")}<br>${genPropertyDetail(
            elm.attribute
          )}`,
          to: {
            pathname: `/profile/edit/${elm.attribute}`,
            state: {
              attribute: elm.attribute,
            },
          },
        };
        return [...accum, property];
      }
      if (elm.attribute === "parentLastName") {
        return [...accum];
      }
      return [...accum, elm];
    }, []);
  // TODO: リファクタリングしたい...! sakamoto_tよろしく!
  const parentsArray: Array<keyof Labels> = [
    "parentLastName",
    "parentFirstName",
    "parentRelationship",
    "parentPhoneNumber",
  ];

  // TODO: リファクタリングしたい...! sakamoto_tよろしく!
  const parentsProperties: Profile[] | false =
    creatorState.age < 20 &&
    parentsArray
      .map((s: keyof Labels): Profile => createProperty(s))
      .reduce((accum: Profile[], elm: Profile): Profile[] => {
        /**
         * realParentName
         */
        if (elm.attribute === "parentFirstName") {
          const property: Profile = {
            attribute: "realParentName",
            title: "保護者お名前",
            detail: `${genPropertyDetail("parentLastName")} ${genPropertyDetail(
              "parentFirstName"
            )}`,
            to: {
              pathname: `/profile/edit/parent_first_name`,
              state: { attribute: elm.attribute },
            },
          };
          return [...accum, property];
        }
        if (elm.attribute === "parentLastName") {
          return [...accum];
        }
        return [...accum, elm];
      }, []);

  return (
    <Content title="個人情報">
      <List items={profileProperties} margin="0 0 4.8rem" />

      <section>
        <Title as="h2">連絡先</Title>
        <List items={contactProperties} margin="0 0 4.8rem" />
      </section>

      {parentsProperties && (
        <section>
          <Title as="h2">保護者</Title>
          <List items={parentsProperties} />
        </section>
      )}
    </Content>
  );
};

export default Profile;
