import React from "react";
import styled from "styled-components";

interface Props {
  name: string;
  value: string;
  backgroundColor?: string;
  dataDisableValue?: string;
  disabled?: boolean;
}

const SubmitButton: React.FC<Props> = (props: Props) => {
  return (
    <StyledSubmitButton
      type="submit"
      name={props.name}
      value={props.value}
      backgroundColor={props.backgroundColor}
      data-disable-with={props.dataDisableValue}
      disabled={props.disabled}
    >
      {props.value}
    </StyledSubmitButton>
  );
};

const StyledSubmitButton = styled.button<{ backgroundColor?: string }>`
  width: 100%;
  max-width: 34rem;
  padding: 0;
  text-align: center;
  line-height: 4.4rem;
  font-size: 1.6rem;
  font-weight: bold;
  background-color: ${({ backgroundColor }): string =>
    backgroundColor || "#3e94be"};
  color: #fff;
  border: none;
  border-radius: 2.2rem;
  text-decoration: none;
  box-shadow: 0 0.2rem 1.2rem rgba(220, 220, 220, 0.5);
  outline: none;
  cursor: pointer;
  appearance: none;
  transition: filter 0.2s ease-in-out;

  &:hover {
    filter: opacity(0.8);
    text-decoration: none;
  }

  &:focus {
    outline: none;
  }
`;

export default SubmitButton;
