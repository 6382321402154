import { useState, useEffect } from "react";
import { FormItemProps } from "../components/molecules/FormItemList";
import { Labels, getLabel } from "../utils/forms/scoutentry";

export function useScoutEntryMinorForm(entry: Labels): FormItemProps<Labels> {
  const [status, setStatus] = useState<FormItemProps<Labels>>([]);

  useEffect(() => {
    const formItem: FormItemProps<Labels> = [
      {
        formItemProps: {
          type: "text",
          placeholder: "保護者名(姓)",
          name: "parentLastName",
          defaultValue: entry.parentLastName,
        },
        field: "input",
        label: getLabel("parentLastName"),
      },
      {
        formItemProps: {
          type: "text",
          placeholder: "保護者名(名)",
          name: "parentFirstName",
          defaultValue: entry.parentFirstName,
        },
        field: "input",
        label: getLabel("parentFirstName"),
      },
      {
        formItemProps: {
          type: "text",
          placeholder: "保護者電話番号",
          name: "parentPhoneNumber",
          defaultValue: entry.parentPhoneNumber,
        },
        field: "input",
        label: getLabel("parentPhoneNumber"),
      },
      {
        formItemProps: {
          options: [
            { value: "mother", text: "母" },
            { value: "father", text: "父" },
            { value: "other", text: "その他" },
          ],
          name: "parentRelationship",
          defaultValue: entry.parentRelationship,
        },
        field: "select",
        label: "保護者の続柄",
      },
    ];
    setStatus(formItem);
  }, [entry]);

  return status;
}
