import React from "react";
import styled from "styled-components";

interface Props {
  children: React.ReactNode;
  as?: any;
  to?: string;
  name?: string;
  href?: string;
  onClick?: (e: React.FormEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  type?: string;
  margin?: string;
}

const defaultProps = {
  as: "button",
  type: "default",
};

const Button: React.FC<Props> = (props: Props) => {
  return (
    <StyledButton
      as={props.as}
      to={props.to}
      href={props.href}
      onClick={props.onClick}
      disabled={props.disabled}
      type={props.type}
      margin={props.margin}
      className="button"
      data-testid="button"
      name={props.name}
    >
      {props.children}
    </StyledButton>
  );
};

Button.defaultProps = defaultProps;

const StyledButton = styled.a<Pick<Props, "type" | "margin">>`
  display: block;
  width: 100%;
  max-width: 34rem;
  padding: 0;
  margin: ${({ margin }): string | number => (margin ? margin : 0)};
  text-align: center;
  line-height: 4.4rem;
  font-size: 1.6rem;
  font-weight: bold;
  color: #676767;
  background-color: #fff;
  border: none;
  border-radius: 2.2rem;
  text-decoration: none;
  box-shadow: 0 0.2rem 1.2rem rgba(220, 220, 220, 0.5);
  outline: none;
  cursor: pointer;
  appearance: none;
  transition: filter 0.2s ease-in-out;

  ${(props): string =>
    props.type === "info" ? `background-color: #3e94be; color: #fff;` : ``}

  ${(props): string =>
    props.type === "success" ? `background-color: #00a65a; color: #fff;` : ``}

  ${(props): string =>
    props.type === "entry" ? `background-color: #0074AE; color: #fff;` : ``}

  ${(props): string =>
    props.type === "google"
      ? `fontfamily: "Roboto", "游ゴシック体", YuGothic, sans-serif;`
      : ``}

  &:hover {
    filter: opacity(0.8);
    text-decoration: none;
  }

  &:focus {
    outline: none;
  }

  &:disabled {
    padding: 0;
    border-radius: 2.2rem;
    background-color: #0e8eb942;
    color: #fff;
    pointer-events: none;
    box-shadow: 0 0.2rem 1.2rem 0 rgba(220, 220, 220, 0.5);
  }

  > i {
    margin-right: 0.8rem;
    font-size: $icon_font_md;
  }

  > svg {
    width: 2.4rem;
    height: 2.4rem;
    margin-right: 2.4rem;
  }
`;

export default Button;
