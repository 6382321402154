import React from "react";
import styled from "styled-components";

interface Props {
  options: Options[];
  onChange: (event: React.FormEvent<HTMLSelectElement>) => void;
  defaultValue?: string | number;
  position?: string;
  top?: string;
  right?: string;
  bottom?: string;
  left?: string;
}

interface Options {
  value: string | number;
  text: string | number;
}

const SelectBox: React.FC<Props> = (props: Props) => {
  return (
    <StyledSelectBox
      position={props.position}
      top={props.top}
      right={props.right}
      bottom={props.bottom}
      left={props.left}
    >
      <select
        defaultValue={props.defaultValue}
        onChange={(e: React.FormEvent<HTMLSelectElement>): void =>
          props.onChange(e)
        }
      >
        {props.options.map((item, index) => (
          <option key={index} value={item.value}>
            {item.text}
          </option>
        ))}
      </select>
    </StyledSelectBox>
  );
};

const StyledSelectBox = styled.div<
  Pick<Props, "position" | "top" | "right" | "bottom" | "left">
>`
  display: inline-block;
  overflow: hidden;
  text-align: center;
  position: ${({ position }): string => position || "relative"};
  top: ${({ top }): string => top || ""};
  right: ${({ right }): string => right || ""};
  border-radius: 5rem;
  background: #fff;
  box-shadow: 0 0.2rem 0.8rem 0 rgba(0, 0, 0, 0.05);

  > select {
    width: 100%;
    cursor: pointer;
    text-indent: 0.001rem;
    text-overflow: ellipsis;
    border: none;
    outline: none;
    background: transparent;
    background-image: none;
    box-shadow: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    line-height: 3.2rem;
    padding-right: 3.6rem;
    padding-left: 1.6rem;
    font-size: 1.4rem;
  }

  &::after {
    content: "";
    display: block;
    position: absolute;
    top: 1rem;
    right: 1.6rem;
    width: 0.8rem;
    height: 0.8rem;
    -webkit-transform: rotate(135deg);
    transform: rotate(135deg);
    padding: 0;
    border-top: 0.2rem solid #676767;
    border-right: 0.2rem solid #676767;
    pointer-events: none;
  }
`;

export default SelectBox;
