import React from "react";
import { useDispatch } from "react-redux";
import { Route, Redirect } from "react-router-dom";
import { creator } from "../store";
import { useCreasUserRegistrationCheck } from "../hooks/CreasUserRegistrationCheck";
import { useAuth } from "../hooks/Auth";

function PrivateRoute({ component: Component, ...rest }: any): JSX.Element {
  const dispatch = useDispatch();
  const { data: authState, isLoading: authIsLoading } = useAuth();
  const creasUserRegistration = useCreasUserRegistrationCheck();

  if (authIsLoading || creasUserRegistration.isLoading)
    return <div data-testid="dummyComponent" />;

  if (authState.isLogin)
    dispatch(creator.actions[creator.types.ActionTypes.FETCH_INFO]());

  return (
    (!authState.isLogin && <Redirect to={"/sign_in"} />) ||
    (!authState.isAgreedTerms && <Redirect to={"/terms/agree"} />) ||
    (!creasUserRegistration.isProfileFilled && (
      <Redirect to={"/users/creas/first_registration"} />
    )) || (
      <Route {...rest} render={(): JSX.Element => <Component {...rest} />} />
    )
  );
}

export default PrivateRoute;
