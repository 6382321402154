import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";

import FormItemList, {
  Props as FormItemListProps,
} from "../../components/molecules/FormItemList";
import EntryConfirmation from "../../components/molecules/NewEntryConfirmation";
import EntryComplete from "../../components/molecules/EntryComplete";
import ProgressBar from "../../components/molecules/ProgressBar";
import SubmitButton from "../../components/atoms/SubmitButton";
import Button from "../../components/atoms/Button";
import Form from "../../components/molecules/Form";
import Modal from "../../components/templates/Modal";
import Image from "../../components/organisms/Image";

import {
  Labels,
  formRules,
  labels,
  sortFormAttributes,
} from "../../utils/forms/liveStreamingEntry";
import { entryApi, liveStreamingServicesApi } from "../../utils/ajax";
import { toastError } from "../../utils/appearError";
import { useLiveStreamingEntryFormItems } from "../../hooks/LiveStreamingEntryFormItems";
import { useOauthAttributes } from "../../hooks/OauthAttributes";
import { useGetOauthToken } from "../../hooks/GetOauthToken";

interface Google {
  name: string;
  id: string;
  email: string;
}

export interface EntryInfoState {
  progress: number;
  google: Google;
  entry: Labels;
  liveStreamingServices: Array<{ id: string; name: string }>;
  modalOpen: boolean;
}

const defaultEntryInfoState: EntryInfoState = {
  progress: 1,
  google: {
    id: "",
    name: "",
    email: "",
  },
  entry: {
    firstName: "",
    lastName: "",
    firstNameKana: "",
    lastNameKana: "",
    address: "",
    gender: "",
    googleEmail: "",
    postcode: "",
    birthdate: "",
    selfAppeal: "",
    platformMasterId: "",
    platformUrl: "",
    email: "",
    name: "",
  },
  liveStreamingServices: [],
  modalOpen: false,
};

const provider = (): "line" | "google" => {
  const query = window.location.search;
  const params = new URLSearchParams(query);
  const provider = params.get("provider");
  if (!provider) return "google";

  return "line";
};

const UID_KEY = "uid";
const EMAIL_KEY = "email";
const uidStorage = (): string => {
  let result = "";
  const uid = sessionStorage.getItem(UID_KEY);
  if (uid) {
    result = uid;
  }
  return result;
};
const emailStorage = (): string => {
  let result = "";
  const email = sessionStorage.getItem(EMAIL_KEY);
  if (email) {
    result = email;
  }
  return result;
};
const inflowRoute = (): "web" | "liny" => {
  const query = window.location.search;
  const params = new URLSearchParams(query);
  const provider = params.get("provider");
  const friendid = params.get("friendid");
  if (!provider) return "web";
  if (!friendid) return "web";
  return "liny";
};

const redirectPath = (inflowRoute: string, serviceName: string): string => {
  let lp = "";
  if (inflowRoute === "web") return "";

  if (serviceName === "LINE LIVE") {
    lp = `${process.env.REACT_APP_LINY_LP_LINE_LIVE}`;
  } else {
    lp = `${process.env.REACT_APP_LINY_LP_POCOCHA}`;
  }
  return `${
    process.env.REACT_APP_LINY_ENTRY_URL
  }&lp=${lp}&var_809887=${uidStorage()}`;
};

const LiveStreaming: React.FC = (): JSX.Element => {
  const history = useHistory();
  const [entryInfoState, setEntryInfoState] = useState<EntryInfoState>(
    defaultEntryInfoState
  );
  const { register, errors, handleSubmit, watch } = useForm();
  const watchedPlatformMasterId = watch("platformMasterId");
  const { token, isValidToken } = useGetOauthToken(provider());
  const { email, uid } = useOauthAttributes(provider(), token, isValidToken);

  if (uid) {
    sessionStorage.setItem(UID_KEY, uid);
  }
  if (email) {
    sessionStorage.setItem(EMAIL_KEY, email);
  }

  useEffect(() => {
    const updateServices = async () => {
      const res = await liveStreamingServicesApi.getLiveStreamingServices();

      setEntryInfoState((prevState) => {
        return {
          ...prevState,
          liveStreamingServices: res.liveStreamingServices,
        };
      });
    };

    updateServices();

    return () => undefined;
  }, [history]);

  const getServiceName = (platformMasterId: string) =>
    entryInfoState.liveStreamingServices.find(
      (service) => `${service.id}` === `${platformMasterId}`
    )?.name;

  const formItems = useLiveStreamingEntryFormItems(
    entryInfoState.entry,
    provider(),
    entryInfoState.liveStreamingServices,
    getServiceName(watchedPlatformMasterId)
  );

  const multiFormProps: FormItemListProps<Labels> = {
    rules: formRules,
    formProps: formItems,
    errors,
    register,
  };

  const updateEntry = (arg: EntryInfoState): void => {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    setEntryInfoState(arg);
  };

  const onCompleteSubmitFunc = (data: Labels): void => {
    if (provider() === "google") data["email"] = email;
    updateEntry({
      ...entryInfoState,
      entry: {
        ...entryInfoState.entry,
        ...data,
        googleEmail: entryInfoState.google.email,
      },
      progress: 2,
    });
  };
  const onCompleteForm = (e: React.FormEvent<HTMLFormElement>): Promise<void> =>
    handleSubmit((data: any) => onCompleteSubmitFunc(data))(e);

  const onSubmit = async (
    e: React.FormEvent<HTMLFormElement>
  ): Promise<void> => {
    e.preventDefault();
    const query = window.location.search;
    const params = new URLSearchParams(query);
    try {
      await entryApi.postLiveStreamingEntry({
        entry: {
          ...entryInfoState.entry,
          platformMasterId: Number(entryInfoState.entry.platformMasterId),
          entryRoute: "network",
          inflowRoute: inflowRoute(),
          friendId: params.get("friendid") || "",
          authUid: uidStorage(),
          authEmail: emailStorage(),
          authProvider: provider(),
        },
      });
      const path = redirectPath(
        inflowRoute(),
        String(getServiceName(entryInfoState.entry.platformMasterId))
      );
      sessionStorage.clear();
      if (path) {
        window.location.href = path;
      } else {
        updateEntry({ ...entryInfoState, progress: 3 });
      }
    } catch (err) {
      if (err.code === 422)
        toastError(
          err.body?.message?.replace("バリデーションに失敗しました: ", "")
        );
      else if (err.code === 409) toastError(err.body);
      else toastError("エラーが発生しました");
    }
  };

  const steps = ["情報入力", "情報確認", "完了"];

  const getImages = () => {
    if (getServiceName(watchedPlatformMasterId) === "LINE LIVE")
      return "how_to_get_line_live_account_url.png";

    return "how_to_get_pococha_account_url.png";
  };

  const remarks = {
    platformUrl: (
      <div>
        {(getServiceName(watchedPlatformMasterId) === "LINE LIVE" ||
          getServiceName(watchedPlatformMasterId) === "Pococha") && (
          <p>
            URLの取得方法は
            <StyledNotifyLink
              onClick={() =>
                setEntryInfoState({ ...entryInfoState, modalOpen: true })
              }
            >
              こちら
            </StyledNotifyLink>
          </p>
        )}
        {getServiceName(watchedPlatformMasterId) === "LINE LIVE" && (
          <p>
            ※コピーをしてもURLが出てこない場合は、メモ帳などに一度貼り付けて、URLをご確認ください。
          </p>
        )}
      </div>
    ),
  };

  return (
    <Content>
      {entryInfoState.modalOpen && (
        <Modal>
          <ImageWrapper
            onClick={() =>
              setEntryInfoState({ ...entryInfoState, modalOpen: false })
            }
          >
            <Image imageKeys={getImages()} />
            <Button
              name="closeModal"
              as="button"
              margin="1rem auto"
              onClick={() =>
                setEntryInfoState({ ...entryInfoState, modalOpen: false })
              }
              type="entry"
            >
              画像を閉じる
            </Button>
          </ImageWrapper>
        </Modal>
      )}
      <ProgressBarWrapper>
        <ProgressBar steps={steps} progress={entryInfoState.progress} />
      </ProgressBarWrapper>
      {entryInfoState.progress === 1 && (
        <StyledFormWrapper>
          <Form onSubmit={onCompleteForm} align="center">
            <FormItemList<Labels>
              {...multiFormProps}
              remarks={remarks}
            ></FormItemList>
            <SubmitButton
              name="commit"
              value="次へ"
              backgroundColor="#0074AE"
              dataDisableValue="次へ"
            />
          </Form>
        </StyledFormWrapper>
      )}
      {entryInfoState.progress === 2 && (
        <EntryConfirmation<Labels>
          backPreviousProgress={(): void =>
            updateEntry({ ...entryInfoState, progress: 1 })
          }
          onSubmit={onSubmit}
          formValues={{
            ...entryInfoState.entry,
            name: entryInfoState.entry.name || "",
            platformMasterId:
              getServiceName(entryInfoState.entry.platformMasterId) || "",
          }}
          labels={labels}
          sortFormAttributes={sortFormAttributes}
        />
      )}
      {entryInfoState.progress === 3 && (
        <EntryComplete
          title={
            <div>
              エントリー
              <br />
              ありがとうございました
            </div>
          }
          notice={
            <div>
              10営業日以内に
              <br />
              審査結果をメールでお知らせいたします
            </div>
          }
        />
      )}
    </Content>
  );
};

export default LiveStreaming;

const Content = styled.main`
  position: relative;
  display: inline-block;
  min-height: calc(100vh - 11.2rem);
  padding: 6.4rem 1.6rem;

  @media (max-width: 896px) {
    margin: 0;
    min-height: calc(100vh - 12.4rem);
    padding: 3.2rem 1.6rem;
  }
`;

const ProgressBarWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 0 auto;
  align-items: center;
  padding-inline-start: 0;
`;

const StyledFormWrapper = styled.div`
  width: 100%;
  margin: 0 auto;
  max-width: 60rem;
  min-width: 28rem;
  align-items: center;
`;

const StyledNotifyLink = styled.span`
  color: blue;
  border-bottom: 1px solid blue;
`;

const ImageWrapper = styled.div`
  margin: 3rem;
  text-align: center;
`;
