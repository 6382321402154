import React, { Component } from "react";
import { Line } from "react-chartjs-2";

interface LineChartProps {
  labels: string[];
  values: number[];
  title: string;
}

export default class LineChart extends Component<LineChartProps> {
  chartData = (labels: string[], values: number[]): object => {
    return {
      labels: labels,
      datasets: [
        {
          label: null,
          fill: false,
          lineTension: 0.1,
          backgroundColor: "rgba(75,192,192,0.4)",
          borderColor: "rgba(75,192,192,1)",
          borderCapStyle: "butt",
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: "miter",
          pointBorderColor: "rgba(75,192,192,1)",
          pointBackgroundColor: "#fff",
          pointBorderWidth: 1,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: "rgba(75,192,192,1)",
          pointHoverBorderColor: "rgba(220,220,220,1)",
          pointHoverBorderWidth: 2,
          pointRadius: 1,
          pointHitRadius: 10,
          data: values,
        },
      ],
    };
  };
  chartOptions = (title: string): object => {
    return {
      title: {
        display: false,
        text: title,
      },
      legend: { display: false },
      scales: {
        yAxes: [
          {
            ticks: {
              beginAtZero: true,
            },
          },
        ],
      },
    };
  };
  render(): JSX.Element {
    return (
      <Line
        // ref="chart"
        data={this.chartData(this.props.labels, this.props.values)}
        options={this.chartOptions(this.props.title)}
      />
    );
  }
}
