import { RegisterOptions } from "react-hook-form";

type LabelKeys = keyof Labels;

type LabelKeyRegisterOptions = {
  [key in LabelKeys]: RegisterOptions;
};

export interface Labels {
  email: string;
  name: string;
  googleEmail?: string;
  platformMasterId: string;
  platformUrl: string;
  selfAppeal: string;
  firstName: string;
  lastName: string;
  firstNameKana: string;
  lastNameKana: string;
  address: string;
  gender: string;
  postcode: string;
  birthdate: string;
}

const labels: { [key in keyof Labels]: string } = {
  platformMasterId: "プラットフォーム",
  platformUrl: "審査対象アカウントURL",
  name: "クリエイター名",
  selfAppeal: "自己PR",
  firstName: "お名前 (名)",
  lastName: "お名前 (姓)",
  firstNameKana: "フリガナ (名)",
  lastNameKana: "フリガナ (姓)",
  email: "メールアドレス",
  address: "住所",
  gender: "性別",
  postcode: "郵便番号",
  birthdate: "生年月日",
};

// 追加したい物をkey_valueで追加する。
const validations: LabelKeyRegisterOptions = {
  name: {
    maxLength: {
      value: 191,
      message: "191字以内で入力してください",
    },
  },
  platformMasterId: {
    required: true,
  },
  platformUrl: {
    maxLength: {
      value: 2000,
      message: "正しいURLを入力してください",
    },
    pattern: {
      value:
        /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_.~#?&//=]*)/,
      message: "正しい形式で入力してください",
    },
  },
  firstName: {
    maxLength: {
      value: 191,
      message: "191字以内で入力してください",
    },
  },
  lastName: {
    maxLength: {
      value: 191,
      message: "191字以内で入力してください",
    },
  },
  firstNameKana: {
    maxLength: {
      value: 191,
      message: "191字以内で入力してください",
    },
    pattern: {
      value: /^[\u30a0-\u30ff　 ー－・]*$/,
      message: "正しい形式で入力してください",
    },
  },
  lastNameKana: {
    maxLength: {
      value: 191,
      message: "191字以内で入力してください",
    },
    pattern: {
      value: /^[\u30a0-\u30ff　 ー－・]*$/,
      message: "正しい形式で入力してください",
    },
  },
  googleEmail: {
    pattern: {
      // gmailにする
      value: /^[\w+\-.]+@[a-z\d\-.]+\.[a-z]+$/,
      message: "正しい形式で入力してください",
    },
  },
  email: {
    pattern: {
      // gmailにする
      value: /^[\w+\-.]+@[a-z\d\-.]+\.[a-z]+$/,
      message: "正しい形式で入力してください",
    },
  },
  address: {
    maxLength: {
      value: 191,
      message: "入力してください",
    },
  },
  postcode: {
    maxLength: {
      value: 7,
      message: "不正な入力値です",
    },
    minLength: {
      value: 7,
      message: "不正な入力値です",
    },
    pattern: /^[1-9]*/,
  },
  gender: {
    required: "選択して下さい",
    pattern: {
      value: /男性|女性|その他/,
      message: "選択肢から選択して下さい",
    },
  },
  birthdate: {
    required: "2020/01/01のように入力して下さい",
    pattern: /\d{4}\/\d{2}\/\d{2}/,
    validate: (value: string) =>
      new Date(value).toString() !== "Invalid Date" ||
      "正しい日付を入力してください",
  },
  selfAppeal: {
    required: false,
  },
};

const formRules = (name: keyof Labels) => {
  const validation = validations[name];

  return { required: "入力してください", ...validation };
};

export const sortFormAttributes: Array<keyof Labels> = [
  "platformMasterId",
  "platformUrl",
  "name",
  "lastName",
  "firstName",
  "lastNameKana",
  "firstNameKana",
  "birthdate",
  "gender",
  "postcode",
  "address",
  "googleEmail",
  "email",
  "selfAppeal",
];

export { formRules, labels };
