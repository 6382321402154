import React from "react";
import { useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";

import { creatorsApi } from "../utils/ajax";
import { creator, types } from "../store";
import {
  Labels,
  formRules,
  labels,
  genFormItemProp,
} from "../utils/forms/profile";

import ContentTemplate from "../components/templates/Content";
import Form from "../components/molecules/Form";
import SubmitButton from "../components/atoms/SubmitButton";
import FormItemList, {
  Props as FormItemListProps,
} from "../components/molecules/FormItemList";

interface LocationState {
  attribute: keyof Labels;
}

interface Location {
  state: LocationState;
}

interface Props {
  location: Location;
}

interface ParamsProps {
  pathAttribute?: string;
}

interface Breadcrumb {
  title: string;
  to: string;
}

const ProfileEdit: React.FC<Props> = (props: Props) => {
  const history = useHistory();
  const { pathAttribute } = useParams<ParamsProps>();
  const { register, errors, handleSubmit } = useForm<Labels>();
  const creatorState: creator.types.State = useSelector<
    types.RootState,
    creator.types.State
  >((state) => state.creator);
  const attribute = props.location.state.attribute;

  if (!attribute || pathAttribute === undefined) {
    history.push("/profile");
    return <div data-testid="dummyComponent"></div>;
  }

  const breadcrumbItem: Breadcrumb = {
    title: "個人情報",
    to: "/profile",
  };

  let title = "";
  let formProps = [];
  switch (attribute) {
    case "firstName":
      title = "お名前";
      formProps = [
        genFormItemProp("lastName", creatorState["lastName"]),
        genFormItemProp("firstName", creatorState["firstName"]),
      ];
      break;
    case "firstNameKana":
      title = "フリガナ";
      formProps = [
        genFormItemProp("lastNameKana", creatorState["lastNameKana"]),
        genFormItemProp("firstNameKana", creatorState["firstNameKana"]),
      ];
      break;
    case "parentFirstName":
      title = "保護者お名前";
      formProps = [
        genFormItemProp("parentLastName", creatorState["parentLastName"]),
        genFormItemProp("parentFirstName", creatorState["parentFirstName"]),
      ];
      break;
    case "address":
      title = "住所";
      formProps = [
        genFormItemProp("postcode", creatorState["postcode"]),
        genFormItemProp("address", creatorState["address"]),
      ];
      break;
    default:
      title = labels[attribute];
      formProps = [genFormItemProp(attribute, creatorState[attribute])];
      break;
  }

  const multiFormProps: FormItemListProps<Labels> = {
    rules: formRules,
    errors,
    register,
    formProps: formProps,
  };

  const onSubmitFunc = async (payload: Labels): Promise<void> => {
    try {
      await creatorsApi.patchCreators(payload);
      history.push("/profile");
    } catch (err) {
      console.log(err);
    }
  };

  const onSubmit = (e: React.FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault();
    return handleSubmit((data: Labels) => onSubmitFunc(data))(e);
  };

  return (
    <ContentTemplate title={title} breadcrumb={breadcrumbItem}>
      <Form onSubmit={onSubmit}>
        <FormItemList<Labels> {...multiFormProps}></FormItemList>
        <SubmitButton name="commit" value="保存" dataDisableValue="保存" />
      </Form>
    </ContentTemplate>
  );
};

export default ProfileEdit;
