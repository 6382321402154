import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import Content from "../components/templates/Content";
import { toastError, toastSuccess } from "../utils/appearError";
import { snsLinkagesApi } from "../utils/ajax";

const SnsLinkages: React.FC = (): JSX.Element => {
  const location = useLocation();

  const handleInstagramGraphAuth = async () => {
    try {
      await snsLinkagesApi.instagramGraphAuth();
    } catch (err) {
      toastError("通信エラーにより連携できませんでした");
      throw err;
    }
  };

  const showSnsLinkageResultMessage = () => {
    const searchParams = new URLSearchParams(location.search);
    const provider = searchParams.get("provider");
    const isSuccess = searchParams.get("is_success");

    if (provider === null || isSuccess === null) return;

    const permittedProviders = ["Instagram"];

    if (permittedProviders.includes(provider) && isSuccess === "true") {
      toastSuccess(`${provider}との連携に成功しました`);
    } else if (permittedProviders.includes(provider) && isSuccess === "false") {
      toastError(`${provider}との連携に失敗しました`);
    }
  };

  useEffect(() => {
    showSnsLinkageResultMessage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Content title="SNS連携">
      <StyledSnsLinkageButtonsContainer>
        <StyledSnsLinkageButtonRow>
          <StyledSnsName>Instagram</StyledSnsName>
          <StyledInstagramLinkageButton onClick={handleInstagramGraphAuth}>
            <i className="fab fa-instagram"></i> <span>連携する</span>
          </StyledInstagramLinkageButton>
        </StyledSnsLinkageButtonRow>
      </StyledSnsLinkageButtonsContainer>
    </Content>
  );
};

const StyledSnsLinkageButtonsContainer = styled.div`
  width: 75%;
  background-color: #f8f8f8;
  padding: 1.6rem;
  margin: 0 auto;
`;

const StyledSnsLinkageButtonRow = styled.div`
  border-radius: 0.8rem;
  background-color: #fff;
  padding: 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledSnsName = styled.span`
  font-weight: bold;
`;

const StyledInstagramLinkageButton = styled.a`
  color: #fff;
  border-radius: 0.8rem;
  position: relative;
  display: inline-block;
  height: 50px;
  width: 140px;
  margin-left: auto;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  line-height: 50px;
  background: linear-gradient(135deg, #427eff 0%, #f13f79 70%) no-repeat;
  overflow: hidden;
  text-decoration: none;
  cursor: pointer;
  user-select: none;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
        15deg,
        #ffdb2c,
        rgb(249, 118, 76) 25%,
        rgba(255, 77, 64, 0) 50%
      )
      no-repeat;
  }

  .fa-instagram {
    color: #fff;
    font-size: 26px;
    position: relative;
    top: 4px;
  }

  span {
    color: #fff;
    display: inline-block;
    position: relative;
    margin-left: 10px;
  }
`;

export default SnsLinkages;
