import React from "react";
import styled from "styled-components";

interface Props {
  label: string;
  minTitle: string;
  maxTitle: string;
  name: string;
  value: Array<string>;
  state: React.SetStateAction<string>;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const RadioGridRow: React.FC<Props> = (props: Props): JSX.Element => {
  const { label, minTitle, maxTitle, name, value, state, onChange }: Props =
    props;
  return (
    <StyledRow>
      <StyledTitle>{label}</StyledTitle>
      <StyledMinTitle>{minTitle}</StyledMinTitle>
      {value.map((item, index) => (
        <StyledCol key={`${name}+${item}`}>
          <input
            type="radio"
            name={name}
            value={item}
            checked={item === state}
            onChange={onChange}
          />
        </StyledCol>
      ))}
      <StyledMaxTitle>{maxTitle}</StyledMaxTitle>
    </StyledRow>
  );
};

const StyledRow = styled.tr`
  height: 5rem;
  border-bottom: 1px solid #e6e6e6;
`;

const StyledCol = styled.td`
  width: 8%;
  text-align: center;
  background-color: #f5f5f5;
`;

const StyledTitle = styled.td`
  font-size: 1.3rem;
  padding: 1rem;
  width: 30%;
  text-align: left;
  border-right: 1px solid #e6e6e6;
`;

const StyledMinTitle = styled.td`
  font-size: 1.1rem;
  width: 15%;
  text-align: center;
`;

const StyledMaxTitle = styled.td`
  font-size: 1.1rem;
  width: 15%;
  text-align: center;
`;

export default RadioGridRow;
