export const API_ENDPOINT_URL =
  process.env.REACT_APP_CODA_API_SERVER_URL ||
  "https://staging.coda.uuum.tokyo";

export const CREAS = "CREAS";
export const MUUU = "MUUU OndemandApp";
export const LMND = "LMND";
export const POCOCHA = "Pococha";
export const LINE_LIVE = "LINE LIVE";

type ServiceNameKey = typeof CREAS | typeof MUUU | typeof LMND;

type ExternalServiceNames = {
  [key in ServiceNameKey]: string;
};

export const EXTERNAL_SERVICE_NAMES: ExternalServiceNames = {
  [CREAS]: "CREAS",
  [MUUU]: "MUUU",
  [LMND]: "LMND",
};

export const EXTERNAL_SERVICE_LINKS = {
  [CREAS]: process.env.REACT_APP_CREAS_URL || "https://staging.coda.uuum.tokyo",
  [MUUU]: process.env.REACT_APP_MUUU_URL || "https://staging.coda.uuum.tokyo",
  [LMND]: process.env.REACT_APP_LMND_URL || "https://staging.coda.uuum.tokyo",
};

export const PARENT_RELATIONSHIP_EN_JA = {
  mother: "母親",
  father: "父親",
  other: "その他",
};
