import React from "react";
import styled from "styled-components";
import Content from "../components/templates/Content";

const LiveStreamingTerm: React.FC = (): JSX.Element => {
  return (
    <Content title="配信プラットフォーム参加利用規約">
      <p>
        本利用規約は、UUUM株式会社が運営する「LIVE Network by UUUM」及び「LIVE
        Network by
        UUUM」を通じて提供されるサービスについての利用条件を定めるものです。
        本利用規約に同意いただくことで、本サービスを利用することができます。
      </p>
      <h3>第1条（定義）</h3>
      <p>
        本利用規約において使用する以下の用語は、それぞれ以下に定める意味を有するものとします。
      </p>
      <StyledIndentP>(1)「当社」---UUUM株式会社</StyledIndentP>
      <StyledIndentP>
        (2)「プラットフォーマー」---動画配信プラットフォーマー
      </StyledIndentP>
      <StyledIndentP>
        (3)「対象サービス」---プラットフォーマーが提供する動画配信サービス（当事者間で本利用規約の対象とすることを合意したサービス。）
      </StyledIndentP>
      <StyledIndentP>
        (4)「LIVE Network by
        UUUM」---対象サービスに関して当社が運営するサービスの総称
      </StyledIndentP>
      <StyledIndentP>
        (5)「本サービス」---「LIVE Network by UUUM」及び「LIVE Network by
        UUUM」を通じて提供される一切のサービス
      </StyledIndentP>
      <StyledIndentP>
        (6)「参加希望者」---本サービスへの参加を希望する者
      </StyledIndentP>
      <StyledIndentP>
        (7)「参加者」---
        参加希望者が本利用規約に同意し、本利用規約及び当社が定める方法により、本サービスの提供を申し込み、当社が承認し、参加登録が完了した者
      </StyledIndentP>
      <h3>第2条（サービスの概要）</h3>
      <ol>
        <li>
          参加者は、本利用規約に従って、当社の定める方法に従い、本サービスを利用することができます。
        </li>
        <li>
          当社は、必要に応じて、本サービスに新しいサービスを追加したり、変更したりすることがあり、参加者はそれをあらかじめ承諾した上で本サービスを利用するものとします。
        </li>
        <li>
          参加者は、年齢や利用環境等、当社の定める条件に応じて、当社の定める範囲内で、本サービスを利用するものとします。
        </li>
      </ol>
      <h3>第3条（サービスの参加条件）</h3>
      <ol>
        <li>
          当社は、当社の定めにより、本サービスの機能や利用範囲その他の利用条件を参加者の年齢や利用環境等に応じて設定するものとします。
          参加者は、本サービスを利用する際には、各機能やサービスについて、必ず利用条件を確認し、遵守するものとします。
        </li>
        <li>
          参加者は、本サービスを利用する上で、真実、正確かつ最新の登録情報を設定するものとし、虚偽の情報を掲載してはならないものとします。
        </li>
        <li>
          参加者は、本規約に定めるほか、各プラットフォーマーが本サービスに関して配信に関するノルマ、利用継続の基準、収益発生の基準その他の基準を定める場合には、
          当該定めを遵守するものとします。なお、当社は参加者へ当該定めについての内容の通知を行います。
        </li>
        <li>
          参加希望者が、当社へのエントリー以前に対象サービスにおいて一定数の配信を行っていた場合、プラットフォーマーの定める規約及び仕様により、
          本サービスへの参加ができない場合があります。なお、当該一定数の配信を行っていたアカウントを削除し、
          新たにアカウントを作成することで、本サービスの利用が可能となります。
        </li>
      </ol>
      <h3>第4条（本サービスへの参加）</h3>
      <ol>
        <li>
          参加希望者は、本利用規約の全てに同意した上、本利用規約及び当社が定める方法により本サービスへの参加の申請（以下「参加申請」といいます。）をするものとします。
        </li>
        <li>
          当社は、前項の参加申請があった場合、当社が定める必要な審査、手続き等を経て、当該参加申請を承認するかどうか決定します。
          なお、当社は、当社が定める審査基準に合致しない場合その他当社が不適当と考える場合には、参加者へ理由を開示することなく、当該参加申請を承認しないことができるものとします。
        </li>
        <li>
          当社が参加希望者の参加申請を承認した場合、当社と参加希望者との間で、
          本利用規約を契約内容とする本サービスの利用契約（以下「本利用契約」と言います。）が締結されるものとします。
        </li>
        <li>
          未成年者の方は、親権者の同意のない限り本サービスへの参加をすることができないものとします。
        </li>
      </ol>
      <h3>第5条（退会）</h3>
      <ol>
        <li>
          参加者は、事前に当社へ申し出ることにより、本サービスの利用を終了し、本サービスから退会することができるものとします。
          但し、本サービスのシステムの都合上、退会の完了まで当社所定の時間が必要な場合があり、参加者はこれを承諾するものとします。
        </li>
        <li>
          本サービスの利用の解除後、他社を通じて対象サービスを利用する際はアカウントを作り直す必要がある場合があり、
          参加者はこれを承諾するものとします。
        </li>
      </ol>
      <h3>第6条（対価の支払い）</h3>
      <ol>
        <li>
          当社は、プラットフォーマーから参加者に対して支払われる対象サービスにおける報酬を代理受領する権限を有するものとします。
        </li>
        <li>
          当社は参加者に対して、参加者の対象サービスにおける活動の対価としてプラットフォーマー所定の計算式により算出される金額としてプラットフォーマーから当社が現実に受領した金員×90％（税込）を支払うものとします。
        </li>
        <li>
          施策等により前項に定める他に参加者へ追加報酬が発生した場合、当社は参加者に対して、別途参加者に通知する金員を支払うものとします。
        </li>
        <li>
          参加者は、当社及びその指定する者に対して、前各項に定める対価以外に、名目（出演料、権利移転料等を含み、これらに限られません。）
          の如何を問わず、経済的利益の提供を一切請求することができないものとします。
        </li>
        <li>
          当社は、各月の1日から末日までの期間についての前各項に定める対価及びこれに対する消費税を、翌月末日または翌々月末日（対象サービスによって異なり、当社は参加者に別途支払い期日に関する通知をするものとします。）までに、参加者が指定する金融機関口座に振込むことにより支払うものとします。
          なお、振込手数料は当社の負担とします。
        </li>
        <li>
          前項の定めにかかわらず、プラットフォーマーにおいて参加者の各月の報酬について一定金額を下回った場合はその支払いを翌月以降に繰り越し、報酬額の累計が当該一定額に達したときに支払うこととされている場合、当社の参加者に対する対価の支払いも当該定めにしたがい、前項に定める支払時期の翌月以降に繰り越される場合があることに参加者は同意するものとします。
        </li>
      </ol>
      <h3>第7条（禁止行為）</h3>
      <ol>
        <li>
          参加者は、本サービスの利用にあたり、以下の行為を行ってはならないものとします。
        </li>
      </ol>
      <StyledIndentP>
        (1)
        飲酒・喫煙を伴う配信や過度な露出を伴う配信など、各プラットフォーマーが規約等で禁止する行為
      </StyledIndentP>
      <StyledIndentP>
        (2) 撮影が禁止されている場所や他の方への迷惑となる場所での配信
      </StyledIndentP>
      <StyledIndentP>
        (3)
        当社、プラットフォーマーその他第三者の権利を侵害したり、社会倫理や法令に違反し又は公序良俗に反する内容、形式の配信及びそれらの恐れのある行為
      </StyledIndentP>
      <StyledIndentP>
        (4)
        当社、プラットフォーマー、他の配信者等他者のトラブル、誹謗中傷等の原因となる行為
      </StyledIndentP>
      <StyledIndentP>
        (5)
        本サービスと同様又は類似のサービスを提供する第三者との間で、当該サービスに関する契約、当該事務所との所属・提携等に関する契約を締結し又はそれらの契約に関する交渉等を行う行為
      </StyledIndentP>
      <StyledIndentP>
        (6)
        本利用契約期間中及び本利用契約期間終了後1年の間、本サービスと同様又は類似のサービスを提供し又は対象サービスその他のプラットフォーマーにおける活動者をマネジメントする行為及びこれらを目的とした法人の設立、その設立に関与する行為
      </StyledIndentP>
      <StyledIndentP>
        (7) その他当社又はプラットフォーマーが不適切と認める行為
      </StyledIndentP>
      <h3>第8条（免責）</h3>
      <ol>
        <li>
          参加者が対象サービスに関する規約等に従わないことその他各プラットフォーマーの決定により本サービスを利用できなくなった場合でも、
          当社は参加者に対し、一切責任を負わないものとします。
        </li>
        <li>
          当社は、本サービスにおいて、参加者相互間及び参加者と第三者との間で生じた一切のトラブル（違法又は公序良俗に反する行為の提案、名誉毀損、侮辱、プライバシー侵害、脅迫、誹謗中傷、いやがらせ等）に関して、当社に故意又は重過失がある場合を除き、責任を負わないものとします。
        </li>
        <li>
          当社は、本サービスの利用に起因して参加者に発生した一切の損害について、当社に故意又は重過失がある場合を除き、責任を負わないものとします。
        </li>
      </ol>
      <h3>第9条（対象サービス規約の遵守</h3>
      <ol>
        <li>
          参加者は、本利用規約に定めるほか、当社が通知する対象サービスの利用規約、その他各プラットフォーマーの定める一切の定めを遵守するものとします。
        </li>
        <li>
          対象サービスに関する規約等における対価の内容・支払い条件、配信に関する基準その他の規約内容等に変更があった場合は、
          当社がその旨の通知を行うことにより、当該通知内容に従うことに同意します。
        </li>
        <li>
          参加者が対象サービスに関する規約等に違反したとプラットフォーマーまたは当社が判断した場合、当社は、
          事前に通知・催告することなく直ちに、本サービスの利用停止、利用の解除その他の措置をとることができるものとします。
        </li>
      </ol>
      <h3>第10条（NETWORK利用規約の遵守）</h3>
      <ol>
        <li>
          参加者は、本規約に定めるほか、当社が別途定める当社 NETWORK利用規約（
          <a
            href="https://mypage.uuum.jp/uuum_network_term"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://mypage.uuum.jp/uuum_network_term
          </a>
          ） を遵守するものとします。
        </li>
        <li>
          参加者がNETWORK利用規約に違反したと当社が判断した場合、
          当社は、事前に通知・催告することなく直ちに、本サービスの利用停止、利用の解除その他の措置をとることができるものとします。
        </li>
      </ol>
      <h3>第11条（有効期間）</h3>
      <ol>
        <li>
          本利用契約は、参加者について第4条に基づく登録が完了した日から1年間とします。但し、有効期間満了1ヶ月前までに、更新を拒絶する旨の通知がなされなかった場合には、本利用契約は同一の条件で1年間自動的に更新されるものとし、以後も同様とします。
        </li>
        <li>
          本利用契約は、当該参加者が退会となった日又は本サービスの提供が終了した日のいずれか早い日まで、当社と参加者との間で有効に存続するものとします。
        </li>
      </ol>
      <h3>第12条（本利用規約の改訂）</h3>
      <ol>
        <li>当社は、随時、本利用規約を改定することができるものとします。</li>
        <li>
          当社は、本利用規約を改定しようとする場合、当社所定の方法により随時、参加者に告知するものとします。
        </li>
        <li>
          前項に基づき、本利用規約の改定を告知した日から当社が定める期間内に参加者が退会しない場合又は本規約の改定を告知した日以降に参加者が本サービスを利用した場合、当該参加者は本利用規約の改定に同意したものとみなされ、当該参加者と当社との間で改定後の規約の効力が発生するものとします。
        </li>
        <li>
          参加者は、前項に定める効力発生の時点以降、当該内容の不知又は不承諾を申し立てることはできないものとします。
        </li>
      </ol>
    </Content>
  );
};

const StyledIndentP = styled.p`
  margin-left: 2rem;
`;

// fixme もっといいstyled-componentsとCSSの書き方

export default LiveStreamingTerm;
