import React from "react";
import { Redirect } from "react-router-dom";

const OauthLineCallback: React.FC = (): JSX.Element => {
  const query = window.location.search;
  const params = new URLSearchParams(query);
  const code = params.get("code") || "";
  const state = params.get("state") || "";
  const friendid = params.get("friendid") || "";

  const path = `/entry/live_streaming?code=${code}&state=${state}&friendid=${friendid}&provider=line`;
  return <Redirect to={path} />;
};

export default OauthLineCallback;
