import React, { useState } from "react";
import styled from "styled-components";
import { useForm } from "react-hook-form";
import SubmitButton from "../components/atoms/SubmitButton";
import ProgressBar from "../components/molecules/ProgressBar";
import FormItemList, {
  Props as FormItemListProps,
} from "../components/molecules/FormItemList";
import Form from "../components/molecules/Form";
import EntryConfirmation from "../components/molecules/EntryConfirmation";
import EntryComplete from "../components/molecules/EntryComplete";
import GoogleProfileAuthorization from "../components/molecules/GoogleProfileAuthorization";

import { Labels, formRules, labels } from "../utils/forms/scoutentry";
import { entriesScoutApi } from "../utils/ajax";
import { toastError, toastSuccess } from "../utils/appearError";
import { useParams } from "react-router";
import { useScoutEntryForm } from "../hooks/ScoutEntryFormItems";
import { useScoutEntryPaymentForm } from "../hooks/ScoutEntryPaymentFormItems";
import { useScoutEntryMinorForm } from "../hooks/MinorsFormItems";
interface ParamsProps {
  uuid: string;
}
interface Google {
  name: string;
  id: string;
  email: string;
}
interface EntryInfoState {
  progress: number;
  google: Google;
  entry: Labels;
}

function getAgeFromBirthday(birthday: string | undefined): number | boolean {
  if (birthday === undefined || !birthday.match(/\d{4}\/\d{2}\/\d{1,2}/))
    return false;
  const ymd: Array<string> = birthday.split("/");
  const today = new Date();
  const thisYearBirthDay = new Date(
    today.getFullYear(),
    Number(ymd[1]) - 1,
    Number(ymd[2])
  );
  const age = today.getFullYear() - Number(ymd[0]);
  return today < thisYearBirthDay ? age - 1 : age;
}
// このロジックを外部に切り出すべき＆リファクタ

const EditScoutEntryinfo: React.FC = (): JSX.Element => {
  const { uuid } = useParams<ParamsProps>();
  const [entryInfoState, setEntryInfoState] = useState<EntryInfoState>({
    progress: 1,
    google: {
      id: "",
      name: "",
      email: "",
    },
    entry: {
      name: "",
      firstName: "",
      lastName: "",
      firstNameKana: "",
      lastNameKana: "",
      googleEmail: "",
      address: "",
      gender: "",
      postcode: "",
      birthdate: "",
      parentFirstName: "",
      parentLastName: "",
      parentRelationship: "other",
      parentPhoneNumber: "",
      bankName: "",
      bankCode: "",
      branchName: "",
      branchCode: "",
      accountNo: "",
      accountName: "",
      accountType: "normal",
    },
  });

  const formItems = useScoutEntryForm(
    entryInfoState.google,
    entryInfoState.entry
  );

  const formPaymentItems = useScoutEntryPaymentForm(entryInfoState.entry);

  const minorFormItems = useScoutEntryMinorForm(entryInfoState.entry);

  const { register, errors, handleSubmit, watch } = useForm<Labels>();
  const watchEntryBirthdate = watch("birthdate");
  const isMinor = getAgeFromBirthday(watchEntryBirthdate) < 20;
  const multiFormProps: FormItemListProps<Labels> = {
    rules: formRules,
    formProps: formItems,
    errors,
    register,
  };

  const paymentFormProps: FormItemListProps<Labels> = {
    rules: formRules,
    formProps: formPaymentItems,
    errors,
    register,
  };

  const minorMultiFormProps: FormItemListProps<Labels> = {
    rules: formRules,
    formProps: minorFormItems,
    errors,
    register,
  };

  const updateEntry = (arg: EntryInfoState): void => {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    setEntryInfoState(arg);
  };

  const authorizeGoogleInfoSuccess = (arg: Google) => {
    setEntryInfoState({ ...entryInfoState, progress: 2, google: arg });
  };

  const onSubmitFunc = (data: Labels): void => {
    const entry = { ...data };
    updateEntry({ ...entryInfoState, entry, progress: 3 });
  };

  const onCompleteForm = (e: React.FormEvent<HTMLFormElement>): Promise<void> =>
    handleSubmit((data: Labels) => onSubmitFunc(data))(e);

  const onSubmit = async (
    e: React.FormEvent<HTMLFormElement>
  ): Promise<void> => {
    if (!entryInfoState.google.email || !entryInfoState.entry) return;

    const entry = {
      soloEntry: {
        ...entryInfoState.entry,
        googleId: entryInfoState.google.id,
        googleEmail: entryInfoState.google.email,
      },
    };

    e.preventDefault();

    try {
      await entriesScoutApi.putEntry(uuid, entry);
      toastSuccess("エントリー情報を更新しました。");
      updateEntry({ ...entryInfoState, progress: 4 });
    } catch (err) {
      if (err.code === 404) {
        toastError("URLが正しくないか、送信済みです。");
      } else if (err.code === 422) {
        toastError("更新に失敗しました。営業担当にご連絡下さい。");
      } else {
        toastError("通信エラーにより更新できませんでした。");
        throw err;
      }
    }
  };

  const steps = ["Google認証", "情報入力", "情報確認", "完了"];

  return (
    <Content>
      <ProgressBarWrapper progress={entryInfoState.progress}>
        <ProgressBar steps={steps} progress={entryInfoState.progress} />
      </ProgressBarWrapper>
      {entryInfoState.progress === 1 && (
        <GoogleProfileAuthorization
          successFunc={authorizeGoogleInfoSuccess}
          text={"mypageで使用するアカウントでログインして下さい"}
        />
      )}
      {entryInfoState.progress === 2 && (
        <StyledFormWrapper>
          <Form onSubmit={onCompleteForm} align="center">
            <FormDetailHeader>個人情報</FormDetailHeader>
            <FormItemList<Labels> {...multiFormProps}></FormItemList>
            {isMinor && (
              <FormItemList<Labels> {...minorMultiFormProps}></FormItemList>
            )}
            <FormDetailHeader>口座情報</FormDetailHeader>
            <FormItemList<Labels> {...paymentFormProps}></FormItemList>
            <SubmitButton
              name="commit"
              value="次へ"
              backgroundColor="#0074AE"
              dataDisableValue="次へ"
            />
          </Form>
        </StyledFormWrapper>
      )}
      {entryInfoState.progress === 3 && (
        <EntryConfirmation
          backPreviousProgress={(): void =>
            updateEntry({ ...entryInfoState, progress: 2 })
          }
          onSubmit={onSubmit}
          data={{ values: { entry: entryInfoState.entry }, labels }}
        />
      )}
      {entryInfoState.progress === 4 && (
        <EntryComplete
          title={
            <div>
              入力完了
              <br />
              ありがとうございました
            </div>
          }
          notice={<div>営業より後日ご連絡いたします</div>}
        />
      )}
    </Content>
  );
};

export default EditScoutEntryinfo;

const FormDetailHeader = styled.h2`
  width: 100%;
  margin-bottom: 2.4rem;
  text-align: left;
`;

const Content = styled.main`
  position: relative;
  display: inline-block;
  min-height: calc(100vh - 11.2rem);
  padding: 6.4rem 1.6rem;

  @media (max-width: 896px) {
    margin: 0;
    min-height: calc(100vh - 12.4rem);
    padding: 3.2rem 1.6rem;
  }
`;

const ProgressBarWrapper = styled.div<{ progress: number }>`
  display: flex;
  justify-content: center;
  margin: 0 auto;
  margin-bottom: 2.4rem;
  align-items: center;
  padding-inline-start: 0;

  ${({ progress }) =>
    progress === 1 &&
    `
    padding-top: 28.6rem;
    margin-bottom: 4.8rem;

    @media (max-width: 896px) {
      padding-top: 20.7rem;
    }
  `}
`;

const StyledFormWrapper = styled.div`
  width: 100%;
  margin: 0 auto;
  max-width: 60rem;
  min-width: 28rem;
  align-items: center;
`;
