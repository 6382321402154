import {
  HttpLibrary,
  RequestContext,
  ResponseContext,
} from "@uuum/coda-mypage-api-sdk";
import { from, Observable } from "@uuum/coda-mypage-api-sdk/dist/rxjsStub";
import "whatwg-fetch";

export class IsomorphicFetchHttpLibrary implements HttpLibrary {
  public send(request: RequestContext): Observable<ResponseContext> {
    const method = request.getHttpMethod().toString();
    const body = request.getBody();
    const headers = request.getHeaders();

    const resultPromise = fetch(request.getUrl(), {
      method: method,
      body: body as any,
      // headerにjsonの会話であることを明示する。
      headers: {
        ...headers,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      // cross-originでのcookiesの受け渡しのための設定
      credentials: "include",
    }).then((resp: any) => {
      const headers: { [name: string]: string } = {};
      resp.headers.forEach((value: string, name: string) => {
        headers[name] = value;
      });

      const body = {
        text: () => resp.text(),
        binary: () => resp.blob(),
      };
      return new ResponseContext(resp.status, headers, body);
    });

    return from<Promise<ResponseContext>>(resultPromise);
  }
}
