import { useState, useEffect } from "react";
import * as googleApi from "../utils/google";
import axios from "axios";

interface OauthAttributes {
  uid: string;
  email: string;
  error: boolean;
  isLoading: boolean;
}

export function useOauthAttributes(
  provider: string,
  token: string,
  isValidToken: boolean
): OauthAttributes {
  const [status, setStatus] = useState<OauthAttributes>({
    uid: "",
    email: "",
    error: false,
    isLoading: true,
  });
  useEffect(() => {
    const updateServices = async () => {
      if (provider === "google") {
        const googleInfo = await googleApi.profile(token);

        if (googleInfo) {
          setStatus({
            error: false,
            isLoading: false,
            uid: googleInfo.id,
            email: googleInfo.email,
          });
        } else
          setStatus({
            error: true,
            isLoading: false,
            uid: "",
            email: "",
          });
      } else if (provider === "line") {
        const payload = new URLSearchParams();
        payload.append("id_token", token);
        // TO DO: ここは環境変数にする
        payload.append(
          "client_id",
          `${process.env.REACT_APP_OAUTH_LINE_CLIENT_ID}` || ""
        );
        const headers = {
          "Content-Type": "application/x-www-form-urlencoded",
        };
        try {
          const { data } = await axios.post(
            "https://api.line.me/oauth2/v2.1/verify",
            payload,
            { headers }
          );
          setStatus({
            error: false,
            isLoading: false,
            uid: data.sub,
            email: "",
          });
        } catch {
          setStatus({
            error: true,
            isLoading: false,
            uid: "",
            email: "",
          });
        }
      } else
        setStatus({
          error: true,
          isLoading: false,
          uid: "",
          email: "",
        });
    };

    if (isValidToken) updateServices();
  }, [provider, token, isValidToken]);

  return status;
}
