import { RegisterOptions } from "react-hook-form";

type LabelKeys = keyof Labels;

type LabelKeyRegisterOptions = {
  [key in LabelKeys]: RegisterOptions;
};

export interface Labels {
  name: string;
  firstName: string;
  lastName: string;
  firstNameKana: string;
  lastNameKana: string;
  email: string;
  entryType: string;
  address: string;
  gender: string;
  postcode: string;
  birthdate: string;
  selfAppeal: string;
}

const labels: Labels = {
  name: "クリエイター名",
  firstName: "お名前 (名)",
  lastName: "お名前 (姓)",
  firstNameKana: "フリガナ (名)",
  lastNameKana: "フリガナ (姓)",
  email: "メールアドレス",
  address: "住所",
  gender: "性別",
  postcode: "郵便番号",
  birthdate: "生年月日",
  selfAppeal: "自己アピール",
  entryType: "エントリータイプ",
};

// 追加したい物をkey_valueで追加する。
const validations: LabelKeyRegisterOptions = {
  name: {
    maxLength: {
      value: 191,
      message: "長すぎます",
    },
  },
  firstName: {
    maxLength: {
      value: 191,
      message: "長すぎます",
    },
  },
  lastName: {
    maxLength: {
      value: 191,
      message: "長すぎます",
    },
  },
  firstNameKana: {
    maxLength: {
      value: 191,
      message: "長すぎます",
    },
    pattern: {
      value: /^[\u30a0-\u30ff　 ー－・]*$/,
      message: "正しい形式で入力してください",
    },
  },
  lastNameKana: {
    maxLength: {
      value: 191,
      message: "長すぎます",
    },
    pattern: {
      value: /^[\u30a0-\u30ff　 ー－・]*$/,
      message: "正しい形式で入力してください",
    },
  },
  email: {
    pattern: {
      value: /^[\w+\-.]+@[a-z\d\-.]+\.[a-z]+$/,
      message: "正しい形式で入力してください",
    },
  },
  address: {
    maxLength: {
      value: 191,
      message: "入力してください",
    },
  },
  postcode: {
    maxLength: {
      value: 7,
      message: "不正な入力値です",
    },
    minLength: {
      value: 7,
      message: "不正な入力値です",
    },
    pattern: /^[1-9]*/,
  },
  gender: {
    required: "選択して下さい",
    pattern: {
      value: /男性|女性|その他/,
      message: "選択肢から選択して下さい",
    },
  },
  birthdate: {
    pattern: /\d{4}\/\d{2}\/\d{2}/,
    validate: (value: string) =>
      new Date(value).toString() !== "Invalid Date" ||
      "正確な日付を入力して下さい",
  },
  selfAppeal: {
    required: false,
  },
  entryType: {
    required: false,
  },
};

const formRules = (name: keyof Labels) => {
  const validation = validations[name];

  return { required: "入力してください", ...validation };
};

// この型を制限する
const getLabel = (name: keyof Labels): string => {
  return labels[name];
};

export const sortFormAttributes: Array<keyof Labels> = [
  "name",
  "firstName",
  "lastName",
  "firstNameKana",
  "lastNameKana",
  "email",
  "entryType",
  "address",
  "gender",
  "postcode",
  "birthdate",
  "selfAppeal",
];

export { formRules, getLabel, labels };
