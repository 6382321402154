import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";
import { types, auth } from "../store";

interface Auth {
  isLoading: boolean;
  data: {
    isAgreedTerms: boolean;
    isLogin: boolean;
  };
}

export function useAuth(): Auth {
  const dispatch = useDispatch();
  const authState: auth.types.State = useSelector<
    types.RootState,
    auth.types.State
  >((state) => state.auth);

  useEffect(() => {
    const f = async (): Promise<void> => {
      const fetchAuthStatus =
        auth.actions[auth.types.ActionTypes.FETCH_AUTH_STATUS];
      await dispatch(fetchAuthStatus());
    };
    f();
  }, [dispatch]);

  return {
    isLoading: authState.isLoading,
    data: {
      isLogin: authState.isLogin,
      isAgreedTerms: authState.isAgreedTerms,
    },
  };
}
