import React from "react";
import styled from "styled-components";
import img from "../../assets/images/entry-complete-background.svg";
interface Props {
  title: JSX.Element;
  notice: JSX.Element;
}

const EntryComplete: React.FC<Props> = (props: Props) => {
  return (
    <EntryCompleteWrapper data-testid="entryComplete">
      <Complete>
        <Circle>
          <InnerCircle></InnerCircle>
        </Circle>
      </Complete>
      <CompleteText>{props.title}</CompleteText>
      <Notification>{props.notice}</Notification>
    </EntryCompleteWrapper>
  );
};

const EntryCompleteWrapper = styled.form`
  margin-left: 0.2rem;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const Complete = styled.div`
  width: 28.2rem;
  margin-top: 6.4rem;
  margin-bottom: 2.6rem;
  padding-top: 7.9rem;
  background-image: url(${img});
  background-repeat: no-repeat;
  background-size: 28.2rem 22.2rem;
`;

const Circle = styled.div`
  width: 13.4rem;
  height: 13.4rem;
  border-radius: 50%;
  line-height: 13.4rem;
  padding-top: 1.6rem;
  padding-left: 1.6rem;
  margin: 0 auto;
  background: #b6f2cf;
`;

const InnerCircle = styled.div`
  width: 10.2rem;
  height: 10.2rem;
  border-radius: 50%;
  line-height: 10.2rem;
  text-align: center;
  background: #5ae294;

  &::after {
    display: block;
    content: "\f00c";
    font-family: "Font Awesome 5 Free"; /* stylelint-disable-line font-family-no-missing-generic-family-keyword */

    /* freeのfontawsomeを使用するときはweightを900に設定する */
    font-weight: 900;
    font-size: 4.6rem;
    margin: auto;
    color: #fff;
  }
`;

const CompleteText = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 1.6rem;
  line-height: 1.9rem;
  text-align: center;
  margin-bottom: 1.2rem;
  color: #111;
`;

const Notification = styled.div`
  text-align: center;
  font-style: normal;
  font-weight: normal;
  font-size: 1.2rem;
  line-height: 1.7rem;
  color: #777;
`;

export default EntryComplete;
