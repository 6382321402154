import { useState, useEffect, useMemo } from "react";
import { terms as termsAjax } from "../utils/ajax";

interface UseTermsInterface {
  isLoading: boolean;
  terms: Terms;
}

interface Terms {
  id: number;
  content: string;
}

export function useTerms(params: any): UseTermsInterface {
  const [terms, setTerms] = useState<UseTermsInterface>({
    isLoading: true,
    terms: { id: NaN, content: "" },
  });

  const termsCategory = useMemo(() => params.category, [params.category]);

  useEffect(() => {
    termsAjax
      .get({ category: termsCategory })
      .then((res) => {
        setTerms({
          isLoading: false,
          terms: {
            id: res.data.id,
            content: res.data.content,
          },
        });
      })
      .catch(() =>
        setTerms({ terms: { id: NaN, content: "" }, isLoading: false })
      );
  }, [termsCategory]);

  return terms;
}
