import React, { useState } from "react";

import GoogleLogin from "react-google-login";
import styled from "styled-components";

import * as googleApi from "../../utils/google";
import { Profile } from "../../utils/google/types";
import GoogleButton from "../../components/molecules/GoogleButton";

interface Props {
  successFunc: (arg: Profile) => void;
  text?: string;
}

const GoogleProfileAuthorization: React.FC<Props> = (
  props: Props
): JSX.Element => {
  const [error, setError] = useState("");

  const responseGoogle = async (response: any) => {
    const profile = await googleApi.profile(response.accessToken);

    if (!profile) setError("プロフィールが取得できません");
    else props.successFunc(profile);
  };

  return (
    <StyledContent>
      {props.text && <Header>{props.text}</Header>}

      <SignInList>
        <SignInListItem>
          <GoogleLogin
            clientId={`${process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID}`}
            buttonText="Login"
            onSuccess={responseGoogle}
            cookiePolicy={"single_host_origin"}
            scope="https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email"
            render={(renderProps) => (
              <GoogleButton
                onClick={renderProps.onClick}
                disabled={renderProps.disabled}
              />
            )}
          />
          {error && (
            <ErrorMessage data-testid="errorMessage">{error}</ErrorMessage>
          )}
        </SignInListItem>
      </SignInList>
    </StyledContent>
  );
};

export default GoogleProfileAuthorization;

const StyledContent = styled.main`
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  width: 100%;
  max-width: 38.9rem;
  margin: auto;
`;

const Header = styled.h1`
  text-align: center;
  margin: 0 auto;
  margin-bottom: 1.6rem;
  color: #777;
  font-size: 1.6rem;

  @media (max-width: 896px) {
    width: 22rem;
  }
`;

const SignInList = styled.ul`
  margin: 0 auto;
  padding: 0;
  width: 33.9rem;
  list-style: none;
`;

const SignInListItem = styled.li`
  margin-bottom: 2rem;
  text-align: center;

  &:last-child {
    margin-bottom: 0;
  }
`;

const ErrorMessage = styled.p`
  margin-top: 1rem;
  color: red;
`;
