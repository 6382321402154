import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

interface Props {
  children: React.ReactNode;
  text: string;
  prevPath?: string;
  nextPath?: string;
}

const InfoBox: React.FC<Props> = (props: Props) => {
  return (
    <StyledInfoBox>
      <StyledText>{props.text}</StyledText>
      <StyledNumber>{props.children}</StyledNumber>
      {props.prevPath && (
        <StyledPrevLink to={props.prevPath} data-testid="infoBoxPrevPath" />
      )}
      {props.nextPath && (
        <StyledNextLink to={props.nextPath} data-testid="infoBoxNextPath" />
      )}
    </StyledInfoBox>
  );
};

const StyledInfoBox = styled.div`
  position: relative;
  margin-bottom: 5.2rem;
  text-align: center;
`;

const StyledText = styled.p`
  margin-bottom: 1.2rem;
  color: #676767;
  font-size: 1.6rem;
  line-height: 1;
`;

const StyledNumber = styled.div`
  font-size: 4rem;
  font-weight: bold;
  line-height: 1;

  > small {
    font-size: 2rem;
    margin-left: 0.8rem;
  }
`;

const StyledNavLink = styled(Link)`
  display: block;
  z-index: 1;
  position: absolute;
  top: 50%;
  width: 3.2rem;
  height: 3.2rem;
  padding: 0;
  border-top: 0.2rem solid #676767;
  border-right: 0.2rem solid #676767;

  &:hover {
    transition: all 0.2s linear;
    border-top: 0.2rem solid #3e94be;
    border-right: 0.2rem solid #3e94be;
  }
`;

const StyledPrevLink = styled(StyledNavLink)`
  left: 0.6rem;
  transform: translateY(-50%) rotate(225deg);
`;

const StyledNextLink = styled(StyledNavLink)`
  right: 0.6rem;
  transform: translateY(-50%) rotate(45deg);
`;

export default InfoBox;
