import React, { useState } from "react";
import { useHistory, Redirect } from "react-router-dom";
import styled from "styled-components";

import SubmitButton from "../components/atoms/SubmitButton";
import CheckBox from "../components/atoms/CheckBox";

import { terms as termsAjax } from "../utils/ajax";
import { toastError } from "../utils/appearError";
import { useAuth } from "../hooks/Auth";
import { useTerms } from "../hooks";

const TermsAgree: React.FC = (): JSX.Element => {
  const termsPram = { category: "mypage" };
  const { terms, isLoading } = useTerms(termsPram);
  const authState = useAuth();
  const [agreementCheck, setAgreementCheck] = useState(false);
  const history = useHistory();
  const postAgreement = async (
    e: React.FormEvent<HTMLFormElement>
  ): Promise<void> => {
    e.preventDefault();
    try {
      await termsAjax.post({
        check: agreementCheck,
        id: terms.id,
      });
      history.push("/");
    } catch (err) {
      toastError("エラーが発生しました。もう一度お試しください");
    }
  };

  if (isLoading) return <div data-testid="dummyComponent" />;

  return (
    <Content>
      <Title>利用規約</Title>
      {(!authState.data.isLogin && <Redirect to="/sign_in" />) ||
        (authState.data.isAgreedTerms && <Redirect to="/" />) || (
          <form
            onSubmit={(e: React.FormEvent<HTMLFormElement>): Promise<void> =>
              postAgreement(e)
            }
          >
            <Body>
              <Box dangerouslySetInnerHTML={{ __html: terms.content }} />
            </Body>
            <Footer>
              <CheckBox
                required={true}
                value={1}
                onChange={(e: React.FormEvent<HTMLInputElement>): void =>
                  setAgreementCheck(!!e.currentTarget?.checked)
                }
                label="利用規約とプライバシーポリシーに同意する"
                margin="0 0 2.4rem"
              />
              <SubmitButton
                name="commit"
                value="同意する"
                dataDisableValue="同意する"
              />
            </Footer>
          </form>
        )}
    </Content>
  );
};

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  max-width: 78rem;
  margin: auto;
  padding: 3.2rem 1.6rem;
`;

const Title = styled.h1`
  font-size: 2.4rem;
  margin: 0;
  margin: 0 auto 2.4rem;
  text-align: center;
  line-height: 1.5;

  @media (max-width: 896px) {
    font-size: 1.8rem;
  }
`;

const Body = styled.div`
  position: relative;
  line-height: 1.5;
  font-size: 1.4rem;
  overflow-y: scroll;
  width: 100%;
  height: 36rem;
  margin: 0 auto 2.4rem;
  padding: 3.2rem;
  background-color: #fff;
  border: solid 0.1rem #eee;

  @media (max-width: 896px) {
    padding: 1.6rem;
  }
`;

const Box = styled.div`
  font-size: 1.4rem;
  line-height: 1.5;

  h2 {
    margin-bottom: 1rem;
    line-height: 1;
    font-size: 1.6rem;
    font-weight: bold;
  }

  ul {
    list-style: none;
    padding: 0;
    margin-bottom: 1.6rem;
  }

  ol {
    list-style: decimal;
    padding-left: 3.2rem;
    margin-bottom: 1.6rem;
  }

  > section {
    margin: 2.4rem 0;
  }
`;

const Footer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export default TermsAgree;
