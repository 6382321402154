import React from "react";
import styled from "styled-components";
import Title from "../atoms/Title";
import Breadcrumb from "../molecules/Breadcrumb";

interface Props {
  children: React.ReactNode;
  breadcrumb?: Breadcrumb;
  title?: string;
}

interface Breadcrumb {
  title: string;
  to: string;
}

const Content: React.FC<Props> = (props: Props) => {
  return (
    <StyledContent>
      {props.breadcrumb && (
        <Breadcrumb title={props.breadcrumb.title} to={props.breadcrumb.to} />
      )}
      <StyledContainer>
        {props.title && <Title>{props.title}</Title>}
        {props.children}
      </StyledContainer>
    </StyledContent>
  );
};

const StyledContent = styled.main<{ fluid?: string }>`
  position: relative;
  display: inline-block;
  min-height: calc(100vh - 11.2rem);
  margin-left: 28rem;

  @media (max-width: 896px) {
    margin: 0;
    min-height: calc(100vh - 12.4rem);
    padding: 3.2rem 1.6rem;
  }
`;

const StyledContainer = styled.div`
  padding: 4.8rem;

  @media (max-width: 896px) {
    min-height: calc(100vh - 12.4rem);
    padding: 3.2rem 1.6rem;
  }
`;

export default Content;
