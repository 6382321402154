import React from "react";
import { Modal, makeStyles, Button } from "@material-ui/core";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";

interface Props {
  open: boolean;
  onClose: () => void;
}

const useStyles = makeStyles({
  modal: {
    padding: "0 18%",
  },
  paper: {
    backgroundColor: "white",
    boxShadow: "5px 5px 10px rgba(0, 0, 0, 0.5)",
    padding: "2% 10%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    color: "#fb3d45",
    "@media (max-width: 600px)": {
      fontSize: "1.2rem",
    },
    "@media (min-width: 601px)": {
      fontSize: "inherit",
    },
  },
  button: {
    marginTop: "50px",
    marginBottom: "10px",
    backgroundColor: "#fb3d45",
    fontSize: "inherit",
    fontWeight: "bold",
    color: "white",
    padding: "1% 5%",
    width: "150px",
    borderRadius: "30px",
    "&:hover": {
      background: "#f76d73",
    },
  },
  icon: {
    color: "#fb3d45",
    marginBottom: "20px",
    "@media (max-width: 600px)": {
      fontSize: "13rem",
    },
    "@media (min-width: 601px) and (max-width: 960px)": {
      fontSize: "20rem",
    },
    "@media (min-width: 961px)": {
      fontSize: "20rem",
    },
  },
});

// eslint-disable-next-line react/prop-types
const LmndModal: React.FC<Props> = ({ open, onClose }) => {
  const classes = useStyles();

  const handleConfirm = () => {
    onClose();
  };

  return (
    <Modal open={open} onClose={onClose} className={classes.modal}>
      <div className={classes.paper}>
        <ErrorOutlineIcon className={classes.icon} />
        <h2>
          LMNDの画面に遷移する場合は「利用サービス」の「LMND」を押下してください。
        </h2>
        <Button
          variant="contained"
          className={classes.button}
          onClick={handleConfirm}
        >
          確認
        </Button>
      </div>
    </Modal>
  );
};

export default LmndModal;
