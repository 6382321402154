import React from "react";
import styled from "styled-components";

// asはLinkでくるか、aでくるかのどっちかなので、toとhrefをそれぞれのunion型にする必要がある
interface Props {
  children: React.ReactNode;
  as?: any;
  to?: string;
  href?: string;
  active?: boolean;
  onClick?: (e: React.FormEvent<HTMLInputElement>) => void;
}

const defaultProps = {
  as: "a",
  active: false,
};

const GlobalNavItem: React.FC<Props> = (props: Props) => {
  return (
    <StyledGlobalNavItem
      as={props.as}
      to={props.to}
      href={props.href}
      onClick={props.onClick}
      active={props.active ? "true" : undefined}
      data-testid="globalNavItem"
    >
      {props.children}
    </StyledGlobalNavItem>
  );
};

GlobalNavItem.defaultProps = defaultProps;

const StyledGlobalNavItem = styled.a<{ active: string }>`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 1.2rem 2.4rem;
  color: #666;
  transition: all 0.2s ease-in-out;

  ${(props): string =>
    props.active
      ? `
      color: #0e8eb9;
      background-color: #f2f2f2;

      &::before {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 0.6rem;
        height: 4.8rem;
        background-color: #0e8eb9;
      }
      `
      : ``}

  &:last-child {
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    border-top: 0.1rem solid #e0e0e0;

    @media (max-width: 896px) {
      position: static;
    }
  }

  &:hover {
    text-decoration: none;
    color: #0e8eb9;
    background-color: #f2f2f2;
  }

  > i {
    margin-right: 1.2rem;
  }
`;

export default GlobalNavItem;
