import React from "react";
import Content from "../components/templates/Content";
import Faq from "react-faq-component";
import { creator, types } from "../store";
import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";

const FaqStyles = {
  titleTextColor: "black",
  rowTitleColor: "black",
  rowContentColor: "dimgray",
};

const Emphasis = (input: string): string => {
  return (
    "<span style='font-weight: bold; font-size: 120%'>" + input + "</span>"
  );
};

const ImageBaseUrl = `${process.env.REACT_APP_MYPAGE_IMAGE_URL}faq/voom/`;

const DataRevenue = {
  title: "収益・アカウント周りに関して",
  rows: [
    {
      title: "Q.毎月の報酬はいつどこで確認することが可能ですか？",
      content:
        "A.再生時間や収益は、現状では確認することができません。<br>（管理画面では、投稿後31日間の再生時間であれば確認可能です)<br><br>" +
        "お振り込みのサイクルは月末締め翌月払いとなります。<br>" +
        "なお、LMNDクリエイター様は、LMNDでの案件報酬が発生している場合、<br>" +
        "その収益とVOOMの収益が合わせて振り込まれるためご了承くださいませ。",
    },
    {
      title: "Q.動画の収益状態をデータでチェックしたい",
      content:
        "A.今回の支援金のお支払いの元となるデータはLINE社が取得しているデータとなります。<br>" +
        "現状クリエイター様がお使いの管理画面では収益が見れない仕組みとなっています。",
    },
    {
      title:
        "Q.収益化申請をする必要はあるのか / 収益化申請をしたが審査が完了しない",
      content:
        "A.施策上LINE社が一括で収益化申請を行っておりますので個人的な収益化申請は一切必要ありません。<br>" +
        "今回の施策では広告収益は発生せず、LINE社が再生時間を集計しその実績に応じた支援金をお支払いします。<br>" +
        "支援金として毎月お支払いさせていただいている金額が広告収益以上の金額となっているためご安心ください。<br><br>" +
        "仮に申請をしてしまったという方は申請中のままでよいのでご放念くださいませ。",
    },
    {
      title:
        "Q.収益化基準を満たしたため、収益化申請をしたのですが、いつ頃申請は終わりますでしょうか？",
      content:
        "A.収益化申請はLINE社にて一括で行っているため、個人的な収益化申請の必要はありません。<br>" +
        "一度行った収益化申請は承認されずそのままになるのでご放念ください。<br>" +
        "なお、収益化申請が完了したとしても、本お取り組みは再生時間に応じた支援金をお支払いするものとなっているため、" +
        "これまでとお支払いする内容は変わりません。",
    },
    {
      title: "Q.投稿した短尺動画に収益化の表示が出ない",
      content:
        "A.短尺動画には" +
        Emphasis("収益化ボタンは表示されません施策による収益化はされている") +
        "のでご安心いただければと思います。<br>" +
        "また短尺動画の投稿は必ず" +
        Emphasis("「short video機能」") +
        "をご使用ください。<br><br>" +
        "前提として、今回のお取り組みは再生時間に応じた支援金が支払われるという形になっております。<br>",
    },
    {
      title:
        "Q.現状「収益化」の部分が「収益化中」となっていて、再生はされていてもどの動画も収益自体は０円となっている",
      content:
        "A.広告収益を受け取る申請をされている方は、LINE VOOM ダッシュボードの仕様の問題で「収益化中」の表示が現状出てしまいます。<br>" +
        "ですが広告収益は発生せず支援金のお支払いとなりますので、広告収益が0円という表示がされます。<br>",
    },
    {
      title: "Q.LINE VOOMの収益はどのような基準で発生しますか？",
      content:
        "A.投稿いただいた動画の再生時間に応じて支援金が発生いたします。<br>" +
        "全体に対して、クリエイター様一人当たりの再生時間の割合を出し、" +
        "それに対してLINEさんが用意している全体の支援施策予算を振り分ける形になります。<br>" +
        "（予算枠は、基本的には毎月増えていく形になります）",
    },
    {
      title: "Q.アカウントが未承認になっているのですが、大丈夫ですか？",
      content:
        "A." +
        Emphasis(
          "問題ありません。基本的に未認証アカウントで投稿していただきます。"
        ) +
        "<br><br>" +
        "認証アカウントの申請をしていただいても問題ないですが、" +
        "認証の申請は、元々企業のアカウント用につくられたものであるので、" +
        "審査に通過する可能性はほぼ低いです。",
    },
    {
      title: "Q.振り込みに関して",
      content:
        "A." +
        Emphasis("月末締め翌月払い") +
        "として「ウーム（カ」よりお振り込みをいたします。<br>" +
        "LMNDの方はLMNDにご登録の口座へお振り込みをします。",
    },
    {
      title: "Q.個人アカウントに紐づいてしまいますか？",
      content:
        "A.LINEビジネスアカウントは普段お使いのLINEアカウントとは全く異なるアカウントとなります。" +
        "<br><br>" +
        "個人の情報が流出したり、アカウントがLINE友達に知られたりすることはないのでご安心くださいませ。",
    },
    {
      title: "Q.ログイン出来ず、パスワードを再発行したい",
      content:
        "A.ビジネスアカウントのログイン画面のパスワードをリセットからご対応ください<br>" +
        "<img src='" +
        ImageBaseUrl +
        "revenue_can_not_login.png'>",
    },
    {
      title: "Q.アカウント名とプレミアムIDを変更できますか？",
      content:
        "A." +
        Emphasis("未認証アカウントなら変更可能") +
        "です（変更後7日間は変更できません）<br>" +
        Emphasis("プレミアムアカウントは変更不可") +
        "となっております。",
    },
    {
      title: "Q.アカウントの一部機能が利用できなくなってしまった",
      content:
        "A." +
        Emphasis("権限の問題か任意の設定の問題。その他登録情報の不足") +
        "などが考えられます。<br>" +
        "未認証アカウント(グレーバッジ)は、基本的にLINE社または弊社側で勝手に設定を変更することはございません。<br>" +
        "利用方法などもwebサイトに公開されているもの以上はございません。<br>" +
        "なのでまずは権限周りや設定内容などご自身で確認いただくようお願いします。<br>" +
        "<a href='https://www.linebiz.com/jp/manual/' target='_blank'>https://www.linebiz.com/jp/manual/</a>",
    },
  ],
};

const DataViews = {
  title: "再生回数・インプレッションに関して",
  rows: [
    {
      title: "Q.再生数が管理画面上で0のまま増えない",
      content:
        "A." +
        Emphasis(
          "①投稿してから24時間以上経過してない（管理画面にデータが反映されていない)"
        ) +
        "<br>" +
        Emphasis(
          "②露出拡大の枠組みに入ったばかり（ホワイトリストに登録されたばかり）"
        ) +
        "<br>" +
        "上記のため画面表示の準備が間に合っていない場合がございます。",
    },
    {
      title: "Q.インプレッション・再生回数が増えない",
      content:
        "A.管理画面でのインプレッション・再生回数には" +
        Emphasis("タイムラグ") +
        "がございます。<br>" +
        "また、施策に参加した" +
        Emphasis("初月の投稿数") +
        "がかなり重要となってきます。<br>" +
        Emphasis(
          "施策に参加した初月はなるべく多く投稿をするようにお願いいたします。"
        ) +
        "<br><br>" +
        Emphasis(
          "ご自身のInstagramやTwitterなどでLINE VOOMへの遷移を促すことも再生回数を増加させることに効果的です。"
        ) +
        "<br>ぜひ積極的に行ってください。",
    },
    {
      title: "Q.動画はどのタブで視聴されますか？",
      content:
        "A.現在は" +
        Emphasis("おすすめタブとディスカバーでの視聴が") +
        "多くなっています。<br>" +
        "露出拡大の枠組みが変更されたため、" +
        Emphasis("投稿のいいね数やコメント数、") +
        "<br>" +
        Emphasis(
          "アカウントから投稿される動画の本数などによって露出量が変更される"
        ) +
        "形となっています。<br>" +
        "投稿して最初の3日間はフォロー中タブで視聴されることが多いので、フォロワーの獲得も大事です。<br>" +
        "全体の傾向としては、おすすめタブに露出拡大された分の再生時間が多いため、投稿本数を指標にしてより多く投稿できるようにしていただくのが効果的です。",
    },
    {
      title:
        "Q.LINE上でクリエイター名を検索しても表示されない場合どうすればよいですか？",
      content:
        "A.プレミアムIDを購入いただく形でIDを変更いただくことは可能です。（有料）<br>" +
        "また、動画に" +
        Emphasis("ハッシュタグでクリエイター名") +
        "をつけていただくことで検索に引っかかりますので動画の説明文に" +
        Emphasis("「#クリエイター名」") +
        "の記載をしてみてください。",
    },
    {
      title: "Q.LINE VOOMの再生時間を確認することはできますか？",
      content:
        "A.Official Account ManagerのLINE VOOMをクリックした先にある「VOOM Studio」のダッシュボードにて再生回数・合計再生時間を確認することが可能です。<br>" +
        "なお、この画面で確認できる再生時間は、支援金対象の再生時間と集計方法、集計期間が異なります。ご了承ください。",
    },
    {
      title: "Q.VOOM Studioはアプリやスマホから見ることができますか？",
      content:
        "A.VOOM Studioはアプリにて閲覧することは現状できませんがスマホのブラウザからURLで入れば閲覧することが可能です。<br>" +
        "下記URLよりお試しください。<br><br>" +
        "https://voom-studio.line.biz/account/(自分のアカウントID)/insight/dashboard ",
    },
  ],
};

const DataContribution = {
  title: "投稿・フォロワーに関して",
  rows: [
    {
      title:
        "Q.投稿する動画はスマホの縦動画（ショート動画）と通常のロング動画（YouTube動画）のどちらがオススメですか？",
      content:
        "A.基本的にはショート動画をおすすめいたします。（PFとしても、ショート動画を優先露出拡大しているため）<br>" +
        "ただ、アカウントが成長し、フォロワー数が伸びてくれば、長尺動画を投稿して1再生あたりの視聴時間を伸ばす方法も有効です。",
    },
    {
      title:
        "Q.TikTokやYouTube、Instagramで過去に投稿した動画を転用しても良いですか？その際ウォーターマークに関してもカットが必要ですか？",
      content:
        "A." +
        Emphasis(
          "その他のSNSを転用して投稿いただくことは問題なく、推奨しております"
        ) +
        "<br>" +
        "LINE VOOM投稿用のオリジナル動画をいきなりつくる必要はなく、まずは転用した投稿からスタートするのがおすすめです。<br>" +
        Emphasis(
          "ウォーターマーク（TikTokなどのプラットフォームロゴ）は削除せずに投稿していただいても問題ありません。"
        ) +
        "<br>" +
        "ですがウォーターマークがない動画の方がインプレッションが高い傾向にあります。",
    },
    {
      title: "Q.サムネイル・動画の最適なサイズを教えてください",
      content:
        "A.サムネイルに関しては" +
        Emphasis("750×993 px") +
        "が指定サイズです。<br>" +
        "動画自体は1:1より縦長の動画を投稿してください。",
    },
    {
      title:
        "Q.サムネイルに画像を設定しない場合どの箇所が自動的に設定されるのか",
      content:
        "A.自動設定の場合、ランダムで設定がされます。0〜6、8秒までの箇所からランダムで切り取られて設定がされます",
    },
    {
      title: "Q.サムネイルが反映されない",
      content:
        "A.サムネイルが反映されるのはLINE VOOMでは" +
        Emphasis("ディスカバーだけ") +
        "となっております。<br>" +
        "そのためサムネイルが反映されていなくても問題ございません。<br>" +
        "LINE VOOMではあまりサムネイルが現状重要視されていないためその点もご理解いただけますと幸いです。",
    },
    {
      title: "Q.アプリゲームの実況動画投稿をすることは可能ですか？",
      content:
        "A.ゲームコンテンツの著作権で言うと下記のようになっております。<br>" +
        Emphasis("①任天堂、カプコン：投稿NG") +
        "<br>" +
        Emphasis(
          "②アニメコンテンツを使ったゲーム（鬼滅の刃のゲームなど）：アニメの著作権的に処理がされていればOK"
        ) +
        "<br>" +
        Emphasis(
          "③他のゲーム：ゲーム会社さんのコンテンツ規約に準じているorクリエイターとゲーム会社さんの間で権利処理が済んでいる場合は投稿OK"
        ) +
        Emphasis("(YouTubeへの投稿と同じ考え方)"),
    },
    {
      title: "Q.サムネイルはどうやって設定すればよいですか？",
      content:
        "A.PC版管理画面からのみ設定ができます。<br>" +
        "動画をアップロードすると、サムネイルの設定画面が出てきますので、そこで設定をお願いします。<br>" +
        "※現状、サムネイルが表示されるのはディスカバータブのみです。<br>" +
        "※投稿済みの動画も、編集画面からサムネイルを設定することは可能です。<br>" +
        "<img src='" +
        ImageBaseUrl +
        "contribution_thumbnail_set.png' width='100%'>",
    },
    {
      title: "Q.投稿する際、どのくらい頻度で投稿するべきですか？",
      content:
        "A.収益を多く獲得されている方の平均投稿本数は、約70本です（上位10名）<br>" +
        "また、収益を月に10万円以上獲得されている方も、40本程度投稿はされております。<br>" +
        "他SNSに投稿した動画の転用でも問題ございませんので、まずはVOOMに投稿する本数を指標に投稿活動を続けていただけますと幸いです。<br>" +
        "また、1日に偏って投稿を多数するよりも、投稿日を分散していただいた方がより効果があります。<br>" +
        "※この本数のデータは、あくまで目安であり、収益額をお約束するものではございません。",
    },
    {
      title: "Q.ハッシュタグはどのようなものをつけるべきですか？",
      content:
        "A.投稿には、動画の概要を端的に表すテキストを入れることをお勧めしております。<br>" +
        "もう一つは、投稿と同時に、外部のSNSから視聴者を誘導していくことが効果的です。",
    },
    {
      title: "Q.動画の音源は何を使用すればよいのでしょうか？",
      content:
        "A.JASRACとNexToneに登録されている管理楽曲は全て許可が取れていますので、使用いただいて問題ありません。<br>" +
        "TikTokなどで独自に広まっている音源はJASRACとNexToneに登録されていない場合もあるのでご注意ください。",
    },
    {
      title: "Q.音源はどのようにつければよいでしょうか？",
      content:
        "A.Tiktokやリールにて作成した音源の付いている動画をそのまま使用いただくことが可能です。<br>" +
        "音源がついていない動画に関してはお手数ですが音源をつけられるアプリにて作成をお願いします。<br>" +
        "将来的な予定ですが原盤の使用許諾もPFとして取る予定で、LINEミュージックに入っている音源が使用可能などとなる予定です。",
    },
    {
      title: "Q.投稿内容のトレンドについて",
      content:
        "A.下記LINE公式アカウントのトークにてLINE VOOMのトレンドやキャンペーン情報が配信されています。ぜひ友達登録をしてご確認ください！<br><br>" +
        "<a href='https://liff.line.me/1645278921-kWRPP32q/?accountId=linevoomjp' target='_blank'>" +
        "https://liff.line.me/1645278921-kWRPP32q/?accountId=linevoomjp</a>",
    },
  ],
};

const DataTalk = {
  title: "トーク・友達に関して",
  rows: [
    {
      title:
        "Q.LINEアカウントのトークのメッセージ配信数には上限がありますが、自動応答についてもメッセージ配信数にカウントされますか？",
      content: "A.自動応答についてはメッセージ配信数にカウントされません。",
    },
    {
      title:
        "Q.チャットモードでの個別での返信は、メッセージ配信数の上限に含まれますか？",
      content: "A.含まれないのでご安心くださいませ。",
    },
    {
      title:
        "Q.LINEアカウントのトークのBotモードとチャットモードの違いは何ですか？",
      content:
        "A.<br>・Botモード<br>" +
        "自動応答(事前にキーワードを設定すると、決められた返事をBotで返す)ことが可能。<br>" +
        "個別に友達が送ってきているメッセージは見ることができない。<br><br>" +
        "・チャットモード<br>" +
        "自動応答はできないが、個別で友達が送ってきている内容は見ることができて、　個別で手動での返信は可能。",
    },
    {
      title:
        "Q.LINE VOOMのフォロワーだけでなく、LINEアカウントの友だちにもLINE VOOMの投稿が表示されますか？",
      content:
        "A.現状LINEの友だちには表示されない形になっているので、トークルームなどで誘導する必要があります。<br>" +
        "LINEのトークにてLINE VOOMの投稿を発信して、ユーザーの遷移を促してください。<br><br>" +
        "詳細はこちらもご覧ください。<br>" +
        "<a href='https://www.linebiz.com/jp/column/service-information/timeline_renewal/' target='_blank'>" +
        "https://www.linebiz.com/jp/column/service-information/timeline_renewal/</a>",
    },
    {
      title: "Q.LINE VOOMのフォロワーにはどのように投稿が表示されますか？",
      content:
        "A.LINEアプリ LINE VOOMタブ内のフォロー中欄に、ご自身の投稿が表示されます。<br>" +
        "検索マークをクリックした先のディスカバー欄にも表示されることがあります。<br><br>" +
        "LINE VOOMのフォロワーを増やすためにも、他SNSやLINEトーク画面にてLINE VOOMの投稿の宣伝をしてもらうことは効果的です。",
    },
    {
      title: "Q.アカウントの他SNSでの宣伝について",
      content:
        "A.LINE VOOMにてYouTubeやTiktok、Instagramといった他SNSアカウントへの遷移を促す投稿はレギュレーション違反となります。<br><br>" +
        "他SNSアカウントからLINE VOOMへの遷移を促す投稿は問題ないため積極的に行っていただけますと幸いです。",
    },
    {
      title: "Q.LINEタイムラインのURLをYouTubeの概要欄に貼るのは可能ですか",
      content:
        "A.可能です。YouTubeの概要欄に記載をして、既存の登録者をLINE VOOMの投稿にも遷移を促すことで収益アップに繋がります。",
    },
    {
      title:
        "Q.公式LINEのトーク画面にてYouTubeの配信告知やグッズ販売のお知らせなどの配信、他のSNS等に誘導することは禁止ですか？",
      content:
        "A.LINE公式アカウントのトーク画面にてYouTubeの配信告知やグッズ販売のお知らせなどを配信したり、他のSNS等に誘導することは問題ございません。<br>" +
        "LINE VOOMの動画投稿とセットで上記内容を行うことは禁止されております。",
    },
    {
      title: "Q.LINE VOOMの投稿ではSNS以外のURLを貼ることもNGですか？",
      content:
        "A.商品提供に対し、金銭の発生の有無は弊社側ではわかりかねるため、ガイドラインにある以下に当たります。<br>" +
        "・特定商品をPRするコンテンツ<br>" +
        "・スポンサーが介入してるコンテンツ<br>" +
        "なので支払い対象外にあたりますが、ただ記載がNGというわけではありません。<br>" +
        "あくまで支払い対象外にあたるというルールになります。",
    },
  ],
};

const VoomFaq: React.FC = (): JSX.Element => {
  const creatorState: creator.types.State = useSelector<
    types.RootState,
    creator.types.State
  >((state) => state.creator);

  if (creatorState.isLoading) return <div>Loading....</div>;
  if (!creatorState.isVoomCreator) return <Redirect to="/404" />;

  return (
    <Content title="LINE VOOM よくある質問">
      <div>
        <Faq data={DataRevenue} styles={FaqStyles} />
        <br></br>
        <Faq data={DataViews} styles={FaqStyles} />
        <br></br>
        <Faq data={DataContribution} styles={FaqStyles} />
        <br></br>
        <Faq data={DataTalk} styles={FaqStyles} />
      </div>
    </Content>
  );
};

export default VoomFaq;
