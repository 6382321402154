import { useState, useEffect } from "react";
import { FormItemProps } from "../components/molecules/FormItemList";
import { Labels, labels } from "../utils/forms/liveStreamingEntry";
import { POCOCHA, LINE_LIVE } from "../constants/common";

export function useLiveStreamingEntryFormItems(
  entry: Partial<Labels>,
  provider: string,
  platformOptions: Array<{ id: string; name: string }>,
  platformName?: string
): FormItemProps<Labels> {
  const getPlaceholder = (pfName?: string) => {
    if (pfName === POCOCHA) return "https://www.pococha.com/ja-jp/app/users/";
    else if (pfName === LINE_LIVE) return "https://live.line.me/channels/";
    else return "審査対象URLを入力してください";
  };
  const [status, setStatus] = useState<FormItemProps<Labels>>([]);
  useEffect(() => {
    const formItem: FormItemProps<Labels> = [
      {
        formItemProps: {
          name: "platformMasterId",
          options: platformOptions.map((platformOption) => ({
            text: platformOption.name,
            value: platformOption.id,
          })),
          defaultValue: entry.platformMasterId,
        },
        field: "select",
        label: labels["platformMasterId"],
      },
      {
        formItemProps: {
          name: "platformUrl",
          type: "text",
          placeholder: getPlaceholder(platformName),
          defaultValue: entry.platformUrl,
        },
        field: "input",
        label: labels["platformUrl"],
      },
      {
        formItemProps: {
          type: "text",
          placeholder: "クリエイター名",
          name: "name",
          defaultValue: entry.name,
        },
        field: "input",
        label: labels["name"],
      },
      {
        formItemProps: {
          type: "text",
          placeholder: "山田",
          name: "lastName",
          defaultValue: entry.lastName,
        },
        field: "input",
        label: labels["lastName"],
      },
      {
        formItemProps: {
          type: "text",
          placeholder: "太郎",
          name: "firstName",
          defaultValue: entry.firstName,
        },
        field: "input",
        label: labels["firstName"],
      },
      {
        formItemProps: {
          type: "text",
          placeholder: "ヤマダ",
          name: "lastNameKana",
          defaultValue: entry.lastNameKana,
        },
        field: "input",
        label: labels["lastNameKana"],
      },
      {
        formItemProps: {
          type: "text",
          placeholder: "タロウ",
          name: "firstNameKana",
          defaultValue: entry.firstNameKana,
        },
        field: "input",
        label: labels["firstNameKana"],
      },
      {
        formItemProps: {
          name: "birthdate",
          defaultValue: entry.birthdate,
        },
        field: "date",
        label: labels["birthdate"],
      },
      {
        formItemProps: {
          name: "gender",
          options: [
            { value: "男性", text: "男性" },
            { value: "女性", text: "女性" },
            { value: "その他", text: "その他" },
          ],
          defaultValue: entry.gender,
        },
        field: "select",
        label: labels["gender"],
      },
      {
        formItemProps: {
          type: "text",
          placeholder: "1076228",
          name: "postcode",
          defaultValue: entry.postcode,
        },
        field: "input",
        label: labels["postcode"],
      },
      {
        formItemProps: {
          type: "text",
          placeholder: "東京都港区赤坂9-7-1 ミッドタウン・タワー F28",
          name: "address",
          defaultValue: entry.address,
        },
        field: "input",
        label: labels["address"],
      },
    ];

    if (provider === "line")
      formItem.push({
        formItemProps: {
          type: "text",
          placeholder: "test@test.com",
          name: "email",
          defaultValue: entry.email,
        },
        field: "input",
        label: labels["email"],
      });

    formItem.push({
      formItemProps: {
        placeholder: "自己アピールを自由に記載してください",
        name: "selfAppeal",
        defaultValue: entry.selfAppeal,
      },
      field: "textarea",
      label: labels["selfAppeal"],
    });

    setStatus(formItem);
  }, [entry, platformOptions, platformName, provider]);

  return status;
}
