import { useState, useEffect } from "react";
import axios, { Method } from "axios";
import { useHistory, useLocation } from "react-router-dom";
import { ErrorDetails } from "./types";

import { convertKeyToCamel } from "../toCamel";
import { toastError } from "../appearError";

axios.defaults.baseURL = process.env.REACT_APP_CODA_API_SERVER_URL;
axios.defaults.headers.post["Content-Type"] = "application/json";
axios.defaults.headers.get["Content-Type"] = "application/json";
axios.defaults.headers.get["X-Requested-With"] = "XMLHttpRequest";

export const createAxiosHook =
  <T>(axiosAction: Method, url: string): Function =>
  (
    initialState: T,
    params?: any
  ): {
    data: T;
    loading: boolean;
    error: boolean;
    errorDetails: ErrorDetails;
    reload: () => void;
  } => {
    const [data, setData] = useState<T>(initialState);
    const [error, setError] = useState(false);
    const [errorDetails, setErrorDetails] = useState({
      status: NaN,
      message: "",
    });
    const [loading, setLoading] = useState(true);
    const [reload, setReload] = useState(0);
    const paramsArray: any[] = params ? Object.values(params) : [];
    const history = useHistory();
    const location = useLocation();

    useEffect(() => {
      let mounted = true;
      const source = axios.CancelToken.source();
      async function fetch() {
        try {
          const { data } = await axios.request<T>({
            url: url,
            method: axiosAction,
            cancelToken: source.token,
            withCredentials: true,
            params: params,
          });
          if (!mounted) return;

          setData(convertKeyToCamel(data));
          setLoading(false);
        } catch (error) {
          if (!mounted) return;

          if (
            error.response &&
            error.response?.status === 401 &&
            location.pathname !== "/sign_in"
          ) {
            toastError(
              "読み込みに失敗しました。正しいアカウントでログインしてください。"
            );
            history.push("/sign_in");
          } else if (error.response && error.response?.status === 500) {
            toastError(
              "通信エラーが発生しました。ページを再度読み込んでください"
            );
          }
          setError(true);
          setErrorDetails({
            status: error.response?.status,
            message: error.response?.message,
          });
          setLoading(false);
        }
      }

      fetch();
      return function () {
        mounted = false;
        source.cancel("Cancelling in cleanup");
      };
    }, [...paramsArray, reload]); // eslint-disable-line react-hooks/exhaustive-deps

    return {
      data,
      loading,
      error,
      errorDetails,
      reload: (): void => {
        setReload(reload + 1);
      },
    };
  };

export default createAxiosHook;
