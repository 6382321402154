import { Payments } from "../types";

export const basic: Payments.Basic = {
  paymentMethod: "",
  zenginBank: {
    bankName: "",
    bankCode: "",
    branchName: "",
    branchCode: "",
    accountName: "",
    accountNo: "",
    accountType: "",
  },
};
