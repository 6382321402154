import React from "react";

const baseUrl =
  `https://accounts.google.com/o/oauth2/v2/auth?scope=https%3A//www.googleapis.com/auth/userinfo.profile https%3A//www.googleapis.com/auth/userinfo.email` +
  `&include_granted_scopes=true` +
  `&response_type=token` +
  `&state=state_parameter_passthrough_value` +
  `&client_id=${process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID}` +
  `&redirect_uri=${process.env.REACT_APP_GOOGLE_OAUTH_ENTRY_REDIRECT_URL}`;

const getPath = (type: string | null): string => {
  switch (type) {
    case "youtube":
      return "/entry/youtube";
    case "live_streaming":
      return "/entry/live_streaming";
  }

  return "/";
};

const getParameter = (entryRoute: string | null): string => {
  switch (entryRoute) {
    case "bs_yoshimoto":
      return "?entry_route=bs_yoshimoto";
  }

  return "";
};

// entry用のgoogleでoauthでリダイレクトするためだけのcomponent
const GoogleAuthRedirectPage: React.FC = (): JSX.Element => {
  const query = window.location.search;
  const params = new URLSearchParams(query);
  const type = params.get("type");
  const entryRoute = params.get("entry_route");

  const path = getPath(type);
  const parameter = getParameter(entryRoute);

  window.location.href = baseUrl + path + parameter;

  return <div />;
};

export default GoogleAuthRedirectPage;
