import React from "react";
import styled from "styled-components";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { types, auth } from "../../store";
import UuumLogo from "../molecules/UuumLogo";
import DrawerButton from "../atoms/DrawerButton";

interface Props {
  mobileMenuChangeFunc: MobileMenuChangeFunc;
  mobileMenuOpen: boolean;
}

interface MobileMenuChangeFunc {
  (state: boolean): void;
}

const Header: React.FC<Props> = (props: Props) => {
  const { pathname } = useLocation();
  const authState: auth.types.State = useSelector<
    types.RootState,
    auth.types.State
  >((state) => state.auth);
  const isMaintenance = process.env.REACT_APP_MAINTENANCE_MODE;

  if (!isMaintenance && pathname === "/sign_in")
    return <div data-testid="headerDummy"></div>;

  function menuNavVisible(): boolean {
    return authState.isLogin && authState.isAgreedTerms;
  }

  function topLogoLink(): string {
    return authState.isLogin ? "/" : "/sign_in";
  }

  return (
    <StyledHeader>
      <StyledHeading>
        <UuumLogo
          to={topLogoLink()}
          onClick={(): void => props.mobileMenuChangeFunc(false)}
          padding="1rem"
        />
      </StyledHeading>
      {menuNavVisible() && (
        <DrawerButton
          mobileMenuOpen={props.mobileMenuOpen}
          onClick={(e): void => {
            e.preventDefault();
            props.mobileMenuChangeFunc(!props.mobileMenuOpen);
          }}
        />
      )}
    </StyledHeader>
  );
};

const StyledHeader = styled.header`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  position: relative;
  z-index: 10;
  width: 100%;
  min-height: 6.4rem;
  background: #fff;
  box-shadow: 0 0.2rem 0.8rem 0 rgba(0, 0, 0, 0.05);

  @media (max-width: 896px) {
    min-height: 5.6rem;
  }
`;

const StyledHeading = styled.h1`
  width: 10rem;
  margin: auto;
  font-size: 0;
`;

export default Header;
